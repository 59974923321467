import { UserService } from './../../services/userservice.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { APP_URLS } from 'src/app/Utils/general';
import { FilterPipe } from 'src/app/pipes/filter.pipe';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-help-desk',
  templateUrl: './help-desk.component.html',
  styleUrls: ['./help-desk.component.css'],
  providers: [FilterPipe],
})
export class HelpDeskComponent implements OnInit {
  helpDeskDetails: any;
  helpList: any = [];
  helpRelatedTopics: any = [];
  helpTitle = '';
  innerhtmldata: any;
  userRole: any;
  errorpage: any;
  isLoading: boolean;
  isBeforeLogin = true;
  allTopics: any = [];
  keyword = 'help_title';
  searchTxt = '';
  @ViewChild('auto') auto: any;
  relatedTopic: any;

  constructor(
    private Us: UserService,
    private FilterSearch: FilterPipe,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.errorpage = window.location.pathname;
    const token = localStorage.getItem('token');
    this.isBeforeLogin = token ? false : true;
    this.userRole = localStorage.getItem('role');
    this.getHelpList();
  }
  selectEvent(item: any) {
    this.helpTitle = '';
    this.innerhtmldata = '';
    if (this.helpDeskDetails[item.parentIndex]) {
      this.helpRelatedTopics = [];
      this.helpRelatedTopics =
        this.helpDeskDetails[item.parentIndex].help_related;
    }
    this.helpTitle = item.help_title;
    this.innerhtmldata = this.sanitizer.bypassSecurityTrustHtml(
      item.description
    );
  }

  selectTopic(topic: any, index: any, i: any) {
    // this.auto.clear();
    // this.auto.close();
    this.helpRelatedTopics = [];
    this.helpTitle = '';
    this.innerhtmldata = '';
    this.relatedTopic = { ...this.helpDeskDetails[i] };
    this.helpRelatedTopics = topic;
    if (topic[index]) {
      this.helpTitle = topic[index].help_title;
      this.innerhtmldata = this.sanitizer.bypassSecurityTrustHtml(
        topic[index].description
      );
    }
  }

  // selectSubTopic(subTopic: any) {
    // this.auto.clear();
    // this.auto.close();
  //   this.helpTitle = '';
  //   this.innerhtmldata = '';
  //   this.helpTitle = subTopic.help_title;
  //   this.innerhtmldata = this.sanitizer.bypassSecurityTrustHtml(
  //     subTopic.description
  //   );
  //   window.scroll(0, 0);
  // }

  getHelpList() {
    this.isLoading = true;
    const url = APP_URLS.v1.getHelpList;
    this.Us.getCallWithHeaders(url).subscribe((resp: any) => {
      this.isLoading = false;
      this.helpDeskDetails = resp.list;
      this.helpDeskDetails.forEach((x: any, i: any) => {
        x.help_related.forEach((title: any, j: any) => {
          title.parentIndex = i;
          this.allTopics.push(title);
        });
      });
      if (this.helpDeskDetails.length) {
        this.selectTopic(this.helpDeskDetails[0].help_related, 0, 0);
      }
    });
  }
}
