import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { CourseService } from 'src/app/services/course.service';
import { UserService } from 'src/app/services/userservice.service';
import { APP_URLS, TenantId } from 'src/app/Utils/general';
import { APP_VLE_URLS } from 'src/app/Utils/vle';

@Component({
  selector: 'app-course-list',
  templateUrl: './course-list.component.html',
  styleUrls: ['./course-list.component.css'],
})
export class CourseListComponent implements OnInit {
  TrainingList: any = [];
  courseid = '';
  buttonType = '';
  FinalChaptersArray: any = [];
  Chapters: any = [];
  ID: any;
  allTranings = true;
  applaidTrainings = false;
  favourateTrainings = false;
  StartCourse = false;
  isnoDataFound = false;
  noDataMsg: any;
  showType = 'all';
  filter = { limit: 0, page: 0 };
  finish = true;
  isLoading = false;
  studentId: any;
  disableButton = true;
  isDataFound = false;
  FILE_PATH_LINODE = APP_URLS.FILE_PATH_LINODE;
  recordCount: any;
  applyStatus: any

  constructor(
    public service: UserService,
    public courcecms: CourseService,
    private cms: CommonService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    const userDetails = localStorage.getItem('userDetails');
    const data = JSON.parse(userDetails);
    if (data) {
      this.studentId = data._id;
    }
  }

  ngOnInit() {
    const status = this.route.snapshot.paramMap.get('applyStatus');
    this.applyStatus = status == 'true' ? true : false
    this.filter.limit = 12;
    this.filter.page = 0;

    this.ShowAllTrainings();
    this.courseid = this.courcecms.courceid;
    this.buttonType = this.courcecms.buttonType;
    if (
      this.courcecms.buttonType !== '' &&
      this.courcecms.buttonType === 'apply'
    ) {
      this.apply(this.courcecms.courceid);
    }
  }

  apply(data: any) {
    const url = APP_URLS.Courses + '/' + data + '?applycourse=true';

    this.service.putCallwithHeaders(url, {}).subscribe(
      (result: any) => {
        this.service.showSuccess(result.respMessage);
        this.courcecms.selectedcourse('', '');
        // this.getTrainingList();
        const index = this.TrainingList.findIndex(
          (item: any) => item?._id === data
        );

        if (index > -1) {
          if (result?.details?.applied) {
            this.TrainingList[index].apply_status = 'applied';
          }
        }
      },
      (err) => {}
    );
  }

  alreadyApplied() {
    this.service.showSuccess(
      'You have already applied for this course and waiting for Admin approval. For further information, you may contact your Admin'
    );
  }

  waitingForApproval() {
    this.service.showError(
      'Waiting for Admin approval. Contact your Admin for further information'
    );
  }

  addFavorateFun(data: any) {
    const url = APP_URLS.Courses + '/' + data + '?favorite=true';

    this.service.putCallwithHeaders(url, {}).subscribe(
      (result: any) => {
        this.service.showSuccess(result.respMessage);

        const index = this.TrainingList.findIndex(
          (item: any) => item?._id === data
        );

        if (index > -1) {
          this.TrainingList[index].fav_status = result?.details?.favorite;
        }
        this.courcecms.selectedcourse('', '');
        if (this.showType === 'favorites') {
          this.FavouriteTrainings();
        } else {
          this.getTrainingList();
        }
      },
      (err) => {}
    );
  }

  /**
   * Load all notes data
   */
  viewAll(): void {
    this.isLoading = true;
    this.filter.limit = 0;
    this.filter.page = 0;
    this.getTrainingList(); // get notes data
  }

  /**
   * Load more notes based on limit
   */
  loadMore(): void {
    this.isLoading = true;
    this.filter.limit = 12;
    this.filter.page = this.filter.page + 1;
    this.getTrainingList(); // get notes data
  }

  getTrainingList() {
    this.cms.loaderSet(true);
    this.isLoading = true;
    let url = APP_VLE_URLS.v1.getMycourseList + '?type=' + this.showType;
    // if( this.filter.limit ){
    url = url + '&filter=' + JSON.stringify(this.filter);
    // }
    this.service.getCallWithHeaders(url).subscribe((res: any) => {
      const targetArr = res?.courses;
      this.recordCount = res?.pagination
      if (targetArr) {
        targetArr.map((arr: any, i: any) => {
          if (!this.TrainingList.some((o2: any) => arr._id === o2._id)) {
            this.TrainingList.push(arr);
          }
        });
      }

      if (this.filter.limit === 0) {
        this.disableButton = false;
      } else if (this.TrainingList.length < res?.pagination.totalRecords) {
        this.disableButton = true;
      } else {
        this.disableButton = false;
      }

      if (!res?.courses.length) {
        this.disableButton = false;
      }

      this.TrainingList.sort((a: any, b: any) => {
        return (new Date(b.created) as any) - (new Date(a.created) as any);
      });

      this.isnoDataFound = this.TrainingList.length ? false : true;
      this.isDataFound = this.TrainingList.length ? true : false;

      if (!this.TrainingList.length) {
        this.isnoDataFound = true;
        if (this.showType === 'all') {
          this.noDataMsg = 'No course(s) found';
        } else if (this.showType === 'applied') {
          this.noDataMsg = 'No course(s) are applied';
        } else if (this.showType === 'assigned') {
          this.noDataMsg = 'No course(s) are approved';
        } else if (this.showType === 'favorites') {
          this.noDataMsg = 'No favourite course(s) found';
        }
      }
      if (
        this.courcecms.courceid !== '' &&
        this.courcecms.buttonType === 'favourite'
      ) {
        if (this.TrainingList.length > 0) {
          this.TrainingList.forEach((x: any) => {
            if (this.courcecms.courceid === x._id) {
              if (x.fav_status === false) {
                this.addFavorateFun(this.courcecms.courceid);
              }
            }
          });
        }
      }
      this.isLoading = false;
      this.cms.loaderSet(false);
    });
  }

  ShowAllTrainings() {
    this.isnoDataFound = false;
    this.allTranings = true;
    this.applaidTrainings = false;
    this.favourateTrainings = false;
    this.showType = 'all';
    this.TrainingList = [];
    this.disableButton = false;
    this.filter.page = 0;
    this.filter.limit = 12;
    this.getTrainingList();
  }

  AppliedTrainings() {
    this.isnoDataFound = false;
    this.allTranings = false;
    this.applaidTrainings = true;
    this.favourateTrainings = false;
    this.StartCourse = true;
    this.showType = 'applied';
    this.TrainingList = [];
    this.disableButton = false;
    this.filter.page = 0;
    this.filter.limit = 12;
    this.getTrainingList();
  }

  ApprovedTrainings() {
    this.isnoDataFound = false;
    this.allTranings = false;
    this.applaidTrainings = false;
    this.favourateTrainings = false;
    this.StartCourse = true;
    this.showType = 'assigned';
    this.TrainingList = [];
    this.disableButton = false;
    this.filter.page = 0;
    this.filter.limit = 12;
    this.getTrainingList();
  }

  FavouriteTrainings() {
    this.isnoDataFound = false;
    this.allTranings = false;
    this.applaidTrainings = false;
    this.favourateTrainings = true;
    this.showType = 'favorites';
    this.TrainingList = [];
    this.disableButton = false;
    this.filter.page = 0;
    this.filter.limit = 12;
    this.getTrainingList();
  }

  openCourse(Training: any) {
    console.log(Training);
    let selectIndex = 0;
    localStorage.setItem('courseId', Training._id);
    localStorage.setItem('TrainingName', Training.name);
    localStorage.setItem('TrainingFullName', Training.fullName);
    localStorage.setItem('Coursetype', 'chapter');
    if (
      Training.apply_status === 'assigned' &&
      Training.resume_chapter === ''
    ) {
      this.router.navigate(['vle/my-course/', Training._id]);
    }else if (Training.apply_status === 'assigned' &&  Training.resume_chapter !== '') {
      this.ID = localStorage.getItem('courseId');
      const url =
        APP_VLE_URLS.newchapters +
        '{"criteria":[{"key":"tenantId","value":"' +
        TenantId +
        '","type":"eq"},{"key":"course","value":"' +
        this.ID +
        '","type":"eq"},{"key": "applystatus","value":"'+ this.applyStatus +'"}]}';
      this.service.getCallWithHeaders(url).subscribe(
        (res: any) => {
          this.Chapters = res.cou_overview;
          sessionStorage.setItem('courseDetails', JSON.stringify(res));
          // localStorage.setItem('courseDetails', JSON.stringify(res));
          let uuid = '';
          if (
            res?.chapter_uuid_status?.finish === 0 &&
            !res?.chapter_uuid_status?.finish_uuid?.length
          ) {
            if (res?.cou_overview?.length) {
            const val =  res?.cou_overview.find((c) => {
                if (c.child?.length) {
                  return c.child;
                }
              })
              uuid = val ? val[0]?.uuid : ''
              console.log(uuid)
            }else if(!res?.cou_overview?.length){
              this.service.showError('No chapter avaliable for this course')
              return
            }
          } else if (
            res?.chapter_uuid_status?.not_finish === 1 &&
            res?.chapter_uuid_status?.not_finish_uuid?.length === 1
          ) {
            uuid = res?.chapter_uuid_status?.not_finish_uuid[0];
          } else if (
            res?.chapter_uuid_status?.not_finish === 0 &&
            !res?.chapter_uuid_status?.not_finish_uuid?.length
          ) {
            uuid = res?.chapter_uuid_status?.completed_chapters[0];
          } else {
            const dummyuuid =
              res?.chapter_uuid_status?.completed_chapters[
                res?.chapter_uuid_status?.completed_chapters?.length - 1
              ];
            let data: any;
            const today = moment().format('DD-MM-YYYY');
            res?.cou_overview?.forEach((element, index) => {
              element?.child?.find((uid, i) => {
                if (uid?.uuid === dummyuuid) {
                  const selectedData = res?.courseTermDetail[0]?.due_date_detail.find((e) => {return e?.chapter === element?.child[i + 1]?._id})
                  if (selectedData && moment(selectedData?.openDate).format('DD-MM-YYYY') > today) {
                    data = element?.child[i];
                  }else{
                    data = element?.child[i + 1];
                  }
                  selectIndex = index;
                }
              });
            });
            uuid = data?.uuid;
          }
          localStorage.setItem('uuid', uuid);
          localStorage.setItem('resumeChapter', JSON.stringify(selectIndex));
          this.setChaptersData();
        },
        (err) => {}
      );
    }
  }

  CourseNameClick(Training: any) {
    localStorage.setItem('courseId', Training._id);
    localStorage.setItem('TrainingName', Training.name);
    localStorage.setItem('TrainingFullName', Training.fullName);
    // localStorage.setItem('uuid', Training.resumeChapter);
    if (Training.apply_status === 'assigned') {
      this.router.navigate(['vle/my-course/', Training._id, {applyStatus: true}]);
    } else {
      this.service.showError('Add As Favourite or Apply');
    }
  }

  getData() {
    this.ID = localStorage.getItem('courseId');
    const url =
      APP_VLE_URLS.newchapters +
      '{"criteria":[{"key":"tenantId","value":"' +
      TenantId +
      '","type":"eq"},{"key":"course","value":"' +
      this.ID +
      '","type":"eq"},{"key": "applystatus","value":"'+ this.applyStatus +'"}]}';
    this.service.getCallWithHeaders(url).subscribe(
      (res: any) => {
        this.Chapters = res.cou_overview;
        this.setChaptersData();
      },
      (err) => {}
    );
  }

  setChaptersData() {
    this.Chapters.forEach((x: any, i: any) => {
      x.accordion = '#accordion' + i;
      x.accordionID = 'accordion' + i;
      x.child.forEach((y: any, index: any) => {
        y.childaccordion = '#childaccordion' + index;
        y.childaccordionID = 'childaccordion' + index;
        if (y.chapter_type === 'lesson') {
          y.icon = 'fa fa-book';
        }
        if (y.chapter_type === 'assignment') {
          y.icon = 'fa fa-code';
        }
        if (y.chapter_type === 'downloads') {
          y.icon = 'fas fa-cloud-download-alt';
        }
        if (y.chapter_type === 'test' || y.chapter_type === 'qatest') {
          y.icon = 'fas fa-pencil-alt';
        }
        if (y.chapter_type === 'quiz') {
          y.icon = 'fa fa-graduation-cap';
        }
        if (y.chapter_type === 'chapter') {
          y.icon = 'fa fa-bars';
        }
      });
      this.FinalChaptersArray.push(x);
      // if (x.child.length && this.finish) {
      //   const data = x.child[0]?.uuid;
      //   localStorage.setItem('uuid', data);
      //   this.finish = false;
      // }
      localStorage.setItem(
        'AllChapters',
        JSON.stringify(this.FinalChaptersArray)
      );
      const Trainingid: any = localStorage.getItem('courseId');
      const TrainingName: any = localStorage.getItem('TrainingName');
      const resumeChapter = localStorage.getItem('uuid');
      this.router.navigate([
        'vle/Mychapter/',
        Trainingid + '-' + resumeChapter,
        TrainingName.replace(/\s/g, '-'),{applyStatus: true}
      ]);
    });
  }
}
