import { UserService } from './../../../services/userservice.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-vle-main',
  templateUrl: './vle-main.component.html',
  styleUrls: ['./vle-main.component.css'],
})
export class VleMainComponent implements OnInit {
  isShowHeaderFooter: any;
  constructor(private service: UserService, private routetype: ActivatedRoute) {
    this.service.setHeaderFooterValue.subscribe((val) => {
      this.isShowHeaderFooter = val;
    });
  }

  ngOnInit(): void {}
}
