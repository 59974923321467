<main class="inner-page">

    <!-- ======= Breadcrumbs Section ======= -->
    <section class="breadcrumb">
        <div class="container-fluid">
            <div class="d-flex justify-content-between align-items-center text-xs-center">
                <ul>
                    <li><a href="javascript:void(0)" routerLink="/">Home</a></li>
                    <li>About Us</li>
                </ul>

            </div>
        </div>
    </section>
    <!-- End Breadcrumbs Section -->

    <!-- ======= Content Section ======= -->
    <section id="main">
        <div class="container-fluid">
            <div class="main-content">
                <div class="d-flex justify-content-between align-items-center">
                    <h1 class="page-header-title-1">About Us</h1>
                </div>
                <hr class="hr" />
                <div class="my-profile">
                    <div class="row">
                        <div class="hd-cntnt mt-3 clrbth col-md-12" style="width: 100% !important;">
                            <h3 style="text-align: justify;"><strong>About ADMI</strong></h3>
                            <p style="text-align: justify;"><strong>Purpose:</strong> To bridge the growing digital skill gap because of the global digitalization drive across industries based on the following:</p>
                            <ul style="text-align: justify;">
                                <li>Demand for people with high-level digital skills is greater than the supply of suitably qualified employees, and the gap is growing. The World Economic Forum estimates that by 2022 emerging technologies will generate 133
                                    million new jobs in place of the 75 million that will be displaced</li>
                                <li>The report, Bridging the Digital Divide, also reports that 37 per cent of jobs are expected to alter significantly in the next five years — which could see 12 million employees in the UK affected by changing roles or potential
                                    redundancies.
                                    <br><br></li>
                            </ul>
                            <p style="text-align: justify;"><span style="text-decoration: underline;"><strong>Objective:</strong></span> To make this platform a dependable and authentic digital skills e-learning platform<br> <br><span style="text-decoration: underline;"><strong>How:</strong></span>                                By powering the learning with</p>
                            <ul style="text-align: justify;">
                                <li>Ease to study &lt; step by step training &gt;</li>
                                <li>Completeness &lt; end to end training &gt;</li>
                                <li>Result oriented i.e., job ready &lt; with task wise assignments &amp; assessments &gt;</li>
                                <li>Authentic &lt; with right accreditations &gt;</li>
                            </ul>
                            <p style="text-align: justify;"><strong>ADMI smart learning system’s specialities</strong></p>
                            <ul>
                                <li style="text-align: justify;">It is expected to help learners acquire skill in every aspect of each of the topic with incorporated assignments &amp; assessments</li>
                                <li style="text-align: justify;">Model’s offering is ready made version of the digital skills training for all the approved digital skills apprenticeship providers in the UK with backend management dashboard to track the attendance, progress of learning,
                                    assignment submissions, assessments reports, session prompts to learners and tutors, help to the learners who struggle</li>
                                <li style="text-align: justify;">There are set of programs being provided for free learning for all the digital skills enthusiasts</li>
                                <li style="text-align: justify;">Some of ADMIs programs will be accredited</li>
                                <li style="text-align: justify;">Select start-ups are expected to get free resources (for limited time). Free resources are ADMI’s students who will get actual work experience in return</li>
                                <li>Platform is poised to become go to site for digital skills seekers with
                                    <ul>
                                        <li style="text-align: justify;">free learning video resources</li>
                                        <li style="text-align: justify;">free templates</li>
                                        <li style="text-align: justify;">new tech updates</li>
                                        <li style="text-align: justify;">free trials</li>
                                        <li style="text-align: justify;">limited free advisory for select startups</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <!-- Content Section -->

</main>
<!-- End #main -->
