<!-- ======= Top Bar ======= -->
<section id="topbar" class="d-flex align-items-center fixed-top topbar-transparent d-xs-none"
  [ngClass]="{ 'topbar-scrolled': ishome === true }">
  <div class="container-fluid d-flex justify-content-end">
    <i class="d-lg-flex align-items-center"><span>
        <a href="mailto:{{ tenantInfo?.email }}" class="text-white">
          {{ tenantInfo?.email }}</a></span></i>
    <i class="d-flex align-items-center ms-3"><span><a href="tel:{{tenantInfo?.landline}}"
          class="text-white">{{tenantInfo?.landline}}</a></span></i>
  </div>
</section>
<!-- ======= Top Bar ======= -->

<!--==============================
Mobile Menu
============================== -->
<div class="vs-menu-wrapper">
  <div class="vs-menu-area text-center">
      <button class="vs-menu-toggle" id="close-menu"><i class="fas fa-times"></i></button>
      <div class="mobile-logo">
          <a routerLinkActive="active" [routerLink]="['/']" (click)="mobileLogoClick()"><img [attr.src]="tenantInfo?.logoUrl" style="width: 120px !important;" ></a>
      </div>
      <div class="vs-mobile-menu">
          <ul>
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/']">Home</a>
              </li>

              <ng-container *ngIf="token == false">
                <li class="nav-item" *ngFor="let item of categoryData">
                  <a href="javascript:void(0)" class="nav-link" [routerLink]="['/Courses/' + item.course_type]"  (click)="openCourses(item, 'mobile-nav')" routerLinkActive="active" >
                    {{ item?.course_type }}
                  </a>
                </li>
              </ng-container>
              <li class="menu-item-has-children">
                <a href="javascript:void(0)" class="nav-link">ABOUT <i class="bi bi-chevron-down"></i></a>
                <ul class="sub-menu">
                    <li>
                      <a routerLinkActive="active" [routerLink]="['/whyus']">WHY US</a>
                    </li>
                    <li>
                      <a routerLinkActive="active" [routerLink]="['/ourApproach']"><span>OUR APPROACH</span></a>
                    </li>
                    <li>
                      <a routerLinkActive="active" [routerLink]="['/governance']"><span>GOVERNANCE</span></a>
                    </li>
                </ul>
              </li>
              <li class="menu-item-has-children">
                <a href="javascript:void(0)" class="nav-link">SUPPORT <i class="bi bi-chevron-down"></i></a>
                <ul class="sub-menu">
                    <li>
                      <a href="" [routerLink]="['/login','employee']"><span>STAFF-LOGIN</span></a>
                    </li>
                    <li>
                      <a routerLinkActive="active" [routerLink]="['/contact']"><span>CONTACT</span></a>
                    </li>
                </ul>
              </li>

              <li class="nav-item d-lg-none">
                <a href="javascript:void(0)" [routerLink]="['/login']" class="btn-1 text-white d-flex justify-content-center">
                  SIGN IN
                </a>
              </li>
          </ul>
      </div>
  </div>
</div>

<!--==============================
Header Area
==============================-->
<header class="vs-header header-layout1">
  <div class="sticky-wrapper">
      <div class="sticky-active">
          <!-- Main Menu Area -->
          <div class="header-inner py-3">
              <div class="container-fluid">
                  <div class="row align-items-center justify-content-between">
                      <div class="col-auto">
                          <div class="header-logo py-3 py-lg-0">
                              <a routerLinkActive="active" [routerLink]="['/']"><img [attr.src]="tenantInfo?.logoUrl" ></a>
                          </div>
                      </div>
                      <div class="col-auto">
                          <div class="d-flex align-items-center justify-content-center">
                              <nav class="navbar navbar-nav order-last order-lg-0 main-menu menu-style1 d-none d-lg-block">
                                  <ul>
                                      <li class="dropdown nav-item ">
                                        <a routerLinkActive="active" [routerLink]="['/']">Home</a>
                                      </li>
                                      <ng-container *ngIf="token == false">
                                        <li class="dropdown nav-item" *ngFor="let item of categoryData">
                                          <a href="javascript:void(0)" [routerLink]="['/Courses/' + item.course_type]"  (click)="openCourses(item, 'nav-menu')"><span>{{ item?.course_type }}</span><i
                                              class="bi bi-chevron-down"></i></a>
                                          <ul style="width: 25rem">
                                            <li class="nav-item" *ngFor="
                                                let course of item?.courses | slice: 0:10;
                                                let i = index
                                              " (click)="openIndividulaCourse(course)">
                                              <a href="javascript:void(0)" class="tab-content" style="white-space: break-spaces;">{{ course?.name |
                                                uppercase }}</a>
                                            </li>
                                            <li class="nav-item" (click)="openCourses(item, 'nav-menu')" *ngIf="item.courses.length > 10">
                                              <a href="javascript:void(0)">View More ( {{ item.courses.length }} )</a>
                                            </li>
                                          </ul>
                                        </li>
                                      </ng-container>
                                      <li class="dropdown nav-item">
                                        <a href="javascript:void(0)"><span>ABOUT</span> <i class="bi bi-chevron-down"></i></a>
                                        <ul>
                                          <li class="nav-item">
                                            <a routerLinkActive="active" [routerLink]="['/whyus']">WHY US</a>
                                          </li>
                                          <li class="nav-item">
                                            <a routerLinkActive="active" [routerLink]="['/ourApproach']"><span>OUR APPROACH</span></a>
                                          </li>
                                          <li class="nav-item">
                                            <a routerLinkActive="active" [routerLink]="['/governance']"><span>GOVERNANCE</span></a>
                                          </li>
                                        </ul>
                                      </li>
                                      <li class="dropdown nav-item">
                                        <a href="javascript:void(0)"><span>SUPPORT</span> <i class="bi bi-chevron-down"></i></a>
                                        <ul>
                                          <li class="nav-item">
                                            <a href="" [routerLink]="['/login','employee']"><span>STAFF-LOGIN</span></a>
                                          </li>
                                          <li class="nav-item">
                                            <a routerLinkActive="active" [routerLink]="['/contact']"><span>CONTACT</span></a>
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <a [routerLink]="['/login']" href="javascript:void(0)" class="btn-1 scrollto p-3">SIGN IN</a>
                                      </li>
                                      <li role="menuitem" class="nav-item" (click)="openCart()" *ngIf="userRole != 'employer' && userRole != 'staff'">
                                        <div style="position :relative;cursor: pointer; color: #ffffff;" class="p-3">
                                          <i class="fa fa-cart-plus"></i>
                                          <div class="count-styles">{{ CartCount }}</div>
                                        </div>
                                      </li>
                                  </ul>
                              </nav>
                              <button type="button" class="vs-menu-toggle d-inline-block d-lg-none">
                                <i class="fas fa-bars"></i>
                              </button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</header>

<div class="offcanvas offcanvas-end" tabindex="-1" id="open-coursetype" aria-labelledby="course-add-formLabel">
  <div class="offcanvas-header">
    <h5 id="coursetype-Label">Course Category</h5>
    <button type="button" class="btn-danger" id="close-coursetype" data-bs-dismiss="offcanvas"
      aria-label="Close">Close</button>
  </div>
  <div class="offcanvas-body" *ngIf="isOpenCourseType">
    <ul class="ps-0">
      <li *ngFor="let type of categoryData" class="cursor card">
        <a href="javascript:void(0)" class="p-2" (click)="openCourses(type)">{{ type?.course_type }}</a>
      </li>
    </ul>
  </div>
</div>
