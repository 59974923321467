<main class="inner-page">

    <!-- ======= Breadcrumbs Section ======= -->
    <section class="breadcrumb">
        <div class="container-fluid">
            <div class="d-flex justify-content-between align-items-center text-xs-center">
                <ul>
                  <ng-container *ngIf="token !== '' && token !== null">
                    <li><a href="javascript:void(0)"  [routerLink]="['/vle/home']">Home</a></li>
                  </ng-container>
                    <ng-container *ngIf="token === '' || token === null" >
                      <li><a href="javascript:void(0)" routerLink="/">Home</a></li>
                    </ng-container>
                    <li>Governance</li>
                </ul>
            </div>
        </div>
    </section>
    <!-- End Breadcrumbs Section -->

    <!-- ======= Content Section ======= -->
    <section id="main">
        <div class="container-fluid">
            <div class="main-content">
                <div class="d-flex justify-content-between align-items-center">
                    <h1 class="page-header-title-1">Governance</h1>
                </div>
                <hr class="hr" />
                <div class="governance">
                    <div class="row">
                        <div class="col-lg-12">
                            <h3 class="mb-3">Management and Governance</h3>
                            <p>Applied Business Academy has a management structure headed by a Board of Governance which employs the Chief Executive as the head of the institution with responsibilities of academic leadership and oversight. The Board of Governance
                                comprises the Directors and senior managers, together with external independent members and a Chair.</p>
                            <p>Our external independent members includes:</p>
                            <ul>
                                <li>Mr Derek Shields</li>
                                <li>Ms Sarah Colmar</li>
                                <li>Dr Eleanor Pritchard</li>
                                <li>Mr Santosh Chavare</li>
                            </ul>
                            <div class="row">
                                <div class="col-lg-3 col-md-3 ">
                                    <img src="./assets/img/governance/chairman.jpg" alt="" class="img-fluid mb-3">
                                </div>
                                <div class="col-lg-9  col-md-9 ">
                                    <h3 class="mb-3">From our Chairman – Derek Shields</h3>
                                    <p>
                                        From our Chairman – Derek Shields I was delighted to be invited to chair the inaugural Board of the Applied Business Academy (ABA) and now look forward to playing a full part in guiding it towards a successful and sustainable future.</p>
                                    <p>As board members, our main task will be to ensure that ABA achieves its stated purpose of providing high-quality, practically-oriented programmes. ABA is part of the private education sector, and so we must be alert
                                        to the need to provide value for money and career for students. We will oversee academic standards, ensuring that ABA works within national and international assessment and regulatory frameworks, and that the organization
                                        and management of the institution is fit for purpose.</p>
                                    <p>I feel confident that the composition of the Board is adequate to its task and look forward to our helping to steer ABA towards its objectives.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Content Section -->

</main>
<!-- End #main -->