<!-- ======= Contact ======= -->
<div class="contact">
    <div class="container pt-200">
        <div class="row g-0">
            <div class="col-lg-6 col-left">
            </div>
            <div class="col-lg-6">
                <div class="header-wrapper pt-0 align-items-md-start">
                    <h1 class="page-header-title">Contact</h1>
                    <div class="header-subtitle">Please fill in the below form </div>
                </div>
                <form [formGroup]="contactForm">

                    <div class="row contact-sec frm-field-mt-1">
                        <div class="col-12 col-lg-6 col-md-6">
                            <input class="form-control mb-3 frm-input" type="text" placeholder="FIRST NAME" id="fname" name="fname" formControlName="fname" maxlength="40" [ngClass]="{ 'is-invalid': submitted && f?.fname?.invalid }" required>
                            <div *ngIf="submitted && f?.fname?.invalid" class="invalid-feedback mb-3">
                                <div *ngIf="f?.fname?.errors?.required">{{resourceData?.first_name_required}}</div>
                                <div *ngIf="f?.fname?.errors?.pattern">{{resourceData?.invalid_first_name}}</div>
                                <div *ngIf="f?.fname?.errors?.maxlength">{{resourceData?.first_name_max_length}}</div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 col-md-6">
                            <input class="form-control mb-3 frm-input frm-input-2 " type="text" placeholder="LAST NAME" id="lname" name="lname" formControlName="lname" maxlength="40" [ngClass]="{ 'is-invalid': submitted && f?.lname?.invalid }">
                            <div *ngIf="submitted && f?.lname?.invalid" class="invalid-feedback mb-3">
                                <div *ngIf="f?.lname?.errors?.required">{{resourceData?.last_name_required}}</div>
                                <div *ngIf="f?.lname?.errors?.pattern">{{resourceData?.invalid_last_name}}</div>
                                <div *ngIf="f?.lname?.errors?.maxlength">{{resourceData?.last_name_max_length}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row contact-sec frm-field-mt-2">
                        <div class="col-12 col-lg-6 col-md-6">
                            <input class="form-control mb-3 frm-input" type="text" maxlength="16" placeholder="PHONE" id="phone" name="phone" formControlName="phone" [ngClass]="{ 'is-invalid': submitted && f?.phone?.invalid }">
                            <div *ngIf="submitted && f?.phone?.invalid" class="invalid-feedback mb-3">
                                <div *ngIf="f?.phone?.errors?.required">{{resourceData?.phone_number_required}}</div>
                                <div *ngIf="f?.phone?.errors?.pattern">{{resourceData?.invalid_Mobile_number}}</div>
                                <div *ngIf="f?.phone?.errors?.maxlength">{{resourceData?.phone_number_max_16}}</div>
                                <div *ngIf="f?.phone?.errors?.minlength">{{resourceData?.phone_number_max_5}}</div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 col-md-6 col-sec-2">
                            <input class="form-control mb-3 frm-input frm-input-2" type="email" placeholder="EMAIL" id="email" name="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f?.email?.invalid }">
                            <div *ngIf="submitted && f?.email?.invalid" class="invalid-feedback mb-3">
                                <div *ngIf="f?.email?.errors?.required">{{resourceData?.emp_email_required}}</div>
                                <div *ngIf="f?.email?.errors?.pattern">{{resourceData?.invalid_emp_email_address}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row frm-field-mt-2">
                        <div class="col-12 col-lg-12 col-md-12">
                            <textarea class="form-control mb-3 message" id="exampleFormControlTextarea1" placeholder="MESSAGE" rows="3" id="msg" name="msg" formControlName="msg"></textarea>
                        </div>

                    </div>
                    <button type="button" class="btn-4" (click)="Contactus()">SUBMIT</button>
                </form>
            </div>
        </div>
        <div class="row mt-100 contact-footer">
            <div class="col-md-4">
                <i class="bi bi-telephone-fill"></i> {{tenantInfo?.landline}}
            </div>
            <div class="col-md-4">
                <i class="bi bi-geo-alt-fill"></i> {{tenantInfo?.tenantAddress}}
            </div>
            <div class="col-md-4">
                <i class="bi bi-at"></i> {{tenantInfo?.email}}
            </div>
        </div>
    </div>
</div>
