<!-- ======= Footer ======= -->
<footer id="footer">
    <div class="footer-top">
        <div class="container-fluid">
            <div class="row">

                <div class="col-lg-3 col-md-6">
                    <div class="footer-info text-xs-center">
                        <h3><img [attr.src]="tenantdata?.logoUrl" style="width: 120px !important;" /></h3>
                        <p>{{tenantdata.tenantAddress}} <br>
                            <strong>Phone:</strong> {{tenantdata.landline}}
                            <br>
                            <strong>Email:</strong> {{tenantdata.email}}
                            <br>
                        </p>
                        <div class="social-links mt-3">
                            <a href="javascript:void(0)" target="_blank" class="twitter"><i class="bi bi-twitter"></i></a>
                            <a href="javascript:void(0)" target="_blank" class="facebook"><i class="bi bi-facebook"></i></a>
                            <a href="javascript:void(0)" target="_blank" class="instagram"><i class="bi bi-instagram"></i></a>
                            <a href="javascript:void(0)" target="_blank" class="linkedin"><i class="bi bi-linkedin"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6 col-6 footer-links text-xs-center">
                    <h4>Useful Links</h4>
                    <ul>
                        <li><a routerLinkActive="active" [routerLink]="['/vle/home']">Home</a></li>
                        <li><a routerLinkActive="active" [routerLink]="['/vle/whyus']">Why us</a></li>
                        <li><a routerLinkActive="active" [routerLink]="['/vle/ourApproach']">Our Approach</a></li>
                        <li><a routerLinkActive="active" [routerLink]="['/vle/governance']">Governance</a></li>
                    </ul>
                </div>

                <div class="col-lg-3 col-md-6 col-6 footer-links  text-xs-center">
                    <h4>Our Services</h4>
                    <ul>
                        <li *ngIf="!isUserRole"><a routerLinkActive="active" [routerLink]="['/vle/contact']">Contact</a></li>
                        <li><a routerLinkActive="active" [routerLink]="['/vle/privacypolicy']">Privacy-Policy</a></li>
                        <li><a routerLinkActive="active" [routerLink]="['/vle/aboutus']">About us</a></li>
                        <li><a routerLinkActive="active" [routerLink]="['/vle/termsAndConditions']">Terms & Condition</a></li>
                        <li><a routerLinkActive="active" [routerLink]="['/vle/helpdesk']">Help Desk</a></li>
                    </ul>
                </div>

                <div *ngIf="!isUserRole" class="col-lg-4 col-md-6 footer-newsletter text-xs-center">
                    <h4>Our Newsletter</h4>
                    <p>Bring your ideas to life, learn at the bottom by WeTransfer, along with links to their merchandise.</p>
                    <form [formGroup]="newsLetterFrom">
                        <input type="email" id="email" formControlName="email" required>
                        <input type="submit" (click)="Newsletter()" value="SUBSCRIBE">

                    </form>
                    <div class="copyright">
                        © 2022 {{tenantdata?.name}}
                    </div>
                </div>

            </div>
        </div>
    </div>
</footer>
<!-- End Footer -->

<a href="javascript:void(0)" (click)="scrollToTop()" class="back-to-top d-flex align-items-center justify-content-center" [ngClass]="{'active': scroll === true }"><i
class="bi bi-arrow-up-short"></i></a>
