import { logging } from 'protractor';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SettingsService {



  private _settingsDetails = new BehaviorSubject({});
  settingsDetails = this._settingsDetails.asObservable();

  private _tenantBasicDetails = new BehaviorSubject({});
  tenantBasicDetails = this._tenantBasicDetails.asObservable();

  private _logoimage = new BehaviorSubject({});
  logoImage = this._logoimage.asObservable();

  private _favIcon = new BehaviorSubject({});
  favIcon = this._favIcon.asObservable();

  constructor(private httpclient: HttpClient, private toastr: ToastrService) {
  }

  setSettingsDetails(data: any) {
    this._settingsDetails.next(data);
  }

  setTenantBasicDetails(data: any) {
    this._tenantBasicDetails.next(data);
  }

  setlogoimage(data:any){
    this._logoimage.next(data);
  }
  getlogoimage() {
    return this._logoimage.asObservable();
  }

  setFavIcon(data:any){
    this._favIcon.next(data);
  }
  getFavIcon() {
    return this._favIcon.asObservable();
  }
}
