import { UserService } from 'src/app/services/userservice.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { APP_URLS, TenantId,tenantConfig } from 'src/app/Utils/general';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as ResourceJson from '../../Utils/crm-resource.json';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css'],
})
export class ContactusComponent implements OnInit, OnDestroy {
  contactForm = new FormGroup({});
  submitted = false;
  resourceData: any = (ResourceJson as any).default;
  tenantInfo: any = tenantConfig;


  constructor(private user: UserService) {
    document.body.className = 'contact-bg';
  }

  ngOnInit(): void {
    this.contactForm = new FormGroup({
      fname: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z ]*$/),
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
        Validators.maxLength(40),
      ]),
      lname: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z ]*$/),
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
        Validators.maxLength(40),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/),
      ]),
      phone: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[0-9]*$/),
        Validators.maxLength(16),
        Validators.minLength(5),
      ]),
      msg: new FormControl(''),
    });
  }

  /**
   * get form controls
   */
  get f() {
    return this.contactForm.controls;
  }

  ngOnDestroy(): void {
    document.body.className = '';
  }

  Contactus() {
    console.log(this.contactForm);

    this.submitted = true;
    if (this.contactForm.invalid) {
      return;
    }
    const url = APP_URLS.student;
    const payload = {
      first_name: this.contactForm.value.fname,
      last_name: this.contactForm.value.lname,
      mobile: this.contactForm.value.phone,
      email: this.contactForm.value.email,
      subject: this.contactForm.value.msg,
      tenantId: TenantId,
      from_place: 'contact',
    };
    this.user.postCall(url, payload).subscribe(
      (res: any) => {
        this.submitted = false;
        if (res.respCode === 201) {
          this.user.showSuccess(res.respMessage);
          this.contactForm.reset();
        } else {
          this.user.showError(res.respMessage);
        }
      },
      (error) => {
        this.user.showError('contact Failed');
      }
    );
  }
}
