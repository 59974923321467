import { Component, OnInit } from '@angular/core';
import { tenantConfig } from 'src/app/Utils/general';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css'],
})
export class PrivacyPolicyComponent implements OnInit {
  tenantInfo: any = tenantConfig;

  token = '';
  constructor() {
    this.token = localStorage.getItem('token');
  }

  ngOnInit(): void {}
}
