import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TenantId, tenantConfig } from '../Utils/general';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  chapterDetails: any[] = [];
  allChapterData: any;
  cartdata: Subject<any>;

  gaippToken: any;
  private _userDetails = new BehaviorSubject({});
  userDetails = this._userDetails.asObservable();

  private _loggedUserDetails = new BehaviorSubject({});
  loggedUserDetails = this._loggedUserDetails.asObservable();

  private _setApplicationData = new BehaviorSubject({});
  applicationData = this._setApplicationData.asObservable();

  private _setChapterData = new BehaviorSubject('');
  setChapterData = this._setChapterData.asObservable();

  private _getCompetences = new BehaviorSubject([]);
  getCompetences = this._getCompetences.asObservable();

  private _setSelectedData = new BehaviorSubject('');
  setSelectedData = this._setSelectedData.asObservable();

  private _getCourseTermData = new BehaviorSubject([]);
  getCourseTermData = this._getCourseTermData.asObservable();

  private _setCourseMaterialDetails = new BehaviorSubject('');
  setCourseMaterail = this._setCourseMaterialDetails.asObservable();

  private _partnerUserDetails = new BehaviorSubject([]);
  partnerUserDetails = this._partnerUserDetails.asObservable();

  private _newlyAddedStudent = new BehaviorSubject({});
  newlyAddedStudent = this._newlyAddedStudent.asObservable();

  private _userPermission = new BehaviorSubject({});
  userPermission = this._userPermission.asObservable();

  private _globalSearch = new BehaviorSubject({});
  globalSearch = this._globalSearch.asObservable();

  private _taskUpdate = new BehaviorSubject(false);
  taskUpdate = this._taskUpdate.asObservable();

  private _TenantSettingsData = new BehaviorSubject({});
  tenantSettingsData = this._TenantSettingsData.asObservable();

  private _islogin = new BehaviorSubject(false);
  islogin = this._islogin.asObservable();

  private _profileSub = new BehaviorSubject('');
  profileSub = this._profileSub.asObservable();

  private _sidemenutab = new BehaviorSubject('');
  sidemenutab = this._sidemenutab.asObservable();

  private _setSettingsFileImage = new BehaviorSubject({});
  setSettingsFileImages = this._setSettingsFileImage.asObservable();

  private _TickentCount = new BehaviorSubject('');
  TickentCount = this._TickentCount.asObservable();

  private _NotifyCount = new BehaviorSubject('');
  NotifyCount = this._NotifyCount.asObservable();

  private _editprofile = new BehaviorSubject('');
  editprofile = this._editprofile.asObservable();

  private _nexttab = new BehaviorSubject({});
  nexttab = this._nexttab.asObservable();

  private _bookappointment = new BehaviorSubject({});
  bookappointment = this._bookappointment.asObservable();

  private _supportCompose = new BehaviorSubject({});
  supportCompose = this._supportCompose.asObservable();

  private _composeOpen = new BehaviorSubject({});
  composeOpen = this._composeOpen.asObservable();

  private _ComplaintCompose = new BehaviorSubject({});
  ComplaintCompose = this._ComplaintCompose.asObservable();

  private _addLpDetails = new BehaviorSubject({});
  addLpDetails = this._addLpDetails.asObservable();

  private _projectDetails = new BehaviorSubject({});
  projectDetails = this._projectDetails.asObservable();

  private _resourceDetails = new BehaviorSubject({});
  resourceDetails = this._resourceDetails.asObservable();

  private _tagManagerService = new BehaviorSubject({});
  tagManagerDetails = this._tagManagerService.asObservable();

  private _walletAmount = new BehaviorSubject({});
  walletAmountList = this._walletAmount.asObservable();

  private _dateService = new BehaviorSubject([]);
  getSelectedDate = this._dateService.asObservable();

  private _partnerStudentService = new BehaviorSubject([]);
  getPartnerStudentService = this._partnerStudentService.asObservable();

  private _getAllStudentService = new BehaviorSubject([]);
  getAllStudentDetails = this._getAllStudentService.asObservable();

  private _setSwmList = new BehaviorSubject({});
  setSwmListData = this._setSwmList.asObservable();

  private _getEmpSignDetails = new BehaviorSubject({});
  getEmpSignDetails = this._getEmpSignDetails.asObservable();

  private _getStudentSignDetails = new BehaviorSubject([]);
  getStudentSignDetails = this._getStudentSignDetails.asObservable();

  private _getAttachmentDetails = new BehaviorSubject([]);
  getAttachmentDetails = this._getAttachmentDetails.asObservable();

  private _getEmailAttachDetails = new BehaviorSubject([]);
  getEmailAttachDetails = this._getEmailAttachDetails.asObservable();

  private _getEmployeeDetails = new BehaviorSubject([]);
  getEmployeeDetails = this._getEmployeeDetails.asObservable();

  private _getDownloadCenterDetails = new BehaviorSubject([]);
  getDownloadCenterDetails = this._getDownloadCenterDetails.asObservable();

  private _getAgentDetails = new BehaviorSubject([]);
  getAgentDetails = this._getAgentDetails.asObservable();

  private _getTemplateDetails = new BehaviorSubject([]);
  getTemplateDetails = this._getTemplateDetails.asObservable();

  private _setLessonPlanData = new BehaviorSubject({});
  getLessonPlanData = this._setLessonPlanData.asObservable();

  /*******************************************/
  private _allChapterDetails = new BehaviorSubject([]);
  allChapterDetails = this._allChapterDetails.asObservable();
  /*******************************************/

  private _setTaskDetails = new BehaviorSubject({});
  taskDetails = this._setTaskDetails.asObservable();

  private _setLeadSourceDetails = new BehaviorSubject({});
  leadDetails = this._setLeadSourceDetails.asObservable();

  private _getApplicationList = new BehaviorSubject([]);
  applicationList = this._getApplicationList.asObservable();

  private _setHeaderFooter = new BehaviorSubject(false);
  setHeaderFooterValue = this._setHeaderFooter.asObservable();

  private _getResourceLevelData = new BehaviorSubject([]);
  getResourceLevelData = this._getResourceLevelData.asObservable();

  private _setStudentMywork = new BehaviorSubject({});
  getStudentMywork = this._setStudentMywork.asObservable();

  private _setApplicationNav = new BehaviorSubject('');
  getApplicationNav = this._setApplicationNav.asObservable();

  private _setNotification = new BehaviorSubject({});
  getNotification = this._setNotification.asObservable();

  private _setMailboxTab = new BehaviorSubject(false);
  getMailboxTab = this._setMailboxTab.asObservable();

  private _setCartData = new BehaviorSubject(false);
  getCartData = this._setCartData.asObservable();


  tenantConfig = tenantConfig;
  notes: UserService;
  displayName: any;
  templates: UserService;
  name: any;
  refreshApplication: any;

  constructor(private httpclient: HttpClient, private toastr: ToastrService) {}


  setCart(data) {
    this._setCartData.next(data);
  }

  getCart(): Observable<any> {
    return this.cartdata.asObservable();
  }

  setNotification(data: any) {
    this._setNotification.next(data);
  }

  setMailboxTab(data: any) {
    this._setMailboxTab.next(data);
  }

  setHeaderFooter(data: any) {
    this._setHeaderFooter.next(data);
  }

  setStudentMywork(data: any) {
    this._setStudentMywork.next(data);
  }

  setApplicationNav(data: any) {
    this._setApplicationNav.next(data);
  }

  setTemplateListService(data: any) {
    this._getTemplateDetails.next(data);
  }

  setResourceLevelData(data: any) {
    this._getResourceLevelData.next(data);
  }

  setTaskDetails(data: any) {
    this._setTaskDetails.next(data);
  }

  setLeadSourceDetails(data: any) {
    this._setLeadSourceDetails.next(data);
  }

  setAgentService(data: any) {
    this._getAgentDetails.next(data);
  }

  setDownloadCenterListService(data: any) {
    this._getDownloadCenterDetails.next(data);
  }

  setEmployeeListService(data: any) {
    this._getEmployeeDetails.next(data);
  }

  setEmailTempAttachService(data: any) {
    this._getEmailAttachDetails.next(data);
  }

  setAttachmentService(data: any) {
    this._getAttachmentDetails.next(data);
  }

  setLessonPlanData(data: any) {
    this._setLessonPlanData.next(data);
  }

  setStudentSignService(data: any) {
    this._getStudentSignDetails.next(data);
  }

  setEmployeeSignService(data: any) {
    this._getEmpSignDetails.next(data);
  }

  allStudentService(data: any) {
    this._getAllStudentService.next(data);
  }

  setSwmList(data: any) {
    this._setSwmList.next(data);
  }

  getApplicationList(value) {
    this._getApplicationList.next(value);
  }

  getComplaintCompose() {
    return this._ComplaintCompose.asObservable();
  }

  setComplaintCompose(value) {
    this._ComplaintCompose.next(value);
  }

  setDetails(value) {
    this._composeOpen.next(value);
  }
  setCompetences(value) {
    this._getCompetences.next(value);
  }
  setChapterDetails(value, bool) {
    this.chapterDetails.filter((data, index) => {
      if (data?._id === value?._id) {
        this.chapterDetails[index] = value;
      }
    });
    if (bool) {
      this.chapterDetails.push(value);
    }
    this._setChapterData.next(value);
  }
  setAllChapterDetails(value) {
    this.chapterDetails = value;
    this._allChapterDetails.next(value);
  }
  getCourseTerm(value) {
    this._getCourseTermData.next(value);
  }

  setApplicationData(value) {
    this._setApplicationData.next(value);
  }

  setSelectedDetails(value) {
    this._setSelectedData.next(value);
  }

  getCourseMaterial(value, bool) {
    if (value?.length > 0) {
      this.allChapterData = value;
    }
    if (bool) {
      this.allChapterData.push(value);
    }
    if (!bool && this.allChapterData?.length > 0) {
      this.allChapterData.map((data, i) => {
        if (data?._id === value?._id) {
          this.allChapterData[i] = value;
        }
      });
    }
    if (this.allChapterData?.length > 0) {
      this._setCourseMaterialDetails.next(this.allChapterData);
    }
    if (value?.length === 0) {
      this._setCourseMaterialDetails.next(value);
    }
  }

  setDetailsIs(value) {
    this._composeOpen.next(value);
  }

  getDetails() {
    return this._composeOpen.asObservable();
  }

  setSupportCompose(value) {
    this._supportCompose.next(value);
  }

  getSupportCompose() {
    return this._supportCompose.asObservable();
  }

  setbookappointment(data) {
    this._bookappointment.next(data);
  }

  getbookappointment() {
    return this._bookappointment.asObservable();
  }

  setTab(data: any) {
    this._nexttab.next(data);
  }

  getTab() {
    return this._nexttab.asObservable();
  }

  seteditprofile(data) {
    this._editprofile.next(data);
  }

  geteditprofile() {
    return this._editprofile.asObservable();
  }

  setNotifyCount(value) {
    this._NotifyCount.next(value);
  }

  getNotifyCount() {
    return this._NotifyCount.asObservable();
  }

  setTicketCount(value) {
    this._TickentCount.next(value);
  }

  getTicketCount() {
    return this._TickentCount.asObservable();
  }

  setShareTenantSettings(arrOfObjData: any) {
    this._TenantSettingsData.next(arrOfObjData);
  }

  setSidemenuTab(data) {
    this._sidemenutab.next(data);
  }

  getSidemenuTab() {
    return this._sidemenutab.asObservable();
  }

  setProfile(value) {
    this._profileSub.next(value);
  }

  getProfile() {
    return this._profileSub.asObservable();
  }

  setIsLogin(value) {
    this._islogin.next(value);
  }

  setTaskUpdate(data) {
    this._taskUpdate.next(data);
  }

  setFileImage(data: any, value: boolean) {
    this._setSettingsFileImage.next({ data, value });
  }

  setGlobalSearchEmpty(data) {
    this._globalSearch.next(data);
  }

  setUserPermission(data: any) {
    this._userPermission.next(data);
  }

  setNewStudent(data: any) {
    this._newlyAddedStudent.next(data);
  }

  setUserDetails(data: any) {
    this._userDetails.next(data);
  }

  setPartnerDetails(data: any) {
    this._partnerUserDetails.next(data);
  }

  setLoggedUserDetails(data: any) {
    this._loggedUserDetails.next(data);
  }

  addLpService(data: any) {
    this._addLpDetails.next(data);
  }

  projectService(data: any) {
    this._projectDetails.next(data);
  }

  resourceService(data: any) {
    this._resourceDetails.next(data);
  }

  tagManagerService(data: any) {
    this._tagManagerService.next(data);
  }

  walletAmount(data: any) {
    this._walletAmount.next(data);
  }

  dateService(data: any) {
    this._dateService.next(data);
  }

  partnerStudentService(data: any) {
    this._partnerStudentService.next(data);
  }

  showSuccess(message: any) {
    this.toastr.success(message);
  }

  showWarning(message: any) {
    this.toastr.warning(message);
  }

  showError(message: any) {
    this.toastr.error(message);
  }

  getCall(url: any, head = false) {
    if (head){
      const headers = {
        'Content-Type': 'application/json',
        tenantConfig: JSON.stringify(tenantConfig),
      };
      return this.httpclient.get(url, { headers });
    }else{
      return this.httpclient.get(url);
    }


  }

  postCall(url: any, payload: any) {
    return this.httpclient.post(url, payload);
  }

  putCall(url: any, payload: any) {
    return this.httpclient.put(url, payload);
  }

  putCallwithHeaders(url: any, payload: any) {
    this.gaippToken = localStorage.getItem('token');
    const headers = {
      Authorization: 'Bearer ' + this.gaippToken,
      'Content-Type': 'application/json',
      tenantConfig: JSON.stringify(tenantConfig),
    };
    return this.httpclient.put(url, payload, { headers });
  }

  getCallWithHeaders(url: any) {
    this.gaippToken = localStorage.getItem('token');
    const headers = {
      Authorization: 'Bearer ' + this.gaippToken,
      'Content-Type': 'application/json',
      tenantConfig: JSON.stringify({ tenantId: TenantId }),
    };
    return this.httpclient.get(url, { headers });
  }

  postCallwithHeaders(url: any, payload: any): any {
    this.gaippToken = localStorage.getItem('token');
    const headers = {
      Authorization: 'Bearer ' + this.gaippToken,
      'Content-Type': 'application/json',
      tenantConfig: JSON.stringify({ tenantId: TenantId }),
    };

    return this.httpclient.post(url, payload, { headers });
  }

  deleteCallwithHeaders(url: any): any {
    this.gaippToken = localStorage.getItem('token');
    const headers = {
      Authorization: 'Bearer ' + this.gaippToken,
      'Content-Type': 'application/json',
      tenantConfig: JSON.stringify({ tenantId: TenantId }),
    };
    return this.httpclient.delete(url, { headers });
  }

  postCallImg(url, payload) {
    this.gaippToken = localStorage.getItem('token');

    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.gaippToken,
      Accept: 'application/json',
      tenantConfig: JSON.stringify(this.tenantConfig),
    });

    return this.httpclient.post(url, payload, { headers });
  }

  download(url: string): Observable<Blob> {
    return this.httpclient.get(url, {
      responseType: 'blob',
    });
  }

  getCallBlob(url: any) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.httpclient.get(url, httpOptions);
  }

  getaddresscall(url, registertoken) {
    if (registertoken === '') {
      this.gaippToken = localStorage.getItem('token');
      const tokenis = 'Bearer ' + this.gaippToken;
      const headers = {
        Authorization: tokenis,
        'Content-Type': 'application/json',
        tenantConfig:
          '{ "tenantId": "' +
          TenantId +
          '","url": "http://54.147.203.20:3001/#/","company": "ELearning tool","logo": "http://139.59.34.68:2000/modules/core/client/img/ictc3.png"}',
      };
      return this.httpclient.get(url, { headers });
    } else {
      const tokenis = 'Bearer ' + registertoken;
      const headers = {
        Authorization: tokenis,
        'Content-Type': 'application/json',
        tenantConfig:
          '{ "tenantId": "' +
          TenantId +
          '","url": "http://54.147.203.20:3001/#/","company": "ELearning tool","logo": "http://139.59.34.68:2000/modules/core/client/img/ictc3.png"}',
      };
      return this.httpclient.get(url, { headers });
    }
  }
}
