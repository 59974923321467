import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CourseService } from 'src/app/services/course.service';
import { UserService } from 'src/app/services/userservice.service';
import { APP_URLS, tenantConfig, TenantId } from 'src/app/Utils/general';
import { APP_VLE_URLS } from 'src/app/Utils/vle';
import * as ResourceJson from '../../Utils/crm-resource.json';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.css']
})
export class SetPasswordComponent implements OnInit {

  selectedId = '';
  pwdKey: any;
  studentId: any;
  reset = {
    newpwd: '',
    verifypwd: ''
  };
  resourceData: any = (ResourceJson as any).default; // used for read resource file
  isLoading = false;
  submitted = false;
  setPasswordForm: FormGroup;
  eventChange = 'password';
  eventConfirmChange = 'password';
  eyeIcon = 'fas fa-eye';
  eyeIcon1 = 'fas fa-eye';
  get plf() {
    return this.setPasswordForm.controls;
  }
  constructor(private router: Router,
              private route: ActivatedRoute,
              public service: CourseService,
              private userSvc: UserService,
              private toastr: ToastrService,
              private fb: FormBuilder ) { }

  ngOnInit() {
    this.setPasswordForm = this.fb.group({
      password: this.fb.control('', [Validators.required]),
      confirmPassword: this.fb.control('', [Validators.required])
    });

    this.route.paramMap.subscribe(
      params => {
        this.selectedId = params.get('id');

      }
    );
    this.route.queryParams
      .subscribe(params => {
        const paramsKeyArr = Object.keys(params);
        if (paramsKeyArr?.length > 0) {
          let k = paramsKeyArr[0];
          k = k.split('=')[1];
          this.pwdKey = k;
        }
      }
      );
  }
  password_show_hide() {
    if (this.eventChange === 'password') {
      this.eventChange = 'text';
      this.eyeIcon = 'fas fa-eye-slash';
    } else {
      this.eventChange = 'password';
      this.eyeIcon = 'fas fa-eye';
    }
  }

  confirm_password_show_hide() {
    if (this.eventConfirmChange === 'password') {
      this.eventConfirmChange = 'text';
      this.eyeIcon1 = 'fas fa-eye-slash';
    } else {
      this.eventConfirmChange = 'password';
      this.eyeIcon1 = 'fas fa-eye';
    }
  }

  setPassword() {
    this.submitted = true;
    if (this.setPasswordForm.invalid) {
      return;
    }
    if (this.setPasswordForm.get('password').value !== this.setPasswordForm.get('confirmPassword').value){
      this.userSvc.showError('Password and Confirm Password Should Match');
      return;
    }
    else{

    const url = APP_VLE_URLS.resetPassword + this.selectedId;
    const payload = {
      forgotpw_key: this.pwdKey ,
      id: this.selectedId ,
      newPassword: this.setPasswordForm.get('password').value,
      verifyPassword: this.setPasswordForm.get('confirmPassword').value,
      tenantId: `"` + TenantId + `"`,
    };
    console.log(payload);
    this.userSvc.putCall(url, payload)
    .subscribe((res: any) => {

       this.submitted = false;
       this.userSvc.showSuccess('Now you can sign in with your new password');
       this.router.navigate(['/home']);
    }, err => {

       this.userSvc.showError(err.message);
    });


    }

  }

}
