<main class="inner-page">

    <!-- ======= Breadcrumbs Section ======= -->
    <section class="breadcrumb">
        <div class="container-fluid">
            <div class="d-flex justify-content-between align-items-center text-xs-center">
                <ul>
                  <ng-container *ngIf="token !== '' && token !== null">
                    <li><a href="javascript:void(0)"  [routerLink]="['/vle/home']">Home</a></li>
                  </ng-container>
                    <ng-container *ngIf="token === '' || token === null" >
                      <li><a href="javascript:void(0)" routerLink="/">Home</a></li>
                    </ng-container>
                    <li>Our Approach</li>
                </ul>
            </div>
        </div>
    </section>
    <!-- End Breadcrumbs Section -->

    <!-- ======= Content Section ======= -->
    <section id="main">
        <div class="container-fluid">
            <div class="main-content">
                <div class="d-flex justify-content-between align-items-center">
                    <h1 class="page-header-title-1">Our Approach</h1>
                </div>
                <hr class="hr" />
                <div class="myprofile">
                    <div class="row  mb-3">
                        <div class="col-lg-4 mb-3">
                            <img src="./assets/img/ourapproach/engage.jpg" alt="" class="img-fluid">
                        </div>
                        <div class="col-lg-8 mb-3">
                            <h3 class="mb-3 text-danger"> Engage</h3>
                            <h4 class="mb-3">Bespoke training to beginners and professionals</h4>
                            <p>Each course engages trainees and captures their interest, provides an opportunity for them to express what they know about the concept or skill being developed, and helps them to make connections between what they know and
                                the new ideas within the syllabus. Our trainers take extra care in understanding the technical background of our trainees and offer bespoke support. We completely understand that there are different ways to become a Software
                                Tester and the route the candidates take will depends on their qualifications and particularly on their experience levels too.</p>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-4 mb-3">
                            <img src="./assets/img/ourapproach/educate.jpg" alt="" class="img-fluid">
                        </div>
                        <div class="col-lg-8 mb-3">
                            <h3 class="mb-3 text-danger">Educate</h3>
                            <h4 class="mb-3">Internships</h4>
                            <p class="mb-3">
                                Educate Internships Our internships are a new approach to helping beginners gain exposure to projects implementation phase. We give our best to fulfil the desires and aspirations of our trainees and will guide them according to their skill set. Our expert
                                advisers will provide a detailed analysis of the set of training that is more relevant to their career goals and in turn, ensures maximum returns in the long-term for the trainee in their career. This is possible by understanding
                                the skill-set of each individual ready to take professional training in respective fields and blending it with real time projects that complement their modules and exposure to live projects with existing clients.</p>
                            <ul class="mb-3">
                                <li>Extremely realistic and useful approach to practice in classrooms and online
                                </li>
                                <li>Advanced training methodologies used</li>
                                <li>Excellent course Delivery</li>
                                <li>Personal mentoring</li>
                                <li>Learning while doing</li>
                                <li>Demonstrating real-time knowledge and experience</li>
                            </ul>
                        </div>

                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-4 mb-3">
                            <img src="./assets/img/ourapproach/empower.jpg" alt="" class="img-fluid">
                        </div>
                        <div class="col-lg-8 mb-3">
                            <h3 class="mb-3 text-danger">Empower</h3>
                            <h4 class="mb-3">Placement Assistance</h4>

                            <p>The final phase provides an opportunity for trainees to review and reflect on their own learning, new understanding and skills gained. We will help by providing assistance with CV, Interview practice, hints and tips for sending
                                the application to prospective employers. The practical and extremely useful approach that we are taking are precisely what our trainees need to get certified and gain better prospects in the respective sector.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Content Section -->

</main>
<!-- End #main -->