import { Component, OnInit } from '@angular/core';
import { APP_URLS, tenantConfig, TenantId } from 'src/app/Utils/general';

@Component({
  selector: 'app-governance',
  templateUrl: './governance.component.html',
  styleUrls: ['./governance.component.css']
})
export class GovernanceComponent implements OnInit {
  tenantInfo: any = tenantConfig;

  token = '';
  constructor() {
    this.token = localStorage.getItem('token');
  }

  ngOnInit(): void {}
}
