import { UserService } from 'src/app/services/userservice.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { APP_URLS, TenantId } from 'src/app/Utils/general';
import * as ResourceJson from '../../Utils/crm-resource.json';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {
  registerForm = new FormGroup({});
  submitted = false;
  resourceData: any = (ResourceJson as any).default;
  studentRole: any;
  constructor(private user: UserService) {
    this.studentRole = JSON.parse(localStorage.getItem('tenantDetails'))?.student_role;
  }

  ngOnInit(): void {
    this.registerForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/),
      ]),
      title: new FormControl('', [Validators.required]),
      fname: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z ]*$/),
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
        Validators.maxLength(40),
      ]),
      mname: new FormControl('',[
        Validators.pattern(/^[a-zA-Z ]*$/),
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
        Validators.maxLength(40),]
      ),
      lname: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z ]*$/),
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
        Validators.maxLength(40),
      ]),

      phone: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[0-9]*$/),
        Validators.maxLength(16),
      ]),
      userRole: new FormControl('', [Validators.required])
    });
  }

  /**
   * get form controls
   */
  get f() {
    return this.registerForm.controls;
  }

  Register() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    const url = APP_URLS.student;

    const payload = {
      email: this.registerForm.value.email,
      first_name: this.registerForm.value.fname,
      middle_name: this.registerForm.value.mname,
      last_name: this.registerForm.value.lname,
      mobile: this.registerForm.value.phone,
      tenantId: TenantId,
      title: this.registerForm.value.title,
      user_role: this.registerForm.value.userRole,
      from_place: 'signup',
    };
    this.user.postCall(url, payload).subscribe(
      (res: any) => {
        this.submitted = false;
        if (res.respCode === 201) {
          this.user.showSuccess(res.respMessage);
          this.registerForm.reset();
          this.registerForm.patchValue({
            userRole: '',
            title: ''
          });
        } else {
          this.user.showError(res.respMessage);
        }
      },
      (error) => {
        this.user.showError('Register Failed');
      }
    );
  }
}
