import { Component, OnInit } from '@angular/core';
import { tenantConfig } from 'src/app/Utils/general';

@Component({
  selector: 'app-whyus-info',
  templateUrl: './whyus-info.component.html',
  styleUrls: ['./whyus-info.component.css']
})
export class WhyusInfoComponent implements OnInit {

  tenantInfo = tenantConfig
  constructor() { }

  ngOnInit(): void {
  }

}
