import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
// import { IPayPalConfig, ICreateOrderRequest, IPurchaseUnit } from 'ngx-paypal';
import { Router } from '@angular/router';
import { CourseService } from 'src/app/services/course.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as ResourceJson from '../../Utils/crm-resource.json';
import { UserService } from 'src/app/services/userservice.service';
import { APP_URLS, TenantId } from 'src/app/Utils/general';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css'],
})
export class CheckoutComponent implements OnInit, AfterViewInit {
  isLoading = false;
  submitted = false;
  // public payPalConfig?: IPayPalConfig;
  showSuccess;
  listCount = 0;
  resourceData: any = (ResourceJson as any).default;
  CartDetails = [];
  tenantId = TenantId;
  TotalCartFee = 0;
  mailId = '';
  addressForm: FormGroup;
  getAddressData = [];
  paynow = true;
  subscription = false;
  emioption = false;
  default = '';
  paymentID = '';
  paymentMonth = '';
  // payment_type = 'instantpay';
  CartList = [];
  studentId = '';
  registertoken = '';
  installamount = '';
  emailId = '';
  input: any;
  countryObj = {
    dialCode: '',
  };
  iti: any;
  countryCode = '';
  isUserActivated = false;
  showLoginDialog = false;
  NationalityList = [];
  CountryList = [];
  EthnicOriginList = [];
  DropdownsData = [];
  currencyCode = 'USD'; // GBP

  get af() {
    return this.addressForm.controls;
  }

  constructor(
    private userSvc: UserService,
    private router: Router,
    public courseservice: CourseService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.addressForm = this.formBuilder.group({
      actiontype: this.formBuilder.control('add', [Validators.required]),
      tenantId: this.formBuilder.control(TenantId, Validators.required),
      fname: this.formBuilder.control('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z ]*$/),
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
        Validators.maxLength(40),
      ]),
      lname: this.formBuilder.control('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z ]*$/),
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
        Validators.maxLength(40),
      ]),
      email: this.formBuilder.control('', [
        Validators.required,
        Validators.email,
        Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/),
      ]),
      mobile: this.formBuilder.control('', [
        Validators.required,
        Validators.pattern(/^[0-9-+]*$/),
        Validators.minLength(5),
        Validators.maxLength(16),
      ]),
      address: this.formBuilder.control('', [
        Validators.pattern(/^[a-zA-Z ]*$/),
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
        Validators.required,
      ]),
      city: this.formBuilder.control('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z ]*$/),
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
      ]),
      locality: this.formBuilder.control('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z ]*$/),
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
      ]),
      pincode: this.formBuilder.control('', [
        Validators.required,
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
        Validators.minLength(3),
        Validators.maxLength(15),
      ]),
      nationality: this.formBuilder.control('', Validators.required),
      _id: this.formBuilder.control(''),
      alterphone: this.formBuilder.control(''),
    });
    const data = JSON.parse(localStorage.getItem('userDetails'));
    if (data) {
      this.studentId = data._id;
      this.mailId = data.email;
    }
    const dataofcart = localStorage.getItem('cartdata');
    if (dataofcart != null) {
      this.CartList = dataofcart.split(',');

      if (this.studentId && localStorage.getItem('token')) {
        // this.getaddmultiCart();
      }
    }
    this.getDropdowns();
    this.getCart();
    this.getAddress();
  }

  ngAfterViewInit(): void {
    const userDetails = localStorage.getItem('profilesummaryData');
  }

  @HostListener('document:countrychange', ['$event'])
  getCountry(e) {
    this.countryObj = this.iti.getSelectedCountryData();
  }

  keyPressAlphaNumeric(event) {
    const inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  openAddress(data) {
    if (data) {
      this.addressForm = this.formBuilder.group({
        actiontype: this.formBuilder.control('edit', Validators.required),
        tenantId: this.formBuilder.control(TenantId, Validators.required),
        fname: this.formBuilder.control(data.fname, Validators.required),
        lname: this.formBuilder.control(data.lname || ''),
        email: this.formBuilder.control(data.email || ''),
        mobile: this.formBuilder.control(
          data.mobile || '',
          Validators.required
        ),
        address: this.formBuilder.control(data.address || ''),
        city: this.formBuilder.control(data.city || '', Validators.required),
        locality: this.formBuilder.control(
          data.locality || '',
          Validators.required
        ),
        pincode: this.formBuilder.control(
          data.pincode || '',
          Validators.required
        ),
        nationality: this.formBuilder.control(
          data.nationality || '',
          Validators.required
        ),
        _id: this.formBuilder.control(data._id || ''),
        alterphone: this.formBuilder.control(data.alterphone || ''),
      });
    } else {
      this.submitted = false;
      this.addressForm = this.formBuilder.group({
        actiontype: this.formBuilder.control('add', [Validators.required]),
        tenantId: this.formBuilder.control(TenantId, Validators.required),
        fname: this.formBuilder.control('', [
          Validators.required,
          Validators.pattern(/^[a-zA-Z ]*$/),
          Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
          Validators.maxLength(40),
        ]),
        lname: this.formBuilder.control('', [
          Validators.required,
          Validators.pattern(/^[a-zA-Z ]*$/),
          Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
          Validators.maxLength(40),
        ]),
        email: this.formBuilder.control('', [
          Validators.required,
          Validators.email,
          Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/),
        ]),
        mobile: this.formBuilder.control('', [
          Validators.required,
          Validators.pattern(/^[0-9-+]*$/),
          Validators.minLength(5),
          Validators.maxLength(16),
        ]),
        address: this.formBuilder.control('', [
          Validators.pattern(/^[a-zA-Z ]*$/),
          Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
          Validators.required,
        ]),
        city: this.formBuilder.control('', [
          Validators.required,
          Validators.pattern(/^[a-zA-Z ]*$/),
          Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
        ]),
        locality: this.formBuilder.control('', [
          Validators.required,
          Validators.pattern(/^[a-zA-Z ]*$/),
          Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
        ]),
        pincode: this.formBuilder.control('', [
          Validators.required,
          Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
          Validators.minLength(3),
          Validators.maxLength(15),
        ]),
        nationality: this.formBuilder.control('', Validators.required),
        _id: this.formBuilder.control(''),
        alterphone: this.formBuilder.control(''),
      });
    }
  }

  resetAddressForm() {
    this.addressForm.reset({
      actiontype: 'add',
      tenantId: TenantId,
    });
  }

  closeAddress() {
    this.submitted = false;
    this.resetAddressForm();
  }

  getDropdowns() {
    const url = APP_URLS.groupedList;
    this.userSvc.getCall(url).subscribe((res: any) => {
      this.DropdownsData = res.data;
      res.data.forEach((x) => {
        if (x.type === 'Nationality') {
          this.NationalityList.push(x);
        }
        if (x.type === 'Country') {
          this.CountryList.push(x);
        }
        if (x.type === 'EthnicOrigin') {
          this.EthnicOriginList.push(x);
        }
      });
    });
  }

  async getCart() {
    if (this.studentId) {
      const url = APP_URLS.getcart;
      const payload = {};
      await this.userSvc
        .postCallwithHeaders(url, payload)
        .subscribe((res: any) => {
          this.listCount = res.listCount;
          this.CartDetails = res.list;
          let total = 0;
          let totalDiscount = 0;
          res.list.forEach((x) => {
            totalDiscount = totalDiscount + x.course_discount;
            total = total + x.course.course_fee_final;
          });
          this.TotalCartFee = total;
          this.initConfig(this.TotalCartFee);
        });
    } else {
      const url = APP_URLS.getcart;
      const payload = {
        courseids: this.CartList,
        tenantId: TenantId,
      };
      await this.userSvc.postCall(url, payload).subscribe((res: any) => {
        this.listCount = res.listCount;
        this.CartDetails = res.list;
        let total = 0;
        res.list.forEach((x) => {
          total = total + x.course.course_fee_final;
        });
        this.TotalCartFee = total;
        this.initConfig(this.TotalCartFee);
      });
    }
  }

  initConfig(price) {
    const amt = price;
    const items = [];
    this.CartDetails.forEach((x) => {
      // let items = [];
      items.push({
        name: x.course.name,
        quantity: '1',
        category: 'COURSE',
        unit_amount: {
          currency_code: this.currencyCode,
          value: x.course.course_fee_final.toString(),
        },
      });
    });
    // this.payPalConfig = {
    //   currency: this.currencyCode,
    //   clientId:
    //     'AQ9gb8EQlExCHEFxvWmvucXJ0wN09GnbySe8LCRRuuRA-ubIaVAOVvKEDzRJfgArcOW-LllD6u6d7cXb',
    //   createOrderOnClient: (data) =>
    //     <ICreateOrderRequest>{
    //       intent: 'CAPTURE',
    //       purchase_units: [
    //         {
    //           amount: {
    //             currency_code: this.currencyCode,
    //             value: amt.toString(),
    //             breakdown: {
    //               item_total: {
    //                 currency_code: this.currencyCode,
    //                 value: amt.toString(),
    //               },
    //             },
    //           },
    //           items,
    //         },
    //       ],
    //     },
    //   advanced: {
    //   },
    //   style: {
    //     label: 'paypal',
    //     layout: 'vertical',
    //   },
    //   onApprove: (data, actions) => {
    //     console.log(
    //       'onApprove - transaction was approved, but not authorized-data',
    //       JSON.stringify(data)
    //     );
    //     console.log(
    //       'onApprove - transaction was approved, but not authorized-actions',
    //       JSON.stringify(actions)
    //     );
    //     actions.order.get().then((details) => {
    //       console.log(
    //         'onApprove - you can get full order details inside onApprove: ',
    //         JSON.stringify(details)
    //       );
    //     });
    //   },
    //   onClientAuthorization: (data) => {
    //     console.log(
    //       'onClientAuthorization - you should probably inform your server about completed transaction at this point',
    //       JSON.stringify(data)
    //     );
    //     this.showSuccess = true;
    //   },
    //   onCancel: (data, actions) => {
    //     console.log('OnCancel', data, actions);
    //   },
    //   onError: (err) => {
    //     console.log('OnError', err);
    //   },
    //   onClick: () => {
    //     console.log('onClick');
    //   },
    // };
  }

  getAddress() {
    const url = APP_URLS.getManageAddress;
    this.userSvc
      .getaddresscall(url, this.registertoken)
      .subscribe((res: any) => {
        this.emailId = res?.profile_details?.email;
        this.getAddressData = res?.profile_details?.manage_address || [];
        this.isLoading = false;

        if (
          this.getAddressData !== undefined &&
          typeof this.getAddressData !== 'string'
        ) {
          if (this.getAddressData.length) {
            this.default = this.getAddressData[0]?._id;
          } else {
            localStorage.removeItem('token');
          }
        } else {
          localStorage.removeItem('token');
        }
      });
  }

  bankTrasfer() {}

  addAddress() {
    this.submitted = true;
    console.log(this.addressForm);
    if (this.addressForm.invalid) {
      return;
    }
    const url = APP_URLS.updateManaeAddress;
    this.emailId = this.addressForm.get('email').value;
    if (this.studentId) {
      console.log(this.addressForm.value);
      this.userSvc
        .putCallwithHeaders(url, this.addressForm.value)
        .subscribe((data: any) => {
          if (data.respCode) {
            this.userSvc.showSuccess(data.respMessage);
            document.getElementById('address-modal-close-btn').click();
          } else {
            this.userSvc.showError(data.errorMessage);
          }
        });
    } else {
      console.log(this.addressForm.value);
      this.userSvc
        .putCallwithHeaders(url, this.addressForm.value)
        .subscribe((data: any) => {
          if (data.access_token) {
            this.registertoken = data?.access_token;
            localStorage.setItem('token', data?.access_token);
          }
          if (data.respCode) {
            this.resetAddressForm();
            document.getElementById('address-modal-close-btn').click();
            this.getAddress();
            if (this.getAddressData?.length === 0) {
              this.getProfileDetails('updateAddress');
            } else {
              this.userSvc.showSuccess(data.respMessage);
            }
          } else {
            this.userSvc.showError(data.errorMessage);
          }
        });
    }
    this.submitted = false;
  }

  payNow() {
    // this.paynow = true;
    // this.subscription = false;
    // this.emioption = false;
    // // this.payment_type = 'instantpay';
    // this.paymentMonth = '';
    // // this.payPalConfig = undefined;
    // setTimeout(() => {
    //   this.initConfig(this.TotalCartFee);
    // }, 1000);
  }

  Subscription() {
    // this.paynow = false;
    // this.subscription = true;
    // this.emioption = false;
    // // this.payment_type = 'Subscription';
    // this.selectSubscription('Month');
  }
  Emi() {
    // this.paynow = false;
    // this.subscription = false;
    // this.emioption = true;
    // // this.payment_type = 'Installment';
    // this.selectEMI('3');
  }

  // selectEMI(val) {
  //   this.paymentMonth = val;
  //   let totalinstall = 0;
  //   this.paymentMonth = val;
  //   if (val === '3') {
  //     let total = 0;
  //     this.CartDetails.forEach((x) => {
  //       // tslint:disable-next-line:radix
  //       total = total + parseInt(x.course.installment.month[3]);
  //     });
  //     totalinstall = total;
  //   }
  //   if (val === '6') {
  //     let total = 0;
  //     this.CartDetails.forEach((x) => {
  //       // tslint:disable-next-line:radix
  //       total = total + parseInt(x.course.installment.month[6]);
  //     });
  //     totalinstall = total;
  //   }
  //   if (val === '9') {
  //     let total = 0;
  //     this.CartDetails.forEach((x) => {
  //       // tslint:disable-next-line:radix
  //       total = total + parseInt(x.course.installment.month[9]);
  //     });
  //     totalinstall = total;
  //   }
  //   this.installamount = 'Amount: $ ' + totalinstall;
  //   // this.payPalConfig = undefined;
  //   setTimeout(() => {
  //     this.initConfig(totalinstall);
  //   }, 1000);
  // }

  // selectSubscription(data) {
  //   let totalinstall = 0;
  //   this.paymentMonth = data;
  //   if (data === 'Month') {
  //     let total = 0;
  //     this.CartDetails.forEach((x) => {
  //       // tslint:disable-next-line:radix
  //       total = total + parseInt(x.course.subcription.month);
  //     });
  //     totalinstall = total;
  //   }
  //   if (data === 'Year') {
  //     let total = 0;
  //     this.CartDetails.forEach((x) => {
  //       // tslint:disable-next-line:radix
  //       total = total + parseInt(x.course.subcription.year);
  //     });
  //     totalinstall = total;
  //   }
  //   this.installamount = 'Amount: $ ' + totalinstall;
  //   // this.payPalConfig = undefined;
  //   setTimeout(() => {
  //     this.initConfig(totalinstall);
  //   }, 1000);
  // }

  continue() {
    if (this.isUserActivated) {
      if (this.getAddressData?.length === 0) {
        this.userSvc.showError('Add Billing Address details');
      } else {
        this.getProfileDetails('validateUser');
      }
    } else {
      if (this.studentId) {
        document.getElementById('collapseTwo').classList.remove('show');
        document.getElementById('collapseThree').classList.add('show');
      } else {
        this.getProfileDetails('validateUser');
      }
    }
  }

  selectCourse(data) {
    this.courseservice.selectCourseById(data.course);
    this.courseservice.senddataToHeader(data.course);
    this.router.navigate(['/' + data.course.slug]);
  }

  selectedadd(data) {
    this.default = data._id;
    if (data) {
      this.addressForm.patchValue({
        actiontype: this.formBuilder.control('edit', Validators.required),
        tenantId: this.formBuilder.control(TenantId, Validators.required),
        fname: this.formBuilder.control(data.fname, Validators.required),
        lname: this.formBuilder.control(data.lname, Validators.required),
        email: this.formBuilder.control(data.email, Validators.required),
        mobile: this.formBuilder.control(data.mobile, Validators.required),
        address: this.formBuilder.control(data.address),
        city: this.formBuilder.control(data.city, Validators.required),
        locality: this.formBuilder.control(data.locality, Validators.required),
        pincode: this.formBuilder.control(data.pincode, Validators.required),
        nationality: this.formBuilder.control(
          data.nationality,
          Validators.required
        ),
        _id: this.formBuilder.control(data._id),
        _alterphoneid: this.formBuilder.control(data.alterphone),
      });
    }
  }

  // getAddress() {
  //   const url = APP_URLS.getManageAddress;
  //   this.userSvc
  //     .getaddresscall(url, this.registertoken)
  //     .subscribe((res: any) => {
  //       this.emailId = res?.profile_details?.email;
  //       this.getAddressData = res?.profile_details?.manage_address || [];
  //       this.isLoading = false;

  //       if (
  //         this.getAddressData !== undefined &&
  //         typeof this.getAddressData !== 'string'
  //       ) {
  //         if (this.getAddressData.length) {
  //           this.default = this.getAddressData[0]?._id;
  //         } else {
  //           localStorage.removeItem('token');
  //         }
  //       } else {
  //         localStorage.removeItem('token');
  //       }
  //     });
  // }

  getProfileDetails(type: string) {
    const url = APP_URLS.v1.userDetails + '?apifrom=profile_summary';
    this.userSvc.getCallWithHeaders(url).subscribe(
      (res: any) => {
        if (res?.userDetails?.activate === true) {
          this.isUserActivated = true;
          this.showLoginDialog = true;
        } else {
          this.showLoginDialog = false;
          if (type === 'updateAddress') {
            this.userSvc.showSuccess(
              'Your billing address is added successfully. Welcome email with activation link has been sent to your email id, please login to your email and activate your account then make the payment'
            );
          } else if (type === 'validateUser') {
            this.userSvc.showSuccess(
              'Welcome email with activation link has been sent to your email id, please login to your email and activate your account then make the payment'
            );
          }
        }
      },
      (err) => {}
    );
  }

  deleteDetail(data) {
    this.isLoading = true;
    const url = APP_URLS.updateManaeAddress;
    const payload = {
      actiontype: 'delete',
      _id: data._id,
    };
    this.userSvc.putCallwithHeaders(url, payload).subscribe((res: any) => {
      if (res.respCode === 202 || res.respCode === 200) {
        this.getAddressData = [];
        this.userSvc.showSuccess(res.respMessage);
        this.getAddress();
      } else {
        this.userSvc.showError(res.respMessage);
        this.isLoading = false;
      }
    });
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  submit() {}
}
