<main class="inner-page">
    <!-- courses area start -->
    <div id="courses" class="wrap-bg wrap-bg-dark bg-bottom-zero">
        <div class="container">
            <!-- .row -->
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 course-single mb20">
                    <!-- 1 -->
                    <div class="">
                        <div class="row">
                            <div class="col-md-9 col-sm-12">
                                <div class="card nextstepglobal_course">
                                    <div class="card-header cardheader">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <!-- <a class="btn_left" href="javascript:void(0)" *ngIf="accessToken" routerLink="/mytraining">Continue Shopping</a> -->
                                                <!-- <a class="btn_left" href="javascript:void(0)" *ngIf="!accessToken" routerLink="/viewallcourses">Continue Shopping</a> -->
                                            </div>
                                            <div class="col-md-4 text-white">
                                                You have {{listCount}} item in your cart
                                            </div>
                                            <div class="col-md-4 text-right">
                                                <!-- <a class="empty-cart" href="javascript:void(0)">Continue Shopping</a> -->
                                                <a class="btn_left" href="javascript:void(0)" [routerLink]="['/Courses/Online']">Continue Shopping</a>
                                                <!-- <a class="btn_left" href="javascript:void(0)" *ngIf="!accessToken" [routerLink]="['/Courses/Online']">Continue Shopping</a> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="CartDetails.length !== 0 && !isLoading">
                                        <div class="card-body" *ngFor="let item of CartDetails; let i = index ">
                                            <div class="row">
                                                <div class="col-md-2">
                                                    <!-- <img src="../../../assets/images/cartimg.jpg" /> -->
                                                    <img class="img-fluid" *ngIf="item.course.document_name == ''" src="../../../assets/images/cartimg.jpg" />
                                                    <img class="img-fluid" *ngIf="item.course.document_name !== ''" src="{{'https://daipp-data-buckets.eu-central-1.linodeobjects.com/'+ tenantId  +'/course/'+ item.course.document_name }}" />
                                                </div>
                                                <div class="col-md-8">
                                                    <h4 style="color: #1086b1; text-decoration: underline;"><a href="javascript:void(0)" (click)="selectCourse(item)"><strong>{{item.course.name}}</strong></a></h4>
                                                    <p>
                                                        {{item.course.slug}}
                                                    </p>
                                                </div>
                                                <div class="col-md-2 text-right">
                                                    <div style="cursor: pointer; font-weight: bold;" (click)="removeCart(item)">X</div>
                                                    <br/>
                                                    <span style="font-size: 18px;">US $<strong>{{item.course.course_fee_final}}</strong></span>
                                                </div>

                                            </div>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="card-body text-center" *ngIf="CartDetails.length==0 && !isLoading">
                                        <span style="color: #C7171E ;">Cart is empty. Select the course you want to apply...!</span>
                                    </div>
                                    <div class="text-center mt-3" *ngIf="isLoading">
                                        <p>Loading...</p>
                                    </div>
                                    <!-- <div class="card-footer">
                                        gfdsg

                                    </div> -->
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-12 text-center">
                                <div class="box_checkout">
                                    <h3>
                                        Your Cart Total
                                    </h3>
                                    <div style="font-size: 32px;"><strong>US ${{TotalCartFee}}</strong></div>
                                    <!-- <a class="btn_left1" href="javascript:void(0)" routerLink="/checkout">Secure Checkout</a><br/> -->
                                    <button class="btn_left1" href="javascript:void(0)" (click)="checkout()">Secure Checkout</button><br/>
                                    <span style="font-size: 12px;">Price displayed excludes sales tax.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <!-- .row end -->
        </div>
    </div>
    <!-- courses area end -->



</main>
