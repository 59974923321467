import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter',
})
export class SearchFilterPipe implements PipeTransform {
  transform(value: any, searchfilter: string) {
    if (value.length === 0 || searchfilter === '') {
      return value;
    }
    const ListTemp = [];
    for (const List of value) {
      if (List?.name === searchfilter) {
        ListTemp.push(List);
      }
    }
    return ListTemp;
  }
}
