import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { CourseService } from 'src/app/services/course.service';
import { UserService } from 'src/app/services/userservice.service';
import { APP_URLS, tenantConfig, TenantId } from 'src/app/Utils/general';
import { mainPermissions } from 'src/app/Utils/permissions';
declare var $: any;

export interface ClsObj {
  course_type: string;
  description: string;
  courses: any[];
}
@Component({
  selector: 'app-vle-header',
  templateUrl: './vle-header.component.html',
  styleUrls: ['./vle-header.component.css'],
})
export class VleHeaderComponent implements OnInit {
  ishome = false;

  @Input() menuItem: any;
  @Input() loadCarousel = false;
  showDialog = false;
  showDialogForgot = false;
  permissions: any = {};
  showDialogReg = false;
  tenantInfo: any = tenantConfig;
  isShown = false;
  isclose = false;
  sub: any;
  permissionDetails: any = [];
  permissionInfo$: Subscription;
  page: number | undefined;
  headerClass = '';
  selectCourseId: any;
  selectCourseIdData: any;
  userDetails: any = [];
  token = false;
  categoryData: ClsObj[] = [];
  userDetail: any = {};
  studentID = '';
  userRole: any = '';
  myAtLaBShow = '';
  myOTJShow = '';
  tenantPermission: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public service: CourseService,
    private userSvc: UserService,
    private Cs: CommonService
  ) {
    this.userSvc.loggedUserDetails.subscribe((user: any) => {
      if (user) {
        this.userDetails = user;
      }
    });
    if (localStorage.getItem('token')) {
      this.getProfilePermissions();
    }
    /**
     * Get permission data from local object
     */
    this.permissionInfo$ = this.userSvc.userPermission.subscribe((data: any) => {
      this.permissionDetails = data;
    });

    this.tenantPermission =  JSON.parse(localStorage.getItem('tenantPermission'))?.permissions;
    console.log(this.tenantPermission);


  }

  ngOnInit(): void {
    this.userRole = localStorage.getItem('role');

    const profileData = localStorage.getItem('profilesummaryData');
    if (profileData !== undefined && typeof profileData === 'string') {
      this.userDetail = JSON.parse(profileData);
      if (this.userDetail != null) {
        this.studentID = this.userDetail._id;
      }
    }
    const store: any = localStorage.getItem('categoryList');
    this.categoryData = JSON.parse(store);

    this.Cs.getForgotPop().subscribe((data: any) => {
      if (data === 'forgot') {
        this.showDialog = false;
        this.showDialogForgot = true;
        this.showDialogReg = false;
      }
      if (data === 'register') {
        this.showDialog = false;
        this.showDialogForgot = false;
        this.showDialogReg = true;
      }
      if (data === 'login') {
        this.showDialog = true;
        this.showDialogForgot = false;
        this.showDialogReg = false;
      }
    });
    this.Cs.getPopup().subscribe((data: any) => {
      this.showDialogReg = false;
    });

    this.userSvc.islogin.subscribe((data) => {
      this.token = data;
    });

    if (localStorage.getItem('token') && this.studentID) {
      this.token = true;
    }
    this.selectCourseId = this.service
      .getDataToHeader()
      .subscribe((data: any) => {
        this.selectCourseIdData = data.text;
      });

    if (this.userRole !== 'employer') {
      this.getCategoryList();
    }
    this.getTenantSetting();

    /*---------- Mobile Menu Active ----------*/
    $.fn.vsmobilemenu = function(options) {
      const opt = $.extend(
        {
          menuToggleBtn: '.vs-menu-toggle',
          liToggleBtn: '.vs-mobile-menu ul li:not(.menu-item-has-children)',
          bodyToggleClass: 'vs-body-visible',
          subMenuClass: 'vs-submenu',
          subMenuParent: 'vs-item-has-children',
          subMenuParentToggle: 'vs-active',
          meanExpandClass: 'vs-mean-expand',
          subMenuToggleClass: 'vs-open',
          toggleSpeed: 400,
        },
        options
      );

      return this.each(function() {
        const menu = $(this); // Select menu

        // Menu Show & Hide
        function menuToggle() {
          menu.toggleClass(opt.bodyToggleClass);

          // collapse submenu on menu hide or show
          const subMenu = '.' + opt.subMenuClass;
          $(subMenu).each(function() {
            if ($(this).hasClass(opt.subMenuToggleClass)) {
              $(this).removeClass(opt.subMenuToggleClass);
              $(this).css('display', 'none');
              $(this).parent().removeClass(opt.subMenuParentToggle);
            }
          });
        }
        // Class Set Up for every submenu
        menu.find('li').each(function() {
          const submenu = $(this).find('ul');
          submenu.addClass(opt.subMenuClass);
          submenu.css('display', 'none');
          submenu.parent().addClass(opt.subMenuParent);
          submenu.prev('a').addClass(opt.meanExpandClass);
          submenu.next('a').addClass(opt.meanExpandClass);
        });

        // Toggle Submenu
        function toggleDropDown($element) {
          if ($($element).next('ul').length > 0) {
            $($element).parent().toggleClass(opt.subMenuParentToggle);
            $($element).next('ul').slideToggle(opt.toggleSpeed);
            $($element).next('ul').toggleClass(opt.subMenuToggleClass);
          } else if ($($element).prev('ul').length > 0) {
            $($element).parent().toggleClass(opt.subMenuParentToggle);
            $($element).prev('ul').slideToggle(opt.toggleSpeed);
            $($element).prev('ul').toggleClass(opt.subMenuToggleClass);
          }
        }

        // Submenu toggle Button
        const expandToggler = '.' + opt.meanExpandClass;
        $(expandToggler).each(function() {
          $(this).on('click', function(e) {
            e.preventDefault();
            toggleDropDown(this);
          });
        });

        // Menu Show & Hide On Toggle Btn click
        $(opt.menuToggleBtn).each(function() {
          $(this).on('click', () => {
            menuToggle();
          });
        });

        // Menu Show & Hide On Toggle Btn click
        $(opt.liToggleBtn).each(function() {
          $(this).on('click', () => {
            menuToggle();
          });
        });

        // Hide Menu On out side click
        menu.on('click', (e) => {
          e.stopPropagation();
          menuToggle();
        });

        // Stop Hide full menu on menu click
        menu.find('div').on('click', (e) => {
          e.stopPropagation();
        });

        // Hide Menu On click menu items
        $('.item-click').on('click', (e) => {
          e.stopPropagation();
          menuToggle();
        });
      });
    };
    $('.vs-menu-wrapper').vsmobilemenu();

    /*---------- Sticky fix ----------*/
    let lastScrollTop = '';
    const scrollToTopBtn = '.scrollToTop';

    function stickyMenu($targetMenu, $toggleClass, $parentClass) {
      const st = $(window).scrollTop();
      const height = $targetMenu.css('height');
      $targetMenu.parent().css('min-height', height);
      if ($(window).scrollTop() > 500) {
        $targetMenu.parent().addClass($parentClass);

        if (st > lastScrollTop) {
          $targetMenu.removeClass($toggleClass);
        } else {
          $targetMenu.addClass($toggleClass);
        }
      } else {
        $targetMenu.parent().css('min-height', '').removeClass($parentClass);
        $targetMenu.removeClass($toggleClass);
      }
      lastScrollTop = st;
    }
    $(window).on('scroll', function() {
      stickyMenu($('.sticky-active'), 'active', 'will-sticky');
      if ($(this).scrollTop() > 500) {
        $(scrollToTopBtn).addClass('show');
      } else {
        $(scrollToTopBtn).removeClass('show');
      }
    });
  }

  getTenantSetting(): void {
    const url = APP_URLS.tenantSettings;
    this.userSvc.getCallWithHeaders(url).subscribe((res: any) => {
      if (res?.details?.length > 0) {
        localStorage.setItem('TenantSetting', JSON.stringify(res?.details[0]));
        this.getTenantPermission();
      }
    });
  }

  getTenantPermission(): void {
    const url = APP_URLS.tenantPermission;
    this.userSvc.getCallWithHeaders(url).subscribe((res: any) => {
      if (res?.details?.length > 0) {
        localStorage.setItem(
          'tenantPermission',
          JSON.stringify(res?.details[0])
        );
        this.generatePermissionData(res?.details[0]);
      }
    });
  }

  generatePermissionData(tenantPermission: any): void {
    const tenantSettings = JSON.parse(localStorage.getItem('TenantSetting'));
    this.permissions = {};
    if (mainPermissions && this.userDetails?.role?.value === 'Super admin') {
      mainPermissions.forEach((val: any) => {
        if (val === 'main_tabs') {
          val.forEach((val1: any) => {
            this.permissions[val1.key] = { 9: true, 8: true };
          });
        } else {
          val.forEach((value1: any) => {
            this.permissions[value1.key] = true;
          });
        }
      });
    } else if (mainPermissions && this.userRole === 'student' || this.userRole === 'employer') {
      if (tenantPermission?.permissions?.length > 0) {
        tenantPermission?.permissions.forEach((v: any) => {
          if (
            mainPermissions.main_tabs.findIndex((x: any) => x.key === v) > -1
          ) {
            this.permissions[v] = { 9: true, 8: true };
          } else {
            this.permissions[v] = true;
          }
        });
      }
    } else {
      // other tabs permissions using tenantSettings.permissions
      if (tenantSettings?.permissions?.length > 0) {
        tenantSettings.permissions.forEach((v: any) => {
          tenantPermission?.permissions.forEach((element: any) => {
            if (element === v) {
              if (
                mainPermissions?.main_tabs.findIndex((x: any) => {
                  // tslint:disable-next-line:no-unused-expression
                  x.key === v;
                }) > -1
              ) {
                this.permissions[v] = true;
              }
            }
          });
        });
      }

      // role based all tabs permissions except entities
      if (
        tenantSettings?.role_permissions &&
        this.userDetails?.role?.value &&
        tenantSettings.role_permissions[this.userDetails?.role?.value]
      ) {
        const rolePermissions =
          tenantSettings.role_permissions[this.userDetails?.role?.value];

        if (rolePermissions && rolePermissions.length > 0) {
          this.permissions = {};
          rolePermissions.forEach((v: any) => {
            if (
              tenantPermission?.permissions.findIndex((x: any) => x === v) > -1
            ) {
              this.permissions[v] = true;
            }
          });
        }
      }

      // employee based all tabs permissions except entities
      if (this.userDetails?.role_permissions?.length > 0) {
        const employeePermissions = this.userDetails?.role_permissions;
        if (employeePermissions && employeePermissions.length > 0) {
          this.permissions = {};
          employeePermissions.forEach((v: any) => {
            if (
              tenantPermission?.permissions.findIndex((x: any) => x === v) > -1
            ) {
              this.permissions[v] = true;
            }
          });
        }
      }

      // other employees permissions
      // Permission role based check
      if (tenantSettings && tenantSettings.tab_permissions) {
        tenantSettings.tab_permissions.forEach((v: any) => {
          if (
            v?.permissions &&
            this.userDetails?.role?.value &&
            v.role === this.userDetails?.role?.value
          ) {
            Object.keys(v.permissions).forEach((v1: any) => {
              // permission check for role based permissions
              if (tenantPermission?.permissions.length > 0) {
                this.permissions[v1] = {};

                if (v.permissions[v1] === '9') {
                  this.permissions[v1][v.permissions[v1]] = true;
                  this.permissions[v1]['8'] = true;
                }
                if (v.permissions[v1] === '8') {
                  this.permissions[v1][v.permissions[v1]] = true;
                }
                if (v.permissions[v1] === '7') {
                  this.permissions[v1][v.permissions[v1]] = true;
                }
              }
            });
            return;
          }
        });
      }

      // Employee level permission based check
      if (this.userDetails?.tab_permissions) {
        Object.keys(this.userDetails?.tab_permissions).forEach((v1: any) => {
          if (tenantPermission?.permissions.length > 0) {
            if (!this.permissions[v1]) {
              this.permissions[v1] = {};
            }

            if (this.userDetails?.tab_permissions[v1] === '9') {
              this.permissions[v1][this.userDetails?.tab_permissions[v1]] =
                true;
              this.permissions[v1]['8'] = true;
            }
            if (this.userDetails?.tab_permissions[v1] === '8') {
              this.permissions[v1]['9'] = false;
              this.permissions[v1][this.userDetails?.tab_permissions[v1]] =
                true;
            }
            if (this.userDetails?.tab_permissions[v1] === '7') {
              this.permissions[v1]['9'] = false;
              this.permissions[v1]['8'] = false;
              this.permissions[v1][this.userDetails?.tab_permissions[v1]] =
                true;
            }
          }
        });
      }
    }
    this.userSvc.setUserPermission(this.permissions);
  }

  closeMobileMenuOnClick(){
    $('#close-menu').click();
  }

  logout(place = '') {
    localStorage.clear();
    this.userSvc.setIsLogin(false);
    if (place === 'mobile-nav'){
      this.closeMobileMenuOnClick();
    }
    this.router.navigate(['/home/'], { queryParams: { page: 0 } });
  }

  openCourses(coursetype: any) {
    localStorage.setItem('courseType', coursetype.course_type);
    this.router.navigate([
      '/Courses/' + coursetype.course_type.replace(/\s/g, '-'),
    ]);
  }

  validateMenuPrivilage(key1: any) {
    if (this.permissionDetails[key1] && this.permissionDetails[key1] === true) {
      return true;
    } else {
      return false;
    }
  }

  myWork(place = ''){
    if (this.validateMenuPrivilage(12000) === true){
      this.router.navigate(['/vle/my-work/my-task']);
    }else if (this.validateMenuPrivilage(12001) === true && this.validateMenuPrivilage(12000) === false){
      this.router.navigate(['/vle/my-work/wallet']);
    // tslint:disable-next-line:max-line-length
    }else if (this.validateMenuPrivilage(12002) === true && this.validateMenuPrivilage(12001) === false && this.validateMenuPrivilage(12000) === false){
      this.router.navigate(['/vle/my-work/kyc']);
    // tslint:disable-next-line:max-line-length
    }else if (this.validateMenuPrivilage(12003) === true && this.validateMenuPrivilage(12002) === false && this.validateMenuPrivilage(12001) === false && this.validateMenuPrivilage(12000) === false){
      this.router.navigate(['/vle/my-work/withdraw']);
    // tslint:disable-next-line:max-line-length
    }else if (this.validateMenuPrivilage(12004) === true && this.validateMenuPrivilage(12003) === false && this.validateMenuPrivilage(12002) === false && this.validateMenuPrivilage(12001) === false && this.validateMenuPrivilage(12000) === false){
      this.router.navigate(['/vle/my-work/channel-account']);
    }else {
      this.router.navigate(['/vle/my-work']);
    }
    if (place === 'mobile-nav'){
      this.closeMobileMenuOnClick();
    }

  }

  profile() {
    if (this.userRole === 'employer') {
      // this.router.navigate(['/vle/employer-profile/emp-profile-summary']);
      if (this.validateMenuPrivilage(16100) === true){
        this.router.navigate(['/vle/employer-profile/emp-profile-summary']);
      }else if (this.validateMenuPrivilage(16101) === true && this.validateMenuPrivilage(16100) === false){
        this.router.navigate(['/vle/employer-profile/emp-action-center']);
      // tslint:disable-next-line:max-line-length
      }else if (this.validateMenuPrivilage(16102) === true && this.validateMenuPrivilage(16101) === false && this.validateMenuPrivilage(16100) === false){
        this.router.navigate(['/vle/employer-profile/my-apprentice']);
      // tslint:disable-next-line:max-line-length
      }else if (this.validateMenuPrivilage(16103) === true && this.validateMenuPrivilage(16102) === false && this.validateMenuPrivilage(16101) === false && this.validateMenuPrivilage(16100) === false){
        this.router.navigate(['/vle/employer-profile/download-center']);
      // tslint:disable-next-line:max-line-length
      }else if (this.validateMenuPrivilage(16104) === true && this.validateMenuPrivilage(16103) === false && this.validateMenuPrivilage(16102) === false && this.validateMenuPrivilage(16101) === false && this.validateMenuPrivilage(16100) === false){
        this.router.navigate(['/vle/employer-profile/emp-support-ticket']);
      // tslint:disable-next-line:max-line-length
      }else if (this.validateMenuPrivilage(16105) === true && this.validateMenuPrivilage(16104) === false && this.validateMenuPrivilage(16103) === false && this.validateMenuPrivilage(16102) === false && this.validateMenuPrivilage(16101) === false && this.validateMenuPrivilage(16100) === false){
        this.router.navigate(['/vle/employer-profile/emp-notification']);
      }
    } else if (this.userRole === 'student'){
      // this.router.navigate(['/vle/profile/profile-summary']);
      if (this.validateMenuPrivilage(11000) === true){
        this.router.navigate(['/vle/profile/profile-summary']);
      }else if (this.validateMenuPrivilage(11001) === true && this.validateMenuPrivilage(11000) === false){
        this.router.navigate(['/vle/profile/admission/personal-details']);
      // tslint:disable-next-line:max-line-length
      }else if (this.validateMenuPrivilage(11002) === true && this.validateMenuPrivilage(11001) === false && this.validateMenuPrivilage(11000) === false){
        this.router.navigate(['/vle/profile/book-interview']);
      // tslint:disable-next-line:max-line-length
      }else if (this.validateMenuPrivilage(11003) === true && this.validateMenuPrivilage(11002) === false && this.validateMenuPrivilage(11001) === false && this.validateMenuPrivilage(11000) === false){
        this.router.navigate(['/vle/profile/action-center']);
      // tslint:disable-next-line:max-line-length
      }else if (this.validateMenuPrivilage(11004) === true && this.validateMenuPrivilage(11003) === false && this.validateMenuPrivilage(11002) === false && this.validateMenuPrivilage(11001) === false && this.validateMenuPrivilage(11000) === false){
        this.router.navigate(['/vle/profile/attendance']);
      // tslint:disable-next-line:max-line-length
      }else if (this.validateMenuPrivilage(11005) === true && this.validateMenuPrivilage(11004) === false && this.validateMenuPrivilage(11003) === false && this.validateMenuPrivilage(11002) === false && this.validateMenuPrivilage(11001) === false && this.validateMenuPrivilage(11000) === false){
        this.router.navigate(['/vle/profile/progression']);
      // tslint:disable-next-line:max-line-length
      }else if (this.validateMenuPrivilage(11006) === true && this.validateMenuPrivilage(11005) === false && this.validateMenuPrivilage(11004) === false && this.validateMenuPrivilage(11003) === false && this.validateMenuPrivilage(11002) === false && this.validateMenuPrivilage(11001) === false && this.validateMenuPrivilage(11000) === false){
        this.router.navigate(['/vle/profile/certificate']);
      // tslint:disable-next-line:max-line-length
      }else if (this.validateMenuPrivilage(11007) === true && this.validateMenuPrivilage(11006) === false && this.validateMenuPrivilage(11004) === false && this.validateMenuPrivilage(11004) === false && this.validateMenuPrivilage(11003) === false && this.validateMenuPrivilage(11002) === false && this.validateMenuPrivilage(11001) === false && this.validateMenuPrivilage(11000) === false){
        this.router.navigate(['/vle/profile/support-ticket']);
      // tslint:disable-next-line:max-line-length
      }else if (this.validateMenuPrivilage(11008) === true && this.validateMenuPrivilage(11007) === false && this.validateMenuPrivilage(11006) === false && this.validateMenuPrivilage(11004) === false && this.validateMenuPrivilage(11004) === false && this.validateMenuPrivilage(11003) === false && this.validateMenuPrivilage(11002) === false && this.validateMenuPrivilage(11001) === false && this.validateMenuPrivilage(11000) === false){
        this.router.navigate(['/vle/profile/complaint']);
      // tslint:disable-next-line:max-line-length
      }else if (this.validateMenuPrivilage(11009) === true && this.validateMenuPrivilage(11008) === false && this.validateMenuPrivilage(11007) === false && this.validateMenuPrivilage(11006) === false && this.validateMenuPrivilage(11004) === false && this.validateMenuPrivilage(11004) === false && this.validateMenuPrivilage(11003) === false && this.validateMenuPrivilage(11002) === false && this.validateMenuPrivilage(11001) === false && this.validateMenuPrivilage(11000) === false){
        this.router.navigate(['/vle/profile/notification']);
      // tslint:disable-next-line:max-line-length
      }else if (this.validateMenuPrivilage(11010) === true && this.validateMenuPrivilage(11009) === false && this.validateMenuPrivilage(11008) === false && this.validateMenuPrivilage(11007) === false && this.validateMenuPrivilage(11006) === false && this.validateMenuPrivilage(11004) === false && this.validateMenuPrivilage(11004) === false && this.validateMenuPrivilage(11003) === false && this.validateMenuPrivilage(11002) === false && this.validateMenuPrivilage(11001) === false && this.validateMenuPrivilage(11000) === false){
        this.router.navigate(['/vle/profile/feedback']);
      }else {
        this.router.navigate(['/vle/profile']);
      }
    }
    this.userSvc.setProfile('message');
  }

  mywork() {
    if (this.userRole === 'employer') {
      this.router.navigate(['/employer-mywork/emp-my-project']);
    } else {
      this.router.navigate(['/student-my-work/student-work-summary']);
    }
  }

  openIndividulaCourse(course: any) {

    localStorage.setItem('Individualcourse', course.slug);
    this.router.navigate(['/' + course.slug]);
  }

  getCategoryList() {
    const url =
      APP_URLS.Categories +
      '?filter={"criteria":[{"key":"tenantId","value":"' +
      TenantId +
      '","type":"eq"}]}';
    this.userSvc.getCall(url).subscribe((res: any) => {
      const name: any = [];
      const tempCategoryData: any = [];
      const id = '';
      const categories = Object.values(res.course_category_detail);
      categories.forEach((x: any) => {
        const obj: ClsObj = {
          course_type: '',
          description: '',
          courses: [],
        };
        const repetingdata = name.find(
          (z: any) => z === res.course_category_detail.course_type
        );
        if (repetingdata) {
        } else {
          if (x.title) {
            obj.course_type = x.title;
            obj.description = x.description;
            if (x?.course?.length) {
              x.course.forEach((y: any) => {
                if (x.title === y.course_type) {
                  obj.courses.push({
                    id: y._id,
                    name: y.name,
                    slug: y.slug,
                  });
                }
              });
            }
            tempCategoryData.push(obj);
          }
        }
      });
      if (this.categoryData === null) {
        this.categoryData = tempCategoryData;
      }

      localStorage.setItem('categoryList', JSON.stringify(tempCategoryData));
    });
  }

  async getProfilePermissions() {
    const url = APP_URLS.profilePermissions;

    await this.userSvc.getCallWithHeaders(url).subscribe(
      (res: any) => {
        this.myAtLaBShow = res?.my_at_lab;
        this.myOTJShow = res?.my_otj;
      },
      (err) => {
      }
    );
  }

  @HostListener('document:scroll')
  scrollfunction() {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      this.ishome = true;
    } else {
      this.ishome = false;
    }
  }

  checkPermission(tab){
    let retVal = false;
    if (tab === 'my-work'){
      [12000, 12001, 12002, 12003, 12004, 12005, 12006]?.forEach((k) => {
        if (this.tenantPermission?.findIndex((tp) => tp === k) !== -1){
          retVal = true;
        }
      });
    }

    return retVal;
  }
}
