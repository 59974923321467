<!-- ======= Whu Us Section ======= -->
<section id="why-us" class="why-us">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-4">
                <div class="box">
                    <span class="text-primary">01</span>
                    <h3 class="display-3">Industry Driven Approach</h3>
                    <img src="assets/img/steps/step-1.jpg" class="img-fluid mb-3" />
                    <p>An industry-driven approach helps to develop a deep understanding of  information that we provide. It will have a unique character on it. </p>
                </div>
            </div>

            <div class="col-lg-4 mt-4 mt-lg-0">
                <div class="box">
                    <span class="text-primary">02</span>
                    <h3 class="display-3">Blended Learning Model</h3>
                    <img src="assets/img/steps/step-2.jpg" class="img-fluid mb-3" />
                    <p> Blended learning models are methods used for hybrid learning. It is an experience and an opportunity that combines face-to-face learning with distance learning between teachers and students. </p>
                </div>
            </div>

            <div class="col-lg-4 mt-4 mt-lg-0">
                <div class="box">
                    <span class="text-primary">03</span>
                    <h3 class="display-3">Hands-on Class Room Training</h3>
                    <img src="assets/img/steps/step-3.jpg" class="img-fluid mb-3" />
                    <p> Hands-on learning is engaging students in direct experience and focused on students’ knowledge, skills, and values. It allows students to immerse themselves in a learning environment.  </p>
                </div>
            </div>

        </div>

    </div>
</section>
<!-- End Whu Us Section -->
