import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
  PreloadAllModules,
} from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AuthenticationGuard } from './authentication.guard';
import { NoInternetComponent } from './components/no-internet/no-internet.component';
import { CrmMainComponent } from './crm/components/crm-main/crm-main.component';
import { CrmAuthGuardGuard } from './crm/crm-auth-guard.guard';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { HelpDeskComponent } from './components/help-desk/help-desk.component';
import { WhyusInfoComponent } from './components/whyus-info/whyus-info.component';
import { OurApproachComponent } from './components/our-approach/our-approach.component';
import { GovernanceComponent } from './components/governance/governance.component';
import { VleAuthGuardGuard } from './vle/vle-auth-guard.guard';
import { VleMainComponent } from './vle/components/vle-main/vle-main.component';
import { MainCoursesComponent } from './components/main-courses/main-courses.component';
import { IndividualCourseComponent } from './components/individual-course/individual-course.component';
import { RegisterComponent } from './components/register/register.component';
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { MainComponent } from './components/main/main.component';
import { ContactusComponent } from './components/contactus/contactus.component';
import { CartDetailsComponent } from './components/cart-details/cart-details.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { CourseListComponent } from './vle/components/student/course/course-list/course-list.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { SetPasswordComponent } from './components/set-password/set-password.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [AuthenticationGuard],
      },
      { path: 'Cart', component: CartDetailsComponent },
      { path: 'checkout', component: CheckoutComponent },

      {
        path: 'login/:type',
        component: LoginComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'privacypolicy',
        component: PrivacyPolicyComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'governance',
        component: GovernanceComponent,
        canActivate: [AuthenticationGuard],
      },
      // {
      //   path: 'policies',
      //   component: PoliciesComponent,
      //   canActivate: [AuthenticationGuard],
      // },
      {
        path: 'whyus',
        component: WhyusInfoComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'aboutus',
        component: AboutUsComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'termsAndConditions',
        component: TermsAndConditionsComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'ourApproach',
        component: OurApproachComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'contact',
        component: ContactusComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'helpdesk',
        component: HelpDeskComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'Courses/:id',
        component: MainCoursesComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'register',
        component: RegisterComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'forgotPassword',
        component: ForgotpasswordComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'forgotPassword/:type',
        component: ForgotpasswordComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: ':id',
        component: IndividualCourseComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'my-courses',
        component: CourseListComponent,
      },
    ],
  },
  {
    path: 'crm',
    component: CrmMainComponent,
    canActivate: [CrmAuthGuardGuard],
    data: {
      unAuthRoles: 'ROLE_STUDENT',
    },
    loadChildren: () => import('./crm/crm.module').then((crm) => crm.CrmModule),
  },
  {
    path: 'vle',
    component: VleMainComponent,
    canActivate: [VleAuthGuardGuard],
    data: {
      unAuthRoles: 'ROLE_ADMIN,ROLE_AGENT,ROLE_PARTNER',
    },
    loadChildren: () => import('./vle/vle.module').then((vle) => vle.VleModule),
  },
  {
    path: 'no-internet',
    component: NoInternetComponent,
  },
  {
    path: 'setpassword/:id',
    component: SetPasswordComponent
  },
  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFoundComponent,
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,
    //   {
    //   preloadingStrategy: PreloadAllModules,
    //   scrollPositionRestoration: 'enabled',
    // }
    ),
  ],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
