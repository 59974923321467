import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CourseService } from '../../services/course.service';
import { Subscription } from 'rxjs';
import { APP_URLS, TenantId } from 'src/app/Utils/general';
import { UserService } from 'src/app/services/userservice.service';

@Component({
  selector: 'app-cart-details',
  templateUrl: './cart-details.component.html',
  styleUrls: ['./cart-details.component.css'],
})
export class CartDetailsComponent implements OnInit, OnDestroy {
  showDialogReg = false;
  CartDetails = [];
  TotalCartFee = 0;
  listCount: any;
  CartList = [];
  studentId: any;
  accessToken = '';
  isLoading = false;
  tenantId = TenantId;
  detailRecivedSubscription: Subscription;

  constructor(
    private userSvc: UserService,
    private router: Router,
    public service: CourseService
  ) {
    this.detailRecivedSubscription = this.userSvc.getCartData.subscribe(
      (data) => {
        if (data) {
          this.ngOnInit();
        }
      }
    );
  }

  ngOnDestroy() {
    this.detailRecivedSubscription.unsubscribe();
  }

  ngOnInit(): void {
    console.log(TenantId);
    const data = JSON.parse(localStorage.getItem('userDetails'));
    if (data) {
      this.studentId = data._id;
    }

    if (data != null) {
      this.accessToken = data.accessToken;
    }
    const dataofcart = localStorage.getItem('cartdata');

    if (dataofcart != null) {
      this.CartList = dataofcart.split(',');
      // this.addtocartCall();
    }

    this.getCart();
  }

  selectCourse(data) {
    this.service.selectCourseById(data.course);
    this.service.senddataToHeader(data.course);
    this.router.navigate(['/' + data.course.slug]);
  }

  // async addtocartCall(){

  //   let url = APP_URLS.cartmulti;
  //   let payload = {
  //     courseids : this.CartList
  //   }
  //   await this.userSvc.putCallwithHeaders(url,payload).subscribe((res:any)=>{
  //     if(res.suc_arr.length > 0){
  //       // localStorage.removeItem('cartdata')
  //     }
  //   })
  // }

  async getCart() {
    this.isLoading = true;
    if (localStorage.getItem('token') && this.studentId) {
      const url = APP_URLS.getcart;
      const payload = {};
      await this.userSvc
        .postCallwithHeaders(url, payload)
        .subscribe((res: any) => {
          this.isLoading = false;
          this.listCount = res.listCount;
          this.CartDetails = res.list;
          //  this.userSvc.setCart("cart")
          let total = 0;
          res.list.forEach((x) => {
            total = total + x.course.course_fee_final;
          });
          this.TotalCartFee = total;
        });
    } else {
      const url = APP_URLS.getcart;
      const payload = {
        courseids: this.CartList,
        tenantId: TenantId,
      };
      await this.userSvc.postCall(url, payload).subscribe((res: any) => {
        this.listCount = res.listCount;
        this.CartDetails = res.list;
        // this.userSvc.setCart("cart")
        let total = 0;
        res.list.forEach((x) => {
          total = total + x.course.course_fee_final;
        });
        this.TotalCartFee = total;
        this.isLoading = false;
      });
    }
  }

  removeCart(item) {
    if (localStorage.getItem('token') && this.studentId) {
      const url = APP_URLS.removefromcart + item.course._id;
      const payload = {
        cartId: item._id,
        courseId: item.course._id,
      };

      this.userSvc.postCallwithHeaders(url, payload).subscribe((res: any) => {
        if (res.respCode) {
          this.userSvc.showSuccess(res.respMessage);
          this.userSvc.setCart('cart');
          this.getCart();
        }
      });
    } else {
      this.CartList.forEach((x, i) => {
        if (x === item.course._id) {
          this.CartList.splice(i, 1);
          const data = this.CartList;
          if (data.length > 0) {
            localStorage.setItem('cartdata', data.toString());
          }
          if (data.length === 0) {
            localStorage.removeItem('cartdata');
          }

          this.userSvc.showSuccess('Course Removed from Cart');
          this.userSvc.setCart('cart');
          this.getCart();
        }
      });
    }
  }

  checkout() {
    if (this.CartDetails.length > 0) {
      this.router.navigate(['/checkout']);
    } else {
      this.userSvc.showError('No course selected');
    }
  }
}
