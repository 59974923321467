

<main class="inner-page">
  <!-- ======= Breadcrumbs Section ======= -->
  <section class="breadcrumb">
    <div class="container-fluid">
        <div class="d-flex justify-content-between align-items-center text-xs-center">
            <ul>
                <li><a routerLink="/" href="javascript:void(0)">Home</a></li>
                <li>Policies & Procedures</li>
            </ul>
        </div>
    </div>
</section>
<!-- End Breadcrumbs Section -->

<div class="course-wrapper mt-2 container">
  <div class="main-content privacy-content">
    <div class="approach-content">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h2 class="title-heading2-left text-center pb-md-5 pt-5 mb-5 mb-md-5">POLICIES &amp; PROCEDURES</h2>
                </div>
            </div>
            <div class="row text-center">
                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <h2 class="pdf-bg"><a href="https://abacademy.co.uk/wp-content/uploads/Admission-process.pdf">Admission
              Process</a></h2>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <h2 class="pdf-bg"><a href="https://abacademy.co.uk/wp-content/uploads/Complaints-and-Grievances.pdf">Complaints and
              Grievances</a></h2>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <h2 class="pdf-bg"><a href="https://abacademy.co.uk/wp-content/uploads/Access-and-participation-statement.pdf">Access and
              participation statement</a></h2>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <h2 class="pdf-bg"><a href="https://abacademy.co.uk/wp-content/uploads/Student-protection.pdf">Student
              Protection</a></h2>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <h2 class="pdf-bg"><a href="https://abacademy.co.uk/wp-content/uploads/Meeting-CMA-expectations.pdf">Meeting
              CMA exceptations</a></h2>
                </div>
                <p class="text-left pb-md-5 mb-md-5 pb-4 text-start">In line with the changes of the regulator and the regulatory framework in higher education, {{tenantInfo?.name}} has applied for membership in the new regulatory agency by the name of Office for Students (OfS) <a>https://www.officeforstudents.org.uk/</a></p>
            </div>
        </div>
    </div>
  </div>
</div>



</main>
