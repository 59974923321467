import { Router } from '@angular/router';
import { CourseService } from './../../services/course.service';
import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { UserService } from 'src/app/services/userservice.service';
import { APP_URLS, tenantConfig, TenantId } from 'src/app/Utils/general';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css'],
})
export class GalleryComponent implements OnInit {
  student_Id = '';
  @Input() proCoursesData: any;
  courses: any;
  role: any;
  tenantInfo: any = tenantConfig;
  FILE_PATH_LINODE = APP_URLS.FILE_PATH_LINODE;
  token = '';
  constructor(
    private toastr: UserService,
    private service: CourseService,
    private router: Router
  ) {
    const storage = localStorage.getItem('userDetails');
    this.token = localStorage.getItem('token');

    if (storage !== null) {
      const userDetails = JSON.parse(storage);
      this.student_Id = userDetails ? userDetails._id : '';
    }
  }

  ngOnInit(): void {
    this.getCourseList();
    this.role = localStorage.getItem('role');
  }

  selectCourse(data: any) {
    this.service.selectCourseById(data);
    this.service.senddataToHeader(data);
    if (this.role) {
      this.router.navigate(['/vle/' + data.slug]);
    } else {
      this.router.navigate(['/' + data.slug]);
    }
  }

  getCourseList() {
    const url =
      APP_URLS.Courses +
      '?filter={"criteria":[{"key":"tenantId","value":' +
      TenantId +
      ',"type":"eq"}]}&tenantId=' +
      TenantId;
    let getCall: any;
    if (this.student_Id !== '') {
      getCall = this.toastr.getCallWithHeaders(url);
    } else {
      getCall = this.toastr.getCall(url);
    }

    getCall.subscribe((res: any) => {
      if (res.courses.length > 0) {
        res.courses.map((x: any) => {
          if (this.student_Id) {
            if (x.favorite) {
              x.Favouritebutton = 'Remove Favourite';
            } else {
              x.Favouritebutton = 'Add As Favourite';
            }
            if (x.applied) {
              x.Applybutton = 'Applied';
            } else {
              x.Applybutton = 'Apply';
            }
          } else {
            x.Favouritebutton = 'Add As Favourite';
            x.Applybutton = 'Apply';
          }
        });
        this.proCoursesData = _.groupBy(res.courses, 'course_type');
        const courseList: any[] = [];
        Object.keys(this.proCoursesData)?.forEach((data) => {
          if (data !== '') {
            const obj: any = {};
            obj.courseCategory = data;
            obj.courseDetails = this.proCoursesData[data];
            courseList.push(obj);
          }
        });

        this.courses = courseList[0]?.courseDetails;
      }
    });
  }

  AddFav(data: any) {
    const url = APP_URLS.Courses + '/' + data._id + '?favorite=true';
    if (this.student_Id) {
      this.toastr.putCallwithHeaders(url, {}).subscribe(
        (data: any) => {
          this.toastr.showSuccess(data.respMessage);
          this.ngOnInit();
        },
        (err) => {}
      );
    } else {
      this.toastr.showError('Please register or login to continue');
      this.service.selectedcourse(data._id, 'favourite');
    }
  }
}
