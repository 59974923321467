<!-- ======= Top Bar ======= -->
<section id="topbar" class="d-flex align-items-center fixed-top topbar-transparent d-xs-none" [ngClass]="{'topbar-scrolled': ishome === true }">
    <div class="container-fluid  d-flex align-items-center justify-content-center justify-content-lg-end">
        <span>Hi <span *ngIf="userDetail?.title">{{userDetail.title}}.</span> </span><span class="ms-1" data-toggle="tooltip" data-placement="right" title="{{userDetail.displayName || userDetail?.name}}">{{userDetail.displayName || userDetail?.name}}</span>
        <i class="d-lg-flex align-items-center"><span><a href="mailto:{{tenantInfo?.email}}"
      class="text-white">{{tenantInfo?.email}}</a></span></i>
        <i class="d-flex align-items-center ms-3"><span><a href="tel:{{tenantInfo?.phone}}"class="text-white">{{tenantInfo?.phone}}</a></span></i>
    </div>
</section>

<!--==============================
Mobile Menu
============================== -->
<div class="vs-menu-wrapper">
  <div class="vs-menu-area text-center">
      <button class="vs-menu-toggle" id="close-menu"><i class="fas fa-times"></i></button>
      <div class="mobile-logo">
          <a routerLinkActive="active" [routerLink]="['/vle/home']" (click)="closeMobileMenuOnClick()"><img [attr.src]="tenantInfo?.logoUrl" style="width: 120px !important;"></a>
      </div>
      <div class="vs-mobile-menu">
          <ul>

            <li class="nav-item ">
                <a class="nav-link" href="javacript:void(0)" routerLink="home">HOME</a>
            </li>
            <li class="nav-item" *ngIf="userRole != 'employer'">
                <a class="nav-link" href="javacript:void(0)" routerLink="my-courses" (click)="closeMobileMenuOnClick()">MY COURSES</a>
            </li>
            <li role="nav-item" class="nav-item" *ngIf="userRole != 'employer'">
                <a class="nav-link" routerLinkActive="active" routerLink="my-test" (click)="closeMobileMenuOnClick()">MY TESTS</a>
            </li>
            <li role="nav-item" class="nav-item" *ngIf="userRole == 'student' && myOTJShow == 'show'">
                <a class="nav-link" routerLinkActive="active" routerLink="myotj" (click)="closeMobileMenuOnClick()">MY OTJ</a>
            </li>
            <li role="nav-item" class="nav-item" *ngIf="userRole == 'employer'">
                <a class="nav-link" routerLinkActive="active" routerLink="employer-myotj" (click)="closeMobileMenuOnClick()">MY OTJ</a>
            </li>
            <li role="nav-item" class="nav-item" *ngIf="userRole == 'student' && myAtLaBShow == 'show'">
                <a class="nav-link" routerLinkActive="active" routerLink="myatlab" (click)="closeMobileMenuOnClick()">MY AT LAB</a>
            </li>
            <li role="nav-item" class="nav-item" *ngIf="userRole == 'student' && myOTJShow == 'show'">
                <a class="nav-link" routerLinkActive="active" routerLink="myspr" (click)="closeMobileMenuOnClick()">MY SPR</a>
            </li>
            <li role="nav-item" class="nav-item" *ngIf="userRole == 'employer' && myOTJShow == 'show'">
                <a class="nav-link" routerLinkActive="active" routerLink="employer-myspr" (click)="closeMobileMenuOnClick()">MY SPR</a>
            </li>
            <li role="nav-item" class="nav-item" *ngIf="userRole == 'student' && checkPermission('my-work')">
                <a href="javascript:void(0)" class="nav-link" (click)="myWork('mobile-nav')">MY WORK</a>
            </li>
            <li role="nav-item" class="nav-item">
                <a href="javascript:void(0)" class="nav-link" (click)="profile()">MY PROFILE</a>
            </li>
            <li role="nav-item" class="nav-item">
                <a class="nav-link" routerLinkActive="active" routerLink="helpdesk">HELP DESK</a>
            </li>
            <li role="nav-item" class="nav-item d-lg-none">
              <a (click)="logout('mobile-nav')" href="javascript:void(0)" class="btn-1 text-white d-flex justify-content-center">SIGN OUT</a>
            </li>
          </ul>
      </div>
  </div>
</div>

<!--==============================
Header Area
==============================-->
<header class="vs-header header-layout1">
  <div class="sticky-wrapper">
      <div class="sticky-active">
          <!-- Main Menu Area -->
          <div class="header-inner py-3">
              <div class="container-fluid">
                  <div class="row align-items-center justify-content-between">
                      <div class="col-auto">
                          <div class="header-logo py-3 py-lg-0">
                            <a href="javascript:void(0)" [routerLink]="['/vle/home']"><img [attr.src]="tenantInfo?.logoUrl" /></a>
                          </div>
                      </div>
                      <div class="col-auto">
                          <div class="d-flex align-items-center justify-content-center">
                              <nav class="navbar navbar-nav order-last order-lg-0 main-menu menu-style1 d-none d-lg-block">
                                  <ul>
                                      <li class="dropdown nav-item ">
                                        <a class="nav-link" href="javacript:void(0)" routerLink="home">HOME</a>
                                      </li>
                                      <li class="dropdown nav-item" *ngIf="userRole != 'employer'">
                                          <a class="nav-link" href="javacript:void(0)" routerLink="my-courses">MY COURSES</a>
                                      </li>

                                      <li role="nav-item" class="nav-item" *ngIf="userRole != 'employer'">
                                          <a class="nav-link" routerLinkActive="active" routerLink="my-test">MY TESTS</a>
                                      </li>


                                      <li role="nav-item" class="nav-item" *ngIf="userRole == 'student' && myOTJShow == 'show'">
                                          <a class="nav-link" routerLinkActive="active" routerLink="myotj">MY OTJ</a>
                                      </li>

                                      <li role="nav-item" class="nav-item" *ngIf="userRole == 'employer'">
                                          <a class="nav-link" routerLinkActive="active" routerLink="employer-myotj">MY OTJ</a>
                                      </li>

                                      <li role="nav-item" class="nav-item" *ngIf="userRole == 'student' && myAtLaBShow == 'show'">
                                          <a class="nav-link" routerLinkActive="active" routerLink="myatlab">MY AT LAB</a>
                                      </li>
                                      <li role="nav-item" class="nav-item" *ngIf="userRole == 'student' && myOTJShow == 'show'">
                                          <a class="nav-link" routerLinkActive="active" routerLink="myspr">MY SPR</a>
                                      </li>

                                      <li role="nav-item" class="nav-item" *ngIf="userRole == 'employer' && myOTJShow == 'show'">
                                          <a class="nav-link" routerLinkActive="active" routerLink="employer-myspr">MY SPR</a>
                                      </li>

                                      <li role="nav-item" class="nav-item" *ngIf="userRole == 'student' && checkPermission('my-work')">
                                          <a href="javascript:void(0)" class="nav-link" (click)="myWork()">MY WORK</a>
                                      </li>

                                      <li class="nav-item" class="nav-item">
                                          <a href="javascript:void(0)" class="nav-link" (click)="profile()">MY PROFILE</a>
                                      </li>
                                      <li class="nav-item" class="nav-item">
                                          <a class="nav-link" routerLinkActive="active" routerLink="helpdesk">HELP DESK</a>
                                      </li>
                                      <li>
                                          <a (click)="logout()" href="javascript:void(0)" class="btn-1 scrollto p-3">SIGN OUT</a>
                                      </li>
                                  </ul>
                              </nav>
                              <button type="button" class="vs-menu-toggle d-inline-block d-lg-none">
                                <i class="fas fa-bars"></i>
                              </button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</header>
