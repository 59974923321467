<main class="inner-page">

    <!-- ======= Breadcrumbs Section ======= -->
    <section class="breadcrumb">
        <div class="container-fluid">
            <div class="d-flex justify-content-between align-items-center text-xs-center">
                <ul>
                    <li><a href="javascript:void(0)" routerLink="/">Home</a></li>
                    <li>Why Us</li>
                </ul>

            </div>
        </div>
    </section>
    <!-- End Breadcrumbs Section -->

    <!-- ======= Content Section ======= -->
    <section id="main">
        <div class="container-fluid">
            <div class="main-content">
                <div class="d-flex justify-content-between align-items-center">
                    <h1 class="page-header-title-1">Why Us</h1>
                </div>
                <hr class="hr" />
                <div class="my-profile">
                    <div class="row">
                        <div class="col-lg-9">
                            <h3 class="mb-3">Our Story</h3>
                            <p>{{tenantInfo?.name}} is a reliable provider of professional IT Qualifications and Digital IT Apprenticeships across London.</p>
                            <P>We are a niche and specialist training provider focused on delivering all of the IT and Digital standards by ensuring our trainings are of the highest quality, bespoke and tailored through a specialist team of passinate trainers
                                who have over 15 years of relevant industry experience.</P>
                            <P>Since 2013, in partnership with leading awarding bodies, we have been instrumental in helping individuals and businesses build Digital Skills and transform their performance.</P>
                            <P>We expanded our portfolio in 2018, to help employers recruit fresh talent and up-skill their staff to develop their own talented workforce by offering our most popular Digital IT apprenticeship programmes in partnership with
                                NESCOT, BCS, City & Guilds and the ESFA.</P>
                            <p>Our recent successful Matrix Accreditation strengthens our commitment to quality on IAG, We are fully accredited to ensure we provide the highest possible standards in our Apprenticeship Programmes by successfully joining the
                                Register of Apprenticeship Training Providers (ROATP).</p>
                            <h3 class="mb-3">Our Philosophy</h3>
                            <P>A commitment to empower our learners has helped {{tenantInfo?.name}} to grow from humble beginnings to now serve hundreds of students/individuals every year. Our passion for our Values, Learning & Growing, Teaching & Empowering,
                                Support & Happiness flow through everything we do.</P>
                            <h3 class="mb-3">Our Culture</h3>
                            <p>Our culture is powered by passion and happiness</p>
                            <h3 class="mb-3">Our Values</h3>
                            <div class="accordion " id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                 Quality and integrity
                                </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>We share knowledge openly and are remarkably transparent in everything that we do!</p>
                                            <P>At {{tenantInfo?.name}}, we are accountable to each other and to the Company’s Board. We favor autonomy and taking ownership of our actions. We always recommend the team use good judgment that defines what
                                                we do.</P>
                                            <P>The thing matters most is “RESULTS” (Are we making the positive Impact) -> Results matter more then 1. The number of hours we work. 2. Where we produce them. 3. How much Vacation we have taken.</P>
                                            <P>Our best results come when our Decisions that are Insight Driven and Data Powered – We LOVE DATA -> METRICS. We believe in Databases with Bigger Data rather then Bigger job Titles.</P>
                                            <P>By having better processes on safety, equality and diversity, compliance & a strong work ethic , we believe people are the core strength of our company. Our achievement of excellence is from our quality & reliability.</P>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingTwo">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                 Commitment to learners excellence
                                </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <P>DCL: Dedication and Commitment to Learners. We don’t want to satisfy them; we want to Delight them. Our Goal is to help them Succeed. For every Decision, we ask ourselves – ‘What is in it for the Learner?’</P>
                                            <p>We are on the right path as long as we Train the Learners and Exceed their Expectation. This defines our Dedication.</p>
                                            <p>We are Committed to Continuous learning and Self Improvement. We are committed to Delivering on our Promises. We do what we say we’ll do. We listen, We care & we Serve. We Innovate and constantly Improve our
                                                Systems and Processes.</p>
                                            <p>We keep our HEART + MIND open and aligned in order to keep learning and growing. With a long-term vision, we are Dedicated and Committed to our Learners.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                  Choose postivity, fun and happiness
                                </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>We believe in living True to our self with Passion and Purpose. Thinking, Saying & Doing in Harmony with consideration of Others. Communicating with Honesty, Respect & Dignity. We Strive to approach things in
                                                a Positive and Optimistic way. We avoid Criticising, Condemning Team Members and Learners. – Some times we love Constructive Criticism for Positivity in an Optimistic way among Team Members for FUN!</p>
                                            <p>We Have Fun and Think Full. We are Humble and Grateful. We Build Community and Meaningful Relationships.</p>
                                            <p>We believe in Playing as Team and in Aligning our Purposes, Values, Vision and Mission. This gives us a Sense of Fulfillment, which is an ultimate Job Satisfaction with a sense of serving the Community.</p>
                                            <p>We believe Happy people work more efficiently. We strive to keep our selves and our Learners Happy.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mb-3">
                                    <h2 class="accordion-header" id="headingFour">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    Commitment to both our mission and metrics productivity
                                  </button>
                                    </h2>
                                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>We love learning and growing. We love people who want to learn and grow, especially those who want to do better in their lives.</p>
                                            <p>OUR MISSION is to help individuals LEARN & GROW.</p>
                                            <p>We aspire to ENGAGE, EDUCATE and EMPOWER individuals for their better prospects. We believe, success is making who believed in you look brilliant !</p>
                                            <p>Our Commitment to our MISSION will help us earn the love of many of our learners and be the preferred training provider for IT courses in London. Our Commitment to our METRICS will help us earn the resources
                                                to further our MISSION. We are PASSIONATE about BOTH.</p>
                                        </div>
                                    </div>
                                </div>
                                <h3 class="mb-3">Our Vision</h3>
                                <p>{{tenantInfo?.name}} vision is to be the leading global IT training and consulting services firm, respected for our world-class professionals, and entrusted with our clients most critical business challenges.</p>
                                <h3 class="mb-3">Our Mission</h3>
                                <p>At {{tenantInfo?.name}}, OUR SIMPLE MISSION is to help individuals LEARN & GROW. We want to ENGAGE, EDUCATE and EMPOWER our learners to achieve their career potential and demonstrate technical proficiency with integrity
                                    and professionalism.</p>
                            </div>
                        </div>
                        <div class="col-lg-3 ">
                            <h3 class="mb-3">Excellent</h3>
                            <p>Based on 42 reviews
                              <!-- on <a href="https://www.trustpilot.com/review/abacademy.co.uk?utm_medium=trustbox&utm_source=List" target="_blank">Trustpilot</a> -->
                            </p>
                            <div class="whyus-col-right">
                                <div class="card mb-3">
                                    <div class="card-body">
                                        <p class="text-muted">Samia Tariq</p>
                                        <p>3 days ago</p>
                                        <p class="fw-bold">wonderful experience so far</p>
                                        <!-- <p><a href=javascript:void(0)>Read More</a></p> -->
                                    </div>
                                </div>
                                <div class="card mb-3">
                                    <div class="card-body">
                                        <p class="text-muted">Samia Tariq</p>
                                        <p>November 8</p>
                                        <p class="fw-bold">Amazing institute</p>
                                        <!-- <p><a href=javascript:void(0)>Read More</a></p> -->
                                    </div>
                                </div>
                                <div class="card mb-3">
                                    <div class="card-body">
                                        <p class="text-muted">Alex</p>
                                        <p>October 23</p>
                                        <p class="fw-bold">highly supportive Staff & great atmosphere</p>
                                        <p>Reply from {{tenantInfo?.name}} October 23</p>
                                        <!-- <p><a href=javascript:void(0)>Read More</a></p> -->
                                    </div>
                                </div>
                                <div class="card mb-3">
                                    <div class="card-body">
                                        <p class="text-muted">Victoria Janus</p>
                                        <p>October 22</p>
                                        <p class="fw-bold">They don't do their job</p>
                                        <p>Reply from {{tenantInfo?.name}} October 23</p>
                                        <!-- <p><a href=javascript:void(0)>Read More</a></p> -->
                                    </div>
                                </div>
                                <div class="card mb-3">
                                    <div class="card-body">
                                        <p class="text-muted">Madhurima</p>
                                        <p>April 4</p>
                                        <p class="fw-bold">Excellent coaching centre</p>
                                        <p>Reply from {{tenantInfo?.name}} April 14</p>
                                        <!-- <p><a href=javascript:void(0)>Read More</a></p> -->
                                    </div>
                                </div>
                                <div class="card mb-3">
                                    <div class="card-body">
                                        <p class="text-muted">Noby</p>
                                        <p>April 2</p>
                                        <p class="fw-bold">Excellent Academy I would definitely…</p>
                                        <p>Reply from {{tenantInfo?.name}} October 23</p>
                                        <!-- <p><a href=javascript:void(0)>Read More</a></p> -->
                                    </div>
                                </div>
                                <div class="card mb-3">
                                    <div class="card-body">
                                        <p class="text-muted">Sozy</p>
                                        <p>January 12</p>
                                        <p class="fw-bold">It is a good training and really helpful</p>
                                        <p>Reply from {{tenantInfo?.name}} October 23</p>
                                        <!-- <p><a href=javascript:void(0)>Read More</a></p> -->
                                    </div>
                                </div>
                                <div class="card mb-3">
                                    <div class="card-body">
                                        <p class="text-muted">Kaaha-Nuura</p>
                                        <p>January 12</p>
                                        <p class="fw-bold">I enjoyed the IT diploma programmes by Naema</p>
                                        <p>Reply from {{tenantInfo?.name}} October 23</p>
                                        <!-- <p><a href=javascript:void(0)>Read More</a></p> -->
                                    </div>
                                </div>
                                <div class="card mb-3">
                                    <div class="card-body">
                                        <p class="text-muted">Purvi Mehta</p>
                                        <p>January 12</p>
                                        <p class="fw-bold">The team is very friendly and willing</p>
                                        <p>Reply from {{tenantInfo?.name}} October 23</p>
                                        <!-- <p><a href=javascript:void(0)>Read More</a></p> -->
                                    </div>
                                </div>
                                <div class="card mb-3">
                                    <div class="card-body">
                                        <p class="text-muted">by M.Uddin</p>
                                        <p>January 11</p>
                                        <p class="fw-bold">Brilliant Service</p>
                                        <p>Reply from {{tenantInfo?.name}} October 23</p>
                                        <!-- <p><a href=javascript:void(0)>Read More</a></p> -->
                                    </div>
                                </div>
                                <div class="card mb-3">
                                    <div class="card-body">
                                        <p class="text-muted">Holly</p>
                                        <p>January 11</p>
                                        <p class="fw-bold">{{tenantInfo?.name}} - Friendly, Supportive and a Comfortable Environment!</p>
                                        <p>Reply from {{tenantInfo?.name}} October 23</p>
                                        <!-- <p><a href=javascript:void(0)>Read More</a></p> -->
                                    </div>
                                </div>
                                <div class="card mb-3">
                                    <div class="card-body">
                                        <p class="text-muted">Ruhni Bukhari</p>
                                        <p>November 2</p>
                                        <p class="fw-bold">One of the best academy with friendly staff</p>
                                        <p>Reply from {{tenantInfo?.name}} October 23</p>
                                        <!-- <p><a href=javascript:void(0)>Read More</a></p> -->
                                    </div>
                                </div>
                                <div class="card mb-3">
                                    <div class="card-body">
                                        <p class="text-muted">Ilan Lekerman</p>
                                        <p>November 2</p>
                                        <p class="fw-bold">Nothing bad so far</p>
                                        <!-- <p><a href=javascript:void(0)>Read More</a></p> -->
                                    </div>
                                </div>
                                <div class="card mb-3">
                                    <div class="card-body">
                                        <p class="text-muted">Jamie</p>
                                        <p>November 2</p>
                                        <p class="fw-bold">Top quality IT training from Top…</p>
                                        <!-- <p><a href=javascript:void(0)>Read More</a></p> -->
                                    </div>
                                </div>
                                <div class="card mb-3">
                                    <div class="card-body">
                                        <p class="text-muted">duntongreen2604</p>
                                        <p>April 29</p>
                                        <p class="fw-bold">goood</p>
                                        <p>Reply from {{tenantInfo?.name}} October 23</p>
                                        <!-- <p><a href=javascript:void(0)>Read More</a></p> -->
                                    </div>
                                </div>
                                <div class="card mb-3">
                                    <div class="card-body">
                                        <p class="text-muted">Anand</p>
                                        <p>April 20</p>
                                        <p class="fw-bold">ISTQB Manual Testing</p>
                                        <p>Reply from {{tenantInfo?.name}} October 23</p>
                                        <!-- <p><a href=javascript:void(0)>Read More</a></p> -->
                                    </div>
                                </div>
                                <div class="card mb-3">
                                    <div class="card-body">
                                        <p class="text-muted">swaroopa</p>
                                        <p>April 13</p>
                                        <p class="fw-bold">Very good for training and well…</p>
                                        <p>Reply from {{tenantInfo?.name}} October 23</p>
                                        <!-- <p><a href=javascript:void(0)>Read More</a></p> -->
                                    </div>
                                </div>
                                <div class="card mb-3">
                                    <div class="card-body">
                                        <p class="text-muted">by Raajh Sekaram</p>
                                        <p>April 11</p>
                                        <p class="fw-bold">Great IT courses and exceptional staff…</p>
                                        <p>Reply from {{tenantInfo?.name}} October 23</p>
                                        <!-- <p><a href=javascript:void(0)>Read More</a></p> -->
                                    </div>
                                </div>
                                <div class="card mb-3">
                                    <div class="card-body">
                                        <p class="text-muted">Sampa</p>
                                        <p>October 10</p>
                                        <p class="fw-bold">Very nice environment and excellent …</p>
                                        <p>Reply from {{tenantInfo?.name}} October 23</p>
                                        <!-- <p><a href=javascript:void(0)>Read More</a></p> -->
                                    </div>
                                </div>
                                <div class="card mb-3">
                                    <div class="card-body">
                                        <p class="text-muted">Kumar</p>
                                        <p>October 5</p>
                                        <p class="fw-bold">Great customer service</p>
                                        <p>Reply from {{tenantInfo?.name}} October 23</p>
                                        <!-- <p><a href=javascript:void(0)>Read More</a></p> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <!-- Content Section -->

</main>
<!-- End #main -->
