<div class="registration">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="header-wrapper">
                    <h1 class="page-header-title"> {{displayName}} Forgot Password
                    </h1>

                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row g-0">
            <div class="col-lg-6 col-left">
            </div>
            <div class="col-lg-6 bg-white">
                <div class="p-20">
                    <div class="section-header">
                        {{displayName}} Forgot Password
                    </div>
                    <div class="p-25">
                        <form [formGroup]="forgetPasswordForm">
                            <div class="mb-3">
                                <input type="email" class="form-control" placeholder="EMAIL" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.invalid }">
                                <div *ngIf="submitted && f.email.invalid" class="invalid-feedback mb-3">
                                    <div *ngIf="f.email.errors?.required">{{resourceData?.email_required}}</div>
                                    <div *ngIf="f.email.errors?.pattern">{{resourceData?.invalid_email_address}}</div>
                                </div>
                            </div>

                            <div class="row">

                                <div class="mb-3 text-xs-center">
                                    <input type="submit" class="btn-2" (click)="forgetPassword()" value="Send">
                                </div>
                            </div>
                        </form>
                        <hr />
                        <div class="d-flex justify-content-between">
                            <a class="a-link text-primary font-normal" [routerLink]="['/login', type]" href="javascript:void(0)">{{displayName}} Login</a>
                            <a class="a-link text-primary" [routerLink]="['/register']" href="javascript:void(0)"> Learner's Register </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>