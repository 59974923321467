<app-header></app-header>
<main class="inner-page row miny">

  <div class="col-md-4"></div>
  <div class="card col-md-4 kre card123">
    <div class="card-body">
      <h5 class="hea mb-3">Learner's Set Password</h5>
      <form class="container" [formGroup]="setPasswordForm" novalidate>
        <div class="row mb-3">
                  <div class="d-flex form-floating">
                      <input [attr.type]="eventChange" class="form-control boderChanges" id="floatingPassword" formControlName="password" placeholder="Password" [ngClass]="{ 'is-invalid': submitted && plf.password.invalid }" required>
                      <label for="floatingPassword" class="ml-10">Password</label>
                      <span class="input-group-text eyeBorderChanges" style="border-color: darkgray;" (click)="password_show_hide()">
                          <i [attr.class]="eyeIcon" id="show_eye"></i>
                      </span>
                  </div>
                  <div *ngIf="submitted && plf.password.invalid" class="invalid-feedback mb-3">
                    <div *ngIf="plf.password.errors?.required">{{resourceData.password_required}}</div>
                </div>
          </div>
        <div class="row mb-3">
              <div class="d-flex form-floating">
                      <input [attr.type]="eventConfirmChange" class="form-control boderChanges" id="floatingconfirmPassword" formControlName="confirmPassword" placeholder="confirm Password" [ngClass]="{ 'is-invalid': submitted && plf.confirmPassword.invalid }" required>
                      <label for="floatingconfirmPassword" class="ml-10">Confirm Password</label>
                      <span class="input-group-text eyeBorderChanges" style="border-color: darkgray;" (click)="confirm_password_show_hide()">
                          <i [attr.class]="eyeIcon1" id="show_eye1"></i>
                      </span>
              </div>
                <div *ngIf="submitted && plf.confirmPassword.invalid" class="invalid-feedback mb-3">
                  <div *ngIf="plf.confirmPassword.errors?.required">{{resourceData.confirmPassword_required}}</div>
                </div>
          </div>
        <div class="text-center">
          <ng-container *ngIf="!isLoading">

                <button type="button" class="btn btn-primary" type="submit" (click)="setPassword()">submit</button>
          </ng-container>
        </div>
      </form>
    </div>
  </div>
  <div class="col-md-4"></div>

</main>

<app-footer></app-footer>

