<div class="bg-menu w-100">
    <!-- ---------------------------------------------------------CRM Navbar or Menu Start------------------------------------------------------------------ -->

    <div class="row mx-2">
        <div class="col-12 col-md-12 col-sm-6 col-lg-12 d-flex align-items-center">
            <div>
                <a href="javascript:void(0);" data-bs-toggle="offcanvas" data-bs-target="#sidemenu" aria-controls="sidemenu" (click)="opensidenav()"> <i class="fa fa-bars" aria-hidden="true" style="color: #fff;">&nbsp;&nbsp;</i></a>
            </div>

            <ul class="header-menu nav menu-none">
                <li class="item mt-3" *ngIf="!isEnable">
                    <ngx-skeleton-loader count="10" animation="progress-dark" appearance="circle" [theme]="{
                        'border-radius': '5px',
                        height: '15px',
                        'background-color': '#616162',
                        width : '60px'
                      }"></ngx-skeleton-loader>
                </li>

                <li class="dropdown nav-item navitem" *ngIf="isEnable">
                    <a href="javascript:void(0);" class="nav-link px-0" [routerLink]="['dashboard']" (click)="setGlobalSearchEmpty()">
                        <span>Dashboard</span>
                    </a>
                </li>
                <li class="dropdown nav-item navitem" [routerLink]="['students']" *ngIf="validateMenuPrivilage(100,8) && isEnable">
                    <a href="javascript:void(0);" class="nav-link px-0" (click)="setGlobalSearchEmpty()">
                        <span>Students</span>
                    </a>
                </li>
                <li class="dropdown nav-item navitem" [routerLink]="['courses']" *ngIf="validateMenuPrivilage(101,8) && isEnable">
                    <a href="javascript:void(0);" class="nav-link px-0" (click)="setGlobalSearchEmpty()">
                        <span>Courses</span>
                    </a>
                </li>
                <li class="dropdown nav-item navitem" [routerLink]="['universities']" *ngIf="validateMenuPrivilage(102,8) && isEnable">
                  <a href="javascript:void(0);" class="nav-link px-0" (click)="setGlobalSearchEmpty()">
                      <span>Universities</span>
                  </a>
              </li>
                <li class="dropdown nav-item navitem" [routerLink]="['associate']" *ngIf="validateMenuPrivilage(105,8) && isEnable">
                    <a href="javascript:void(0);" class="nav-link px-0" (click)="setGlobalSearchEmpty()">
                        <span>Associates</span>
                    </a>
                </li>
                <li class="dropdown nav-item navitem" [routerLink]="['employee']" *ngIf="validateMenuPrivilage(106,8) && isEnable">
                    <a href="javascript:void(0);" class="nav-link px-0" (click)="setGlobalSearchEmpty()">
                        <span>Employees</span>
                    </a>
                </li>
                <li class="dropdown nav-item navitem" [routerLink]="['partner']" *ngIf="validateMenuPrivilage(107,8) && isEnable">
                    <a href="javascript:void(0);" class="nav-link px-0" (click)="setGlobalSearchEmpty()">
                        <span>Partners</span>
                    </a>
                </li>

                <li class="dropdown nav-item navitem" [routerLink]="['applications']" *ngIf="validateMenuPrivilage(103,8) && isEnable && layout >= LAYOUT.MD">
                    <a href="javascript:void(0);" class="nav-link px-0" (click)="setGlobalSearchEmpty()">
                        <span>Applications</span>
                    </a>
                </li>

                <li class="dropdown nav-item navitem" [routerLink]="['templates']" *ngIf="validateMenuPrivilage(108,8) && isEnable && layout >= LAYOUT.MD">
                    <a href="javascript:void(0);" class="nav-link px-0" (click)="setGlobalSearchEmpty()">
                        <span>Templates</span>
                    </a>
                </li>
                <li class="dropdown nav-item navitem" [routerLink]="['tasks']" *ngIf="validateMenuPrivilage(115,8) && isEnable && layout > LAYOUT.LG">
                    <a href="javascript:void(0);" class="nav-link px-0" (click)="setGlobalSearchEmpty()">
                        <span>Tasks</span>
                    </a>
                </li>
                <li class="dropdown nav-item navitem" [routerLink]="['downloadcenter']" *ngIf="validateMenuPrivilage(129,8) && isEnable && layout > LAYOUT.LG">
                    <a href="javascript:void(0);" class="nav-link px-0" (click)="setGlobalSearchEmpty()">
                        <span>Download Center</span>
                    </a>
                </li>
                <li class="dropdown nav-item navitem" [routerLink]="['swm']" *ngIf="validateMenuPrivilage(121,8) && isEnable && layout > LAYOUT.LG">
                    <a href="javascript:void(0);" class="nav-link px-0" (click)="setGlobalSearchEmpty()">
                        <span>SWM</span>
                    </a>
                </li>
                <li class="dropdown nav-item navitem" [routerLink]="['otj']" *ngIf="isEnable && layout > LAYOUT.LG">
                  <a href="javascript:void(0);" class="nav-link px-0" (click)="setGlobalSearchEmpty()">
                      <span>OTJ</span>
                  </a>
                </li>
                <li class="dropdown nav-item navitem" [routerLink]="['attendances']" *ngIf="validateMenuPrivilage(104,8) && isEnable && layout >= LAYOUT.XL">
                    <a href="javascript:void(0);" class="nav-link px-0" (click)="setGlobalSearchEmpty()">
                        <span>Attendances</span>
                    </a>
                </li>

                <li class="dropdown nav-item navitem" [routerLink]="['lessonplanlog']" *ngIf="validateMenuPrivilage(134,8) && isEnable  && layout >= LAYOUT.XL">
                    <a href="javascript:void(0);" class="nav-link px-0" (click)="setGlobalSearchEmpty()">
                        <span>Lesson Plan & Logs</span>
                    </a>
                </li>

                <li class="dropdown nav-item navitem" *ngIf="isEnable">
                    <a href="javascript:void(0);" class="nav-link px-0" (click)="setGlobalSearchEmpty('viewall')">
                        <span>View All</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <!-- ---------------------------------------------------------CRM Navbar or Menu End------------------------------------------------------------------ -->
</div>


<!-- ----------------------------------side nav bar --------------------------------------------- -->


<div class="offcanvas offcanvas-start side-navbar" tabindex="-1" id="sidemenu" aria-labelledby="sidemenuLabel">
    <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="sidemenuLabel">
            <div>
                <div class="d-flex justify-content-between align-items-center">
                    <a href="javascript:void(0)" class="nav-link h3 text-white my-2">
                        <img [src]="userDetails?.profile_picture !='' ? FILE_PATH_LINODE+'employee/'+userDetails.profile_picture : './assets/images/avatars/1.jpg'" class="rounded-circle w40" />
                    </a>
                    <div id="hmenu-customer-profile-right">
                        <div id="hmenu-customer-name">
                            <b class="text-white">Hello, <br>{{userDetails.name}}</b>
                        </div>
                    </div>
                </div>

            </div>
        </h5>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" (click)="closesidenav()"><i class="fas fa-times text-white"></i></button>
    </div>
    <div class="offcanvas-body">


        <ul class="nav flex-column text-white w-100 navtop">

            <li class="dropdown nav-item navitem" *ngIf="isEnable">
                <a href="javascript:void(0);" class="nav-link sidenav_height" [routerLink]="['dashboard']" (click)="setGlobalSearchEmpty()">
                    <span>Dashboard</span>
                </a>
            </li>
            <li class="dropdown nav-item navitem" *ngIf="validateMenuPrivilage(100,8) && isEnable">
                <a href="javascript:void(0);" class="nav-link sidenav_height" [routerLink]="['students']" (click)="setGlobalSearchEmpty()">
                    <span>Students</span>
                </a>
            </li>
            <li class="dropdown nav-item navitem" [routerLink]="['courses']" *ngIf="validateMenuPrivilage(101,8) && isEnable">
                <a href="javascript:void(0);" class="nav-link sidenav_height" (click)="setGlobalSearchEmpty()">
                    <span>Courses</span>
                </a>
            </li>
            <li class="dropdown nav-item navitem" [routerLink]="['universities']" *ngIf="validateMenuPrivilage(102,8) && isEnable">
                <a href="javascript:void(0);" class="nav-link sidenav_height" (click)="setGlobalSearchEmpty()">
                    <span>Universities</span>
                </a>
            </li>
            <li class="dropdown nav-item navitem" [routerLink]="['associate']" *ngIf="validateMenuPrivilage(105,8) && isEnable">
                <a href="javascript:void(0);" class="nav-link sidenav_height" (click)="setGlobalSearchEmpty()">
                    <span>Associates</span>
                </a>
            </li>
            <li class="dropdown nav-item navitem" [routerLink]="['employee']" *ngIf="validateMenuPrivilage(106,8) && isEnable">
                <a href="javascript:void(0);" class="nav-link sidenav_height" (click)="setGlobalSearchEmpty()">
                    <span>Employees</span>
                </a>
            </li>
            <li class="dropdown nav-item navitem" [routerLink]="['partner']" *ngIf="validateMenuPrivilage(107,8) && isEnable">
                <a href="javascript:void(0);" class="nav-link sidenav_height" (click)="setGlobalSearchEmpty()">
                    <span>Partners</span>
                </a>
            </li>
            <li class="dropdown nav-item navitem" [routerLink]="['applications']" *ngIf="validateMenuPrivilage(103,8) && isEnable">
                <a href="javascript:void(0);" class="nav-link sidenav_height" (click)="setGlobalSearchEmpty()">
                    <span>Applications</span>
                </a>
            </li>

            <li class="dropdown nav-item navitem" [routerLink]="['templates']" *ngIf="validateMenuPrivilage(108,8) && isEnable">
                <a href="javascript:void(0);" class="nav-link sidenav_height" (click)="setGlobalSearchEmpty()">
                    <span>Templates</span>
                </a>
            </li>
            <li class="dropdown nav-item navitem" [routerLink]="['tasks']" *ngIf="validateMenuPrivilage(115,8) && isEnable">
                <a href="javascript:void(0);" class="nav-link sidenav_height" (click)="setGlobalSearchEmpty()">
                    <span>Tasks</span>
                </a>
            </li>
            <li class="dropdown nav-item navitem" [routerLink]="['downloadcenter']" *ngIf="validateMenuPrivilage(129,8) && isEnable">
                <a href="javascript:void(0);" class="nav-link sidenav_height" (click)="setGlobalSearchEmpty()">
                    <span>Download Center</span>
                </a>
            </li>
            <li class="dropdown nav-item navitem" [routerLink]="['swm']" *ngIf="validateMenuPrivilage(121,8) && isEnable">
                <a href="javascript:void(0);" class="nav-link sidenav_height" (click)="setGlobalSearchEmpty()">
                    <span>SWM</span>
                </a>
            </li>
            <li class="dropdown nav-item navitem" [routerLink]="['otj']" *ngIf="isEnable">
              <a href="javascript:void(0);" class="nav-link sidenav_height" (click)="setGlobalSearchEmpty()">
                  <span>OTJ</span>
              </a>
            </li>
            <li class="dropdown nav-item navitem" [routerLink]="['attendances']" *ngIf="validateMenuPrivilage(104,8) && isEnable">
                <a href="javascript:void(0);" class="nav-link sidenav_height" (click)="setGlobalSearchEmpty()">
                    <span>Attendances</span>
                </a>
            </li>
            <li class="dropdown nav-item navitem" [routerLink]="['lessonplanlog']" *ngIf="validateMenuPrivilage(134,8) && isEnable">
                <a href="javascript:void(0);" class="nav-link sidenav_height" (click)="setGlobalSearchEmpty()">
                    <span>Lesson Plan & Logs</span>
                </a>
            </li>

            <li class="dropdown nav-item navitem" [routerLink]="['myworktask']" *ngIf="validateMenuPrivilage(132,8) && isEnable">
                <a href="javascript:void(0);" class="nav-link sidenav_height" (click)="setGlobalSearchEmpty()">
                    <span>My Work Tasks</span>
                </a>
            </li>

            <li class="dropdown nav-item navitem" [routerLink]="['mainmywork']" *ngIf="validateMenuPrivilage(131,8) && isEnable">
                <a href="javascript:void(0);" class="nav-link sidenav_height " (click)="setGlobalSearchEmpty()">
                    <span>My Works</span>
                </a>
            </li>

            <li class="dropdown nav-item navitem" [routerLink]="['events']" *ngIf="validateMenuPrivilage(109,8) && isEnable">
                <a href="javascript:void(0);" class="nav-link sidenav_height" (click)="setGlobalSearchEmpty()">
                    <span>Events</span>
                </a>
            </li>
            <li class="dropdown nav-item navitem" [routerLink]="['subjects']" *ngIf="validateMenuPrivilage(110,8) && isEnable">
                <a href="javascript:void(0);" class="nav-link sidenav_height" (click)="setGlobalSearchEmpty()">
                    <span>Subjects</span>
                </a>
            </li>
            <li class="dropdown nav-item navitem" [routerLink]="['appointments']" *ngIf="validateMenuPrivilage(116,8) && isEnable">
                <a href="javascript:void(0);" class="nav-link sidenav_height" (click)="setGlobalSearchEmpty()">
                    <span>Appointments</span>
                </a>
            </li>
        </ul>
    </div>
</div>
