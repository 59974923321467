import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { CourseService } from 'src/app/services/course.service';
import { UserService } from 'src/app/services/userservice.service';
import { APP_URLS, TenantId } from 'src/app/Utils/general';
import * as ResourceJson from '../../Utils/crm-resource.json';

declare let $: any;
@Component({
  selector: 'app-individual-course',
  templateUrl: './individual-course.component.html',
  styleUrls: ['./individual-course.component.css'],
})
export class IndividualCourseComponent implements OnInit, OnDestroy {
  enquiryloginForm = new FormGroup({});
  resourceData: any = (ResourceJson as any).default;
  userDetails: any;
  TrainingName: any;
  submitted = false;
  title: any;
  evVideo: any;
  CourseEnuiry: any = {};
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public service: CourseService,
    private userSvc: UserService,
    private toastr: ToastrService,
    private cms: CommonService,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
    if (localStorage.getItem('token')) {
      this.userDetails = localStorage.getItem('userDetails');
      this.studentID = JSON.parse(this.userDetails)._id;
      this.activeToken = true;
    } else {
      this.activeToken = false;
    }

    this.route.params.subscribe((params) => {
      this.coursetype = params.id;
      localStorage.setItem('courseSlug', this.coursetype);
      this.getList();
    });
  }
  public removeEventListener: (() => void) | undefined;
  courselist = {
    name: '',
    fullName: '',
    general_duration: '',
    coursefrontview: '',
    introduction: '',
    Favouritebutton: '',
    Applybutton: '',
    _id: '',
    course_type: ''
  };
  errorpage: any;
  FILE_PATH_LINODE = APP_URLS.FILE_PATH_LINODE;
  bannerImg: any;
  coursetype = '';
  showDialogReg = false;
  courseID = '';
  @ViewChild('f') form: any;
  // evVideo: any
  studentID: any;
  openregister = false;
  activeToken = false;
  CartList: any = [];
  FavList: any = [];
  course: any;
  innerhtmldata: any;
  ID: any;
  Chapters: any = [];
  isUserRole: string;

  getListData: any = [];

  FinalChaptersArray: any = [];

  ngOnInit() {
    this.enquiryloginForm = new FormGroup({
      fname: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z ]*$/),
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
        Validators.maxLength(40),
      ]),
      lname: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z ]*$/),
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
        Validators.maxLength(40),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/),
      ]),
      phone: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[0-9]*$/),
        Validators.maxLength(16),
        Validators.minLength(5),
      ]),
    });

    this.errorpage = window.location.pathname;
    this.removeEventListener = this.renderer.listen(
      this.elementRef.nativeElement,
      'click',
      (event) => {
        if (event.target instanceof HTMLAnchorElement) {
          this.handleAnchorClick(event);
        }
      }
    );
    this.isUserRole = localStorage.getItem('role');
    // this.getList();
  }
  /**
   * get form controls
   */
  get f() {
    return this.enquiryloginForm.controls;
  }

  async getList() {
    this.cms.loaderSet(true);
    const url =
      APP_URLS.CourscesList +
      '{"criteria":[{"key":"tenantId","value":"' +
      TenantId +
      '","type":"eq"},{"key":"slug","value":"' +
      this.coursetype +
      '","type":"eq"}]}';
    await this.userSvc.getCallWithHeaders(url).subscribe(
      (res: any) => {
        this.getListData = res.all;
        const courseis = res.all[0];
        this.courselist.name = res.all[0]?.name;
        this.courselist._id = res.all[0]?._id;
        this.courselist.fullName = res.all[0]?.fullName;
        this.courselist.course_type = res.all[0]?.course_type;
        if (courseis) {
          if (this.FavList.includes(courseis?._id)) {
            courseis.infav = true;
          } else {
            courseis.infav = false;
          }
        }
        this.course = courseis;
        this.evVideo = this.sanitizer.bypassSecurityTrustHtml(courseis?.evideo);
        if (
          courseis?.introduction.includes('http://appliedbusinessacademy.co.uk')
        ) {
          courseis.introduction = courseis?.introduction.replaceAll(
            'http://appliedbusinessacademy.co.uk',
            '.'
          );
        }

        this.innerhtmldata = this.sanitizer.bypassSecurityTrustHtml(
          courseis?.introduction
        );

        setTimeout(() => {
          $('#carouselExampleControls').css('position', 'relative');
        }, 100);

        this.courseID = courseis?._id;
        this.cms.loaderSet(false);
      },
      (error) => {
        this.cms.loaderSet(false);
      }
    );
  }

  public ngOnDestroy() {
    if (this.removeEventListener) {
      this.removeEventListener();
    }
    localStorage.removeItem('coursetype');
    localStorage.removeItem('courseSlug');
  }

  public handleAnchorClick(event: Event) {
    event.preventDefault();
    const anchor = event.target as HTMLAnchorElement;
    const baseurl = anchor.baseURI;
    if (anchor.href === baseurl + '#addasfav') {
      if (this.activeToken) {
        const url = APP_URLS.Courses + '/' + this.courseID + '?favorite=true';
        this.userSvc.putCallwithHeaders(url, {}).subscribe(
          (data: any) => {
            this.userSvc.showSuccess(data.respMessage);
            this.getList();
          },
          (err) => {}
        );
      } else {
        this.service.selectedcourse(this.courselist._id, 'favourite');
        this.openregister = true;
      }
    }

    if (anchor.href === baseurl + '#apply') {
      if (this.activeToken) {
        const url =
          APP_URLS.Courses + '/' + this.courseID + '?applycourse=true';

        this.userSvc.putCallwithHeaders(url, {}).subscribe(
          (data: any) => {
            this.userSvc.showSuccess(data.respMessage);
            this.getList();
          },
          (err) => {}
        );
      } else {
        this.service.selectedcourse(this.courselist._id, 'apply');
        this.openregister = true;
      }
    }

    if (anchor.href === baseurl + '#book') {
      if (this.activeToken) {
        this.router.navigate(['vle/profile']);
      } else {
        this.openregister = true;
      }
    }
  }
  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  bookAppointment() {
    if (this.studentID) {
      localStorage.setItem('bookinterview', 'true');
      const role = localStorage.getItem('role');
      if (role === 'student') {
        this.router.navigate(['vle/profile']);
      }
    } else {
      // this.router.navigate(['/' + 'register']);
      this.userSvc.showError('Please register or login to continue');
    }
  }

  enquiry() {
    this.submitted = true;
    if (this.enquiryloginForm.invalid) {
      return;
    }
    const payload = {
      first_name: this.enquiryloginForm.value.fname,
      last_name: this.enquiryloginForm.value.lname,
      email: this.enquiryloginForm.value.email,
      mobile: this.enquiryloginForm.value.phone,
      course_id: this.courselist._id,
      query: this.courselist.fullName,
      tenantId: TenantId,
      from_place: 'course enquiry',
    };
    const url = APP_URLS.student;
    this.userSvc.postCall(url, payload).subscribe(
      (res: any) => {
        if (res.respCode) {
          this.userSvc.showSuccess(res.respMessage);
          this.enquiryloginForm.reset();
          this.submitted = false;
        } else {
          this.userSvc.showError(res.respMessage);
          this.submitted = false;
        }
      },

      (error) => {
        this.cms.loaderSet(false);
        this.userSvc.showError('contact Failed');
      }
    );
  }

  AddApply(data: any) {
    if (this.studentID) {
      const url = APP_URLS.Courses + '/' + data._id + '?applycourse=true';
      this.userSvc.putCallwithHeaders(url, {}).subscribe(
        (data1: any) => {
          this.userSvc.showSuccess(data1.respMessage);
          this.getList();
        },
        (err) => {}
      );
    } else {
      this.userSvc.showError('Please register or login to continue');
      // this.service.selectedcourse(data._id, "apply")
      // this.showDialogReg = true;
    }
  }

  AddFav(data: any) {
    // let url = APP_URLS.applyAndFavourate + data._id + '?favorite=true';

    if (this.studentID) {
      const favarry = [];
      const url = APP_URLS.favmulti;
      favarry.push(data._id);
      const payload = {
        courseids: favarry,
        target: 'add',
      };
      this.userSvc.putCall(url, payload).subscribe(
        (data1: any) => {
          if (data1.suc_arr.length > 0) {
            this.userSvc.showSuccess(data1.suc_arr[0]);
          }
          if (data1.err_arr.length > 0) {
            this.userSvc.showError(data1.err_arr[0]);
          }
          this.getList();
        },
        (err) => {}
      );
    } else {
      // this.userservice.showError('Please register or login to continue');
      // this.service.selectedcourse(data._id, "favourite")
      // this.showDialogReg = true;
      // this.addtofavLocalStorage(data)
      this.userSvc.showError('Please register or login to continue');
    }
  }

  openCourse(Training: any) {
    localStorage.setItem('courseId', Training._id);
    localStorage.setItem('TrainingName', Training.name);
    localStorage.setItem('uuid', Training.resumeChapter);
    if (Training.apply_status === 'assigned' && Training.resumeChapter === '') {
      this.router.navigate(['/Mycourses/', Training._id]);
    }
    if (Training.apply_status === 'assigned' && Training.resumeChapter !== '') {
      this.ID = localStorage.getItem('courseId');
      const url =
        APP_URLS.newchapters +
        '{"criteria":[{"key":"tenantId","value":"' +
        TenantId +
        '","type":"eq"},{"key":"course","value":"' +
        this.ID +
        '","type":"eq"},{"key": "applystatus","value":"'+ true +'"}]}';
      this.userSvc.getCallWithHeaders(url).subscribe(
        (res: any) => {
          this.Chapters = res.cou_overview;
          this.setChaptersData();
        },
        (err) => {}
      );
    }
  }

  async setChaptersData() {
    await this.Chapters.forEach((x: any, i: any) => {
      x.accordion = '#accordion' + i;
      x.accordionID = 'accordion' + i;
      x.child.forEach((y: any, index: any) => {
        y.childaccordion = '#childaccordion' + index;
        y.childaccordionID = 'childaccordion' + index;
        if (y.chapter_type === 'lesson') {
          y.icon = 'fa fa-book';
        }
        if (y.chapter_type === 'assignment') {
          y.icon = 'fa fa-code';
        }
        if (y.chapter_type === 'downloads') {
          y.icon = 'fa fa-cloud-download-alt';
        }
        if (y.chapter_type === 'test' || y.chapter_type === 'qatest') {
          y.icon = 'fa fa-pencil-alt';
        }
        if (y.chapter_type === 'quiz') {
          y.icon = 'fa fa-graduation-cap';
        }
        if (y.chapter_type === 'chapter') {
          y.icon = 'fa fa-bars';
        }
      });
      this.FinalChaptersArray.push(x);
      localStorage.setItem(
        'AllChapters',
        JSON.stringify(this.FinalChaptersArray)
      );
      const Trainingid = localStorage.getItem('courseId');
      this.TrainingName = localStorage.getItem('TrainingName');
      const resumeChapter = localStorage.getItem('uuid');
      this.router.navigate([
        '/Mychapter/',
        Trainingid + '-' + resumeChapter,
        this.TrainingName.replace(/\s/g, '-'),
      ]);
    });
  }

  removeFav(course: any) {
    if (this.studentID) {
      const favarry = [];
      const url = APP_URLS.favmulti;
      favarry.push(course._id);
      const payload = {
        courseids: favarry,
        target: 'remove',
      };
      this.userSvc.putCall(url, payload).subscribe(
        (data: any) => {
          if (data.suc_arr.length > 0) {
            this.userSvc.showSuccess(data.suc_arr[0]);
          }
          if (data.err_arr.length > 0) {
            this.userSvc.showError(data.err_arr[0]);
          }
          this.getList();
        },
        (err) => {}
      );
    } else {
      this.FavList.forEach((x: any, i: any) => {
        if (x === course._id) {
          this.FavList.splice(i, 1);
          const data = this.FavList;
          if (data.length > 0) {
            localStorage.setItem('favdata', data.toString());
          }
          if (data.length === 0) {
            localStorage.removeItem('favdata');
          }

          this.userSvc.showSuccess('Removed from Favourite List');
          this.getList();
        }
      });
    }
  }

  // transformUrl(url: any){
  //   return ;
  // }
}
