import { Subscriber, Subscription } from 'rxjs';
import { SettingsService } from './../../../services/settings.service';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/userservice.service';
import { APP_CRM_URLS } from 'src/app/Utils/crm';
import { mainPermissions } from 'src/app/Utils/permissions';
import { APP_URLS, tenantConfig } from 'src/app/Utils/general';
import * as ResourceJson from '../../../Utils/crm-resource.json';
import { DOCUMENT } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-crm-header',
  templateUrl: './crm-header.component.html',
  styleUrls: ['./crm-header.component.css'],
})
export class CrmHeaderComponent implements OnInit, OnDestroy {
  isLoading = false;
  isAddStudentPopupOpen = false;
  searchList: any[] = [];
  userDetails: any = [];
  logoId: any;
  notificationData: any;
  isOpenNotification = false;
  inboxesData: any;
  faviIconImage: any;
  FILE_PATH_LINODE = APP_URLS.FILE_PATH_LINODE;
  resourceData: any = (ResourceJson as any).default; // used for read resource file
  tenantLogo = tenantConfig.logoUrl;
  keyword = 'first_name';
  filter: any;
  logoImage: any;
  searchType: any;
  searchTypeLabel = 'Student';
  searchData = '';
  logo$: Subscription;
  permissionDetails: any = [];
  permissionInfo$: Subscription;
  permissions: any = {};
  selectedType: string;
  isAssociate = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private settingsservice: SettingsService,
    @Inject(DOCUMENT) private document
  ) {
    // tslint:disable-next-line:prefer-const
    this.userService.loggedUserDetails.subscribe((user: any) => {
      if (user) {
        this.userDetails = user;
      }
    });
    this.userService.globalSearch.subscribe((res) => {
      this.searchData = '';
    });

    this.userService.getNotification.subscribe((res: any) => {
      if (res?.value === 'notify' && Object.keys(res)?.length) {
        this.notificationData = res?.res;
      } else if (res?.value === 'inbox' && Object.keys(res)?.length) {
        this.inboxesData = res?.res;
      }
    });

    /**
     * Get permission data from local object
     */
    this.permissionInfo$ = this.userService.userPermission.subscribe((data: any) => {
      this.permissionDetails = data;
    });
  }

  ngOnInit(): void {
    this.getlogoImg();
    this.logo$ = this.settingsservice.getlogoimage().subscribe((data: any) => {
      this.tenantLogo = this.FILE_PATH_LINODE + 'tenant/' + data;
    });
    const getUserDetails: any = localStorage.getItem('userDetails');
    this.userDetails = JSON.parse(getUserDetails);
    this.getTenantGroupSetting();
    this.getemployeeDetails();
    this.getTenantSetting();
    this.getNotificationCount();
    this.getInboxcount();
  }

  /**
   * Permission for detais menu
   */
   validateMenuPrivilage(key1: any) {
    if (this.userDetails?.role?.value.toLowerCase() === 'admin') {
      if (this.permissionDetails[key1] && this.permissionDetails[key1] === true) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  validateMenuPrivilages(key1: any) {
    if (this.userDetails?.role?.value.toLowerCase() === 'admin') {
      // tslint:disable-next-line:max-line-length
      if ((this.permissionDetails[key1] && this.permissionDetails[key1][8] && this.permissionDetails[key1][8] === true) || (this.permissionDetails[key1] && this.permissionDetails[key1][9] && this.permissionDetails[key1][9] === true)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }


  getTenantSetting(): void {
    const url = APP_URLS.tenantSettings;
    this.userService.getCallWithHeaders(url).subscribe((res: any) => {
      if (res?.details?.length > 0) {
        localStorage.setItem('TenantSetting', JSON.stringify(res?.details[0]));
        this.getTenantPermission();
      }
    });
  }

  selectData(data: any){
    if (data?.target?.value === 'associate'){
      this.isAssociate = true;
      this.searchType = 'employee';
    }else{
      this.isAssociate = false;
      this.searchType = data?.target?.value;
    }
    this.searchList = [];
  }

  generatePermissionData(tenantPermission: any): void {
    const tenantSettings = JSON.parse(localStorage.getItem('TenantSetting'));
    this.permissions = {};
    if (mainPermissions && this.userDetails?.role?.value === 'Super admin') {
      mainPermissions.forEach((val: any) => {
        if (val === 'main_tabs') {
          val.forEach((val1: any) => {
            this.permissions[val1.key] = { 9: true, 8: true };
          });
        } else {
          val.forEach((value1: any) => {
            this.permissions[value1.key] = true;
          });
        }
      });
    } else if (mainPermissions && this.userDetails?.role?.value.toLowerCase() === 'admin') {
      if (tenantPermission?.permissions?.length > 0) {
        tenantPermission?.permissions.forEach((v: any) => {
          if (
            mainPermissions.main_tabs.findIndex((x: any) => x.key === v) > -1
          ) {
            this.permissions[v] = { 9: true, 8: true };
          } else {
            this.permissions[v] = true;
          }
        });
      }
    } else {
      // other tabs permissions using tenantSettings.permissions
      if (tenantSettings?.permissions?.length > 0) {
        tenantSettings.permissions.forEach((v: any) => {
          tenantPermission?.permissions.forEach((element: any) => {
            if (element === v) {
              if (
                mainPermissions?.main_tabs.findIndex((x: any) => {
                  // tslint:disable-next-line:no-unused-expression
                  x.key === v;
                }) > -1
              ) {
                this.permissions[v] = true;
              }
            }
          });
        });
      }

      // role based all tabs permissions except entities
      if (
        tenantSettings?.role_permissions &&
        this.userDetails?.role?.value &&
        tenantSettings.role_permissions[this.userDetails?.role?.value]
      ) {
        const rolePermissions =
          tenantSettings.role_permissions[this.userDetails?.role?.value];

        if (rolePermissions && rolePermissions.length > 0) {
          this.permissions = {};
          rolePermissions.forEach((v: any) => {
            if (
              tenantPermission?.permissions.findIndex((x: any) => x === v) > -1
            ) {
              this.permissions[v] = true;
            }
          });
        }
      }

      // employee based all tabs permissions except entities
      if (this.userDetails?.role_permissions?.length > 0) {
        const employeePermissions = this.userDetails?.role_permissions;
        if (employeePermissions && employeePermissions.length > 0) {
          this.permissions = {};
          employeePermissions.forEach((v: any) => {
            if (
              tenantPermission?.permissions.findIndex((x: any) => x === v) > -1
            ) {
              this.permissions[v] = true;
            }
          });
        }
      }

      // other employees permissions
      // Permission role based check
      if (tenantSettings && tenantSettings.tab_permissions) {
        tenantSettings.tab_permissions.forEach((v: any) => {
          if (
            v?.permissions &&
            this.userDetails?.role?.value &&
            v.role === this.userDetails?.role?.value
          ) {
            Object.keys(v.permissions).forEach((v1: any) => {
              // permission check for role based permissions
              if (tenantPermission?.permissions.length > 0) {
                this.permissions[v1] = {};

                if (v.permissions[v1] === '9') {
                  this.permissions[v1][v.permissions[v1]] = true;
                  this.permissions[v1]['8'] = true;
                }
                if (v.permissions[v1] === '8') {
                  this.permissions[v1][v.permissions[v1]] = true;
                }
                if (v.permissions[v1] === '7') {
                  this.permissions[v1][v.permissions[v1]] = true;
                }
              }
            });
            return;
          }
        });
      }

      // Employee level permission based check
      if (this.userDetails?.tab_permissions) {
        Object.keys(this.userDetails?.tab_permissions).forEach((v1: any) => {
          if (tenantPermission?.permissions.length > 0) {
            if (!this.permissions[v1]) {
              this.permissions[v1] = {};
            }

            if (this.userDetails?.tab_permissions[v1] === '9') {
              this.permissions[v1][this.userDetails?.tab_permissions[v1]] =
                true;
              this.permissions[v1]['8'] = true;
            }
            if (this.userDetails?.tab_permissions[v1] === '8') {
              this.permissions[v1]['9'] = false;
              this.permissions[v1][this.userDetails?.tab_permissions[v1]] =
                true;
            }
            if (this.userDetails?.tab_permissions[v1] === '7') {
              this.permissions[v1]['9'] = false;
              this.permissions[v1]['8'] = false;
              this.permissions[v1][this.userDetails?.tab_permissions[v1]] =
                true;
            }
          }
        });
      }
    }
    this.userService.setUserPermission(this.permissions);
    this.initalPermission();
  }

  initalPermission(){
    /**
     * Get permission data from local object
     */
     this.permissionInfo$ = this.userService.userPermission.subscribe((data: any) => {
      this.permissionDetails = data;
    });

    // tslint:disable-next-line:max-line-length
     if ((this.permissionDetails[100] && this.permissionDetails[100][8] === true) || (this.permissionDetails[100] && this.permissionDetails[100][9] === true)){
      this.searchType = 'student';
      this.isAssociate = false;
    // tslint:disable-next-line:max-line-length
    }else if (((this.permissionDetails[101] && this.permissionDetails[101][8] === true) || (this.permissionDetails[101] && this.permissionDetails[101][9] === true)) && !this.permissionDetails[100]){
      this.searchType = 'course';
      this.isAssociate = false;
    // tslint:disable-next-line:max-line-length
    }else if (((this.permissionDetails[105] && this.permissionDetails[105][8] === true) || (this.permissionDetails[105] && this.permissionDetails[105][9] === true)) && !this.permissionDetails[101] && !this.permissionDetails[100]){
      this.searchType = 'employee';
      this.isAssociate = true;
    // tslint:disable-next-line:max-line-length
    }else if (((this.permissionDetails[106] && this.permissionDetails[106][8] === true) || (this.permissionDetails[106] && this.permissionDetails[106][9] === true)) && !this.permissionDetails[105] && !this.permissionDetails[101] && !this.permissionDetails[100]){
      this.searchType = 'employee';
      this.isAssociate = false;
    // tslint:disable-next-line:max-line-length
    }else if (((this.permissionDetails[107] && this.permissionDetails[107][8] === true) || (this.permissionDetails[107] && this.permissionDetails[107][9] === true)) && !this.permissionDetails[106] && !this.permissionDetails[105] && !this.permissionDetails[101] && !this.permissionDetails[100]){
      this.searchType = 'contact';
      this.isAssociate = false;
    }
     this.filter = { queryString: '', entityTypes: [this.searchType] };
  }

  getTenantPermission(): void {
    const url = APP_URLS.tenantPermission;
    this.userService.getCallWithHeaders(url).subscribe((res: any) => {
      if (res?.details?.length > 0) {
        localStorage.setItem(
          'tenantPermission',
          JSON.stringify(res?.details[0])
        );
        this.generatePermissionData(res?.details[0]);
      }
    });
  }

  goToProfile() {
    if (this.userDetails.role.value === 'Agent') {
      this.userService.setUserDetails({});
      this.router.navigate(['crm/associate/' + this.userDetails._id]);
    } else if (this.userDetails.role.value !== 'employee') {
      this.userService.setUserDetails({});
      this.router.navigate(['crm/employee/' + this.userDetails._id]);
    }
  }

  getlogoImg() {
    const payload = {
      host_name: tenantConfig?.siteUrl,
    };
    const url = APP_CRM_URLS.tenantData;
    this.userService.postCallwithHeaders(url, payload).subscribe(
      (res: any) => {
        if (res?.details?.length !== 0) {
          this.logoImage = res?.details?.logo;
          this.settingsservice.setlogoimage(res?.details?.logo);
          this.faviIconImage = res?.details?.favicon;
          this.settingsservice.setFavIcon(res?.details?.favicon);
          this.logoId = res?.details?._id;
          localStorage.setItem('tenantDetails', JSON.stringify(res?.details));
        } else {
          this.userService.showError(res.message);
        }
      },
      (err) => {
        this.userService.showError(this.resourceData.server_error);
      }
    );
  }

  getAddStudentPrivillage() {
    if (
      this.permissions &&
      this.permissions[100] &&
      this.permissions[100]['9'] &&
      this.permissions[100]['9'] === true
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Get employee details
   */
  getemployeeDetails(): void {
    const url = APP_CRM_URLS.v1.employees + '/' + this.userDetails._id;
    this.userService.getCallWithHeaders(url).subscribe((res: any) => {
      if (res?.details) {
        localStorage.setItem('userDetails', JSON.stringify(res?.details));
        this.userService.setLoggedUserDetails(res?.details);
      }
    });
  }

  logout(): void {
    localStorage.clear();
    // this.document
    //   .getElementById('main-style-sheet')
    //   ?.setAttribute('href', './assets/css/styles.css');
    // this.document
    //   .getElementById('common-style-sheet')
    //   ?.setAttribute('href', './assets/css/common.css');
    // this.document
    //   .getElementById('topbar-style-sheet')
    //   ?.setAttribute('href', './assets/css/top_bar.css');
    // this.document
    //   .getElementById('font1')
    //   ?.setAttribute(
    //     'href',
    //     'https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap'
    //   );
    // this.document
    //   .getElementById('font2')
    //   ?.setAttribute(
    //     'href',
    //     'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap'
    //   );
    setTimeout(() => {
      this.router.navigate(['/']);
    }, 100);
  }

  getTenantGroupSetting(): void {
    const url = APP_URLS.groupedList;
    this.userService.getCallWithHeaders(url).subscribe((results: any) => {
      const lookUpData = {};
      if (results?.data?.length > 0) {
        results?.data.forEach((value, key) => {
          const category = {};
          category[value?.type] = {};
          if (lookUpData[value?.type]) {
            lookUpData[value?.type][value?.key] = value;
          } else {
            if (!lookUpData[value?.type]) {
              lookUpData[value?.type] = {};
            }
            lookUpData[value?.type][value?.key] = value;
          }
        });
        localStorage.setItem('lookUpDataGaipp', JSON.stringify(lookUpData));
      }
    });
  }

  /**
   * Complete Qualification task
   */
  completedAddStudent(data: any): void {
    this.isAddStudentPopupOpen = false;
    $('#closeaddStudentPopup').click();
    if (this.router.url.indexOf('students') > -1) {
      this.userService.setNewStudent(data);
    }
  }

  /**
   * Select value from global search
   */
  selectEvent(data: any) {
    // this.router.navigate(['crm/student/' + data._id]);
    if (this.searchType === 'student'){
      this.router.navigate(['crm/student/' + data._id]);
    }else if (this.searchType === 'course'){
      this.router.navigate(['crm/course/' + data._id + '/details']);
    }else if (this.searchType === 'institution'){
      this.router.navigate(['crm/university/' + data._id + '/details']);
    }else if (this.searchType === 'employee' && this.isAssociate === false){
      this.router.navigate(['crm/employee/' + data._id]);
    }else if (this.searchType === 'employee' && this.isAssociate === true){
      this.router.navigate(['crm/associate/' + data._id]);
    }
    else if (this.searchType === 'contact'){
      this.router.navigate(['crm/partner/' + data._id]);
    }else{}
  }

  /**
   * Get employee name & id oninput change in search box
   */
  onChangeSearch(e): any {
    if (e === '') {
      return;
    }
    this.filter = {
      queryString: e,
      entityTypes: [this.searchType],
    };
    const url =
      APP_CRM_URLS.search + '?powerSearch=' + JSON.stringify(this.filter);
    this.userService.getCallWithHeaders(url).subscribe((res: any) => {
      if (res?.result) {
        // this.searchList = res?.result?.students.map((data) => {
        //   data.first_name = `${data.first_name} ${data.last_name}, ${data.mobile}, ${data.email}`;
        //   return data;
        // });
        if (this.searchType === 'student'){
          this.searchList = res?.result?.students.map((data) => {
            data.first_name = `${data.first_name} ${data.last_name}, ${data.mobile}, ${data.email}`;
            data.heading = 'student';
            return data;
          });
        }else if (this.searchType === 'institution'){
          this.searchList = res?.result?.institutes.map((data) => {
            data.first_name = `${data.name}`;
            return data;
          });
        }else if (this.searchType === 'course'){
          this.searchList = res?.result?.courses.map((data) => {
            data.first_name = `${data.award?.value} ${data.name}`;
            return data;
          });
        }else if (this.searchType === 'employee' && this.isAssociate === true){
          this.searchList = res?.result?.employees.map((data) => {
            if (data?.type === 'Agent'){
              data.first_name = `${data.name} , ${data.contact_number}, ${data.email}`;
              return data;
            }
          });
        }else if (this.searchType === 'employee' && this.isAssociate === false){
            this.searchList = res?.result?.employees.map((data) => {
              if (data?.type === 'Employee'){
                data.first_name = `${data.name} , ${data.contact_number}, ${data.email}`;
                return data;
              }
            });
        }else if (this.searchType === 'contact'){
          this.searchList = res?.result?.contacts.map((data) => {
            data.first_name = `${data.name}, ${data.phone}, ${data.email}`;
            return data;
          });
        }
      } else {
        this.searchList = [];
      }
    });
  }

  ngOnDestroy(): void {
    this.logo$.unsubscribe();
  }

  getNotificationCount(): void {
    const filter = {
      limit: 10,
      page: 1,
      sortfield: 'created',
      direction: 'desc',
      criteria: [
        {
          key: 'emp_read.emp_id',
          value: this.userDetails._id,
          type: 'nin',
        },
        { key: 'active', value: 'active', type: 'eq' },
        { key: 'frontorback', value: 'frontandback', type: 'or' },
        { key: 'frontorback', value: 'back', type: 'or' },
      ],
    };

    const url =
      APP_CRM_URLS.notifications +
      '/get?fr=an&filter=' +
      JSON.stringify(filter);
    this.userService.getCallWithHeaders(url).subscribe((res: any) => {
      if (res) {
        this.notificationData = res;
      }
    });
  }

  getInboxcount(): void {
    const filter = {
      page: 1,
      sortfield: 'updated',
      direction: 'desc',
      criteria: [{ key: 'open_closed_thread', value: 'open', type: 'eq' }],
    };
    const url = APP_CRM_URLS.inboxes + '?filter=' + JSON.stringify(filter);
    this.userService.getCallWithHeaders(url).subscribe((res: any) => {
      if (res?.inboxes.length) {
        this.inboxesData = res;
      }
    });
  }

  openNotification(type: string): void {
    this.selectedType = type;
    this.isOpenNotification = false;
    setTimeout(() => {
      this.isOpenNotification = true;
      $('#open-notification-view').offcanvas('show');
    }, 10);
  }

  closeNotificationCanvas(): void {
    this.isOpenNotification = false;
    $('#open-notification-view').offcanvas('hide');
  }
}
