<!-- ======= Hero Section ======= -->

<section id="hero">
    <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item active" style="height: auto;">
                <img src="../../../assets/img/slide/slide-1.jpg" class="d-block w-100" alt="...">
                <div class="carousel-caption d-none d-md-block">
                    <h2 class="animate__animated animate__fadeInDown mb-3">Learn Without Boundaries for the present and for the future
                    </h2>
                    <p class="animate__animated animate__fadeInUp">With more than 5,000+ courses and Professional Certificates to enhance your professional career.
                    </p>
                    <div class="mt-5">
                        <ng-container *ngIf="token == null">
                          <a class="btn-2 btn-style animate__animated animate__fadeInUp scrollto" [routerLink]="['/Courses/View all']" routerLinkActive="router-link-active" href="javascript:void(0)">
                            Explore courses
                          </a>
                        </ng-container>
                        <ng-container *ngIf="token != null">
                          <a class="btn-2 btn-style animate__animated animate__fadeInUp scrollto" [routerLink]="['/vle/my-courses']" routerLinkActive="router-link-active" href="javascript:void(0)">
                            Explore courses
                          </a>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
