import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { UserService } from 'src/app/services/userservice.service';
import { APP_URLS, tenantConfig, TenantId } from 'src/app/Utils/general';
import * as ResourceJson from '../../Utils/crm-resource.json';
@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css'],
})
export class ForgotpasswordComponent implements OnInit {
  forgetPasswordForm = new FormGroup({});
  submitted = false;
  resourceData: any = (ResourceJson as any).default;
  routeInfo$!: Subscription;
  type: any;
  forget = {
    email: '',
  };
  displayName = '';
  constructor(
    private router: Router,
    public service: CommonService,
    private userSvc: UserService,
    private routetype: ActivatedRoute
  ) {
    this.routetype.paramMap.subscribe((params) => {
      this.type = params.get('type');
      console.log(this.type);
      if (this.type === 'learner'){
        this.displayName = 'Learner\'s';
      }else if (this.type === 'employer'){
        this.displayName = 'Employer\'s';
      }else{
        this.displayName = `${tenantConfig?.shortName} Staff and Associates\'s`;
      }
    });

    this.forgetPasswordForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/),
      ]),
    });
  }

  ngOnInit(): void {}

  /**
   * get form controls
   */
  get f() {
    return this.forgetPasswordForm.controls;
  }

  forgetPassword() {
    this.submitted = true;
    if (this.forgetPasswordForm.invalid) {
      return;
    }

    if (this.type === 'learner') {
      const url =
        APP_URLS.v1.changevlepwd +
        '?email=' +
        this.forgetPasswordForm.value.email +
        '&tenantId=' +
        TenantId;
      this.userSvc.getCall(url).subscribe((data: any) => {
        if (data.respCode === 204) {
          this.userSvc.showSuccess(data.respMessage);
          this.service.setPopup(false);
        } else {
          this.userSvc.showError(data.respMessage);
        }
      });
    } else if (this.type === 'employee') {
      const url =
        APP_URLS.forgotPassword +
        '?email=' +
        this.forgetPasswordForm.value.email +
        '&tenantId=' +
        TenantId;
      this.userSvc.getCall(url).subscribe((data: any) => {
        if (data.respCode === 204) {
          this.userSvc.showSuccess(data.respMessage);
          this.service.setPopup(false);
        } else {
          this.userSvc.showError(data.respMessage);
        }
      });
    }
  }
}
