import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CourseService {
  private subject = new Subject<any>();
  examcomplete: Subject<any>;
  AssignmentData: Subject<any>;
  ExamData: Subject<any>;
  openexam: Subject<any>;
  profileSub: Subject<any>;
  selected: Subject<any>;
  AssignLater: Subject<any>;
  menucondition: Subject<any>;
  updatepopup: Subject<any>;
  submitEvidencepopup: Subject<any>;
  closeModal: Subject<any>;
  popup: Subject<any>;

  selectCourseByIdData: any;
  private _selectCourseDetails = new BehaviorSubject([]);
  selectCourseDetails = this._selectCourseDetails.asObservable();

  private _setCourseImage = new BehaviorSubject({});
  setCourseImages = this._setCourseImage.asObservable();

  private _setChapterView = new BehaviorSubject({});
  getChapterView = this._setChapterView.asObservable();

  courceid = '';
  buttonType = '';

  constructor(private httpclient: HttpClient, private toastr: ToastrService) {
    this.selected = new Subject<any>();
    this.examcomplete = new Subject<any>();
    this.AssignmentData = new Subject<any>();
    this.profileSub = new Subject<any>();
    this.ExamData = new Subject<any>();
    this.openexam = new Subject<any>();
    this.AssignLater = new Subject<any>();
    this.menucondition = new Subject<any>();
    this.updatepopup = new Subject<any>();
    this.submitEvidencepopup = new Subject<any>();
    this.closeModal = new Subject<any>();
    this.popup = new Subject<any>();
  }

  setChapterView(data: any){
    this._setChapterView.next(data)
  }

  setSelectCourseDetails(data: any) {
    this._selectCourseDetails.next(data);
  }
  setCourseImage(data: any, value: boolean) {
    this._setCourseImage.next({ data, value });
  }
  selectCourseById(data) {
    return (this.selectCourseByIdData = data);
  }

  senddataToHeader(message: string) {
    this.subject.next({ text: message });
  }

  selectedcourse(id: any, type: any) {
    this.courceid = id;
    this.buttonType = type;
    return this.courceid;
  }

  getDataToHeader(): Observable<any> {
    return this.subject.asObservable();
  }

  setexam(value) {
    this.examcomplete.next(value);
  }

  getexam() {
    return this.examcomplete.asObservable();
  }
  setSelectedData(value) {
    this.selected.next(value);
  }

  getSelectedData() {
    return this.selected.asObservable();
  }

  setAssignment(value) {
    this.AssignmentData.next(value);
  }

  getAssignment() {
    return this.AssignmentData.asObservable();
  }
  setExamtype(value) {
    this.AssignmentData.next(value);
  }

  getExamtype() {
    return this.AssignmentData.asObservable();
  }

  setProfile(value) {
    this.profileSub.next(value);
  }

  getProfile() {
    return this.profileSub.asObservable();
  }

  setopenexam(data) {
    this.openexam.next(data);
  }

  getopenexam() {
    return this.openexam.asObservable();
  }

  setAssignLater(value) {
    this.AssignLater.next(value);
  }

  getAssignLater() {
    return this.AssignLater.asObservable();
  }

  setmenu(value) {
    this.menucondition.next(value);
  }

  getmenu() {
    return this.menucondition.asObservable();
  }

  setupdatepopup(value) {
    this.updatepopup.next(value);
  }

  getupdatepopup() {
    return this.updatepopup.asObservable();
  }

  setsubmitEvidencepopup(value) {
    this.submitEvidencepopup.next(value);
  }

  getsubmitEvidencepopup() {
    return this.submitEvidencepopup.asObservable();
  }

  closeModalPopup() {
    this.closeModal.next();
  }

  closeModalPopupService() {
    return this.closeModal.asObservable();
  }

  setPopup(value) {
    this.popup.next(value);
  }

  getPopup() {
    return this.popup.asObservable();
  }
}
