import { CourseService } from 'src/app/services/course.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/userservice.service';
import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { APP_URLS, tenantConfig, TenantId } from 'src/app/Utils/general';
import * as ResourceJson from '../../Utils/crm-resource.json';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  userLoginForm: FormGroup;
  submitted = false;
  activeTab = 'student';
  isActive = true;
  loginType: any;
  resourceData: any = (ResourceJson as any).default;
  tenantConfig = tenantConfig;

  isLoading = false;

  /**
   * get form controls
   */
  get f() {
    return this.userLoginForm.controls;
  }

  constructor(
    private user: UserService,
    private router: Router,
    private courseService: CourseService,
    private routetype: ActivatedRoute,
    private fb: FormBuilder
  ) {
    this.loginType = '';
    this.activeTab = '';
    this.routetype.paramMap.subscribe((params) => {
      this.loginType = params.get('type');
      if (this.loginType === 'employee') {
        this.activeTab = 'staff';
      } else if (this.loginType === 'learner') {
        this.activeTab = 'student';
      }else if (this.loginType === 'employer'){
        this.activeTab = 'employer';
      }else{
        this.activeTab = 'student';
      }
    });

    this.userLoginForm = this.fb.group({
      email: this.fb.control('', [
        Validators.required,
        Validators.email,
        Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/),
      ]),
      password: this.fb.control('', Validators.required),
    });
  }

  ngOnInit(): void {}

  selectTab(currentTab: string) {
    this.userLoginForm.reset();
    this.submitted = false;
    switch (currentTab) {
      case 'employer':
        this.activeTab = 'employer';
        break;

      case 'staff':
        this.activeTab = 'staff';
        break;

      default:
        this.activeTab = 'student';
    }
  }

  showPassword() {
    this.isActive = !this.isActive;
  }

  getDropdowns() {
    const url = APP_URLS.v1.settingsData;
    this.user.getCall(url).subscribe((res: any) => {
      localStorage.setItem('settingsData', JSON.stringify(res));
    });
  }

  getProfileDetails() {
    const url = APP_URLS.v1.userDetails + '?apifrom=profile_summary';
    this.user.getCallWithHeaders(url).subscribe(
      (res: any) => {
        localStorage.setItem(
          'profilesummaryData',
          JSON.stringify(res.userDetails)
        );
        this.user.setIsLogin(true);
        if (this.activeTab === 'student') {
          if (
            this.courseService.courceid !== '' &&
            this.courseService.courceid !== undefined
          ) {
            this.router.navigate(['vle/my-courses']);
          } else {
            this.router.navigate(['vle/profile/profile-summary']);
          }
        } else {
          this.router.navigate(['vle/profile/profile-summary']);
        }
        this.getDropdowns();
      },
      (err) => {
        this.user.showError(err);
      }
    );
  }

  studentLogin() {
    this.submitted = true;
    if (this.userLoginForm.invalid) {
      return;
    }

    const payload = {
      email: this.userLoginForm.value.email,
      password: this.userLoginForm.value.password,
      tenantId: TenantId,
      role: this.activeTab,
    };

    const url = APP_URLS.v1.login;
    this.user.postCall(url, payload).subscribe(
      (res: any) => {
        this.submitted = false;
        if (
          res?.respCode === 200 ||
          res?.respCode === 201 ||
          res?.respCode === 204
        ) {
          this.user.showSuccess(res.respMessage);
          localStorage.setItem('token', res.userDetails.access_token);
          localStorage.setItem('userDetails', JSON.stringify(res.userDetails));
          localStorage.setItem('role', this.activeTab);
          this.getProfileDetails();
        } else {
          this.user.showError(res?.errorMessage);
        }
      },
      (err) => {
        this.user.showError('Login Failed');
      }
    );
  }

  /**
   * used to login user
   */
  stafflogin() {
    this.submitted = true;
    if (this.userLoginForm.invalid) {
      return;
    }

    const payload = {
      usernameOrEmail: this.userLoginForm.value.email,
      password: this.userLoginForm.value.password,
      tenantId: TenantId,
    };
    const url = APP_URLS.login;
    this.isLoading = true;
    this.user.postCall(url, payload).subscribe(
      (res: any) => {
        this.submitted = false;
        if (res?.errorCode === '10001') {
          this.isLoading = false;
          this.user.showError(res?.errorMessage);
        } else {
          // this.document
          //   .getElementById('main-style-sheet')
          //   ?.setAttribute('href', './assets/css/main.css');
          localStorage.setItem('role', this.activeTab);
          localStorage.setItem('token', res.tokenData.access_token);
          localStorage.setItem('userDetails', JSON.stringify(res.tokenData));
          // localStorage.setItem('tenantData', JSON.stringify(res.tenantData));

          this.user.setLoggedUserDetails(res.tokenData);
          this.router.navigate(['crm/dashboard']);
          // setTimeout(() => {
          //   this.document
          //     .getElementById('common-style-sheet')
          //     ?.setAttribute('href', '');
          //   this.document
          //     .getElementById('topbar-style-sheet')
          //     ?.setAttribute('href', '');
          //   this.document.getElementById('font1')?.setAttribute('href', '');
          //   this.document.getElementById('font2')?.setAttribute('href', '');
          // }, 100);

          this.user.showSuccess(this.resourceData.login_success);
        }
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        // this.cms.loaderSet(false);
        this.user.showError('Login Failed');
      }
    );
  }
}
