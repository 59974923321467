<main class="inner-page">

  <!-- ======= Breadcrumbs Section ======= -->
  <section class="breadcrumb">
    <div class="container-fluid">
      <div class="d-flex justify-content-between align-items-center text-xs-center">
        <ul>
          <li style="list-style: none !important;"><a href="javascript:void(0)" routerLink="/">Home</a></li>
          <li style="list-style: none !important;">Terms & Condition</li>
        </ul>

      </div>
    </div>
  </section>
  <!-- End Breadcrumbs Section -->

  <!-- ======= Content Section ======= -->
  <section id="main">
    <div class="container-fluid">
      <div class="main-content">
        <div class="d-flex justify-content-between align-items-center">
          <h1 class="page-header-title-1">Terms of Use</h1>
        </div>
        <hr class="hr" />
        <div class="my-profile">
          <div class="row">
            <div>
              <h3 class="mb-3">Please read the following carefully</h3>
              <p>
                These terms and conditions ("Terms and Conditions") control your use of this website <a
                  href="www.theadmi.com">www.theadmi.com</a> ("Website"). In these Terms and Conditions, "ADMI (Advanced
                Digital Marketing Institute) fully owned and operated by Kinal Media Private Limited" is referred to as
                the "Company", "us," or "we."
              </p>
              <p>
                'You' refers to a user or a paying Student / Applicant. If you are an Institute or third party
                intermediately who gives access to ADMI products, you agree to take responsibility in full in case of
                damages or indemnification that could properly lie against the customer.
              </p>
              <p>
                ADMI website (the 'Site'), the educational services made available through the site and the content (the
                'Products') are owned, operated and maintained, as applicable, by KINAL MEDIA PRIVATE LIMITED ('we',
                'our', 'us', or the 'Company'). The Site, Products and Content are, collectively, the 'Company
                Products'.
              </p>
              <p>
                By (a) using or accessing the Company Products, including, but not limited to downloading or accessing,
                (b) offering a Course through the Site or through Software; you agree to the terms and conditions set
                forth in these Terms of Use (the "Terms")
              </p>
              <p>
                By using this website or its Products and Services, You Agree and Warrant that you have read,
                understood,
                and agreed to be bound by these terms. Company's privacy policy can be found at Privacy Policy. If you
                do
                not accept these terms, you must not use - and are not authorized to use - all or any portion of the
                company's website and its products or services (as defined below).
              </p>
              <p class="fw-bold">Please read them carefully before you use the services of this site.</p>
              <ul>
                <li>You should not use this site in an unlawful manner; you must respect the website terms and
                  conditions and follow the Privacy Policy.</li>
                <li>Under no situations or circumstances, will the company be liable for any change in the content which
                  it provides on the website through its products and services, including but not limited to any errors,
                  omissions, loss, or damage experienced in connection with the use of exposure, any content made
                  available via our products, services, or various resources such as email, blog etc.</li>
                <li>Our services are applicable to users with our login credentials and access to the Internet. However,
                  we are not responsible for the charges incurred for the usage of hardware, software, or internet
                  services provider fee. Also, the user is fully responsible for the proper functioning of computer
                  hardware and internet access for proper functioning.</li>
                <li>You will be required to use login credentials for accessing content on the site and the company
                  reserves the right to block access to our services for any user who does not follow these conditions
                </li>
                <li>We make sure that all eligible users get uninterrupted access to our service, but there is no
                  obligation to do so.</li>
                <li>ADMI is not responsible and is not obligated for issues in your network or server beyond certain
                  limits.</li>
                <li>ADMI does not give any job guarantee or placement guarantee for the courses offered on <a
                    href="www.theadmi.com">www.theadmi.com</a> </li>
              </ul>
            </div>
            <div>
              <h3 class="mb-3">Website Usage Guidelines</h3>
              <ul>
                <li>Do not insult, abuse, harass, stalk, threaten, or otherwise infringe the rights of others;</li>
                <li>Do not publish, post, distribute or disseminate any defamatory, infringing, indecent, offensive or
                  unlawful material or information.</li>
                <li>Do not upload, install, transfer files which are protected by Intellectual Property Laws or software
                  which affect other computers.</li>
                <li>It's prohibited to edit HTML source code, reverse engineer or attempt to hack.</li>
                <li>Do not run Spam services/scripts or anything which could affect the infrastructure, and in turn, the
                  users.</li>
                <li>Do not communicate spam, advertise, or sell services such as digital downloads, eBooks, or phishing
                  links.</li>
                <li>You are not allowed to copy, distribute, and indulge in plagiarism with website content.</li>
                <li>You are not allowed to copy, distribute, and indulge in plagiarism with website images and all
                  presentations made for knowledge transfer.</li>
                <li>Do not use any content which violates any legal, regulatory, Governmental or network operator
                  conditions or codes of practice.</li>
              </ul>
            </div>

            <div>
              <h3 class="mb-3">Links and Hyperlinks Terms</h3>
              <p>This website may have links to other websites. We do not undertake any control on the content of these
                websites; nor are we responsible for their website content. The sole purpose of the links included is to
                provide users information only. Hence, ADMI will not be held responsible with any of the third party
                websites.</p>
              <ul>
                <li>You are not allowed to mirror or frame the homepage or any other pages of this Site on any other
                  website or webpage.</li>
                <li>Do not link to ADMI pages and subpages with spam links/anchor text, which could provide a false
                  impression. This may create misunderstanding for the users.</li>
                <li>Do not use or include copyrighted or registered trademarks, or Intellectual property images, design
                  or content as a link to ADMI website.</li>
                <li>Do not link to pages which support racism, terrorism.</li>
                <li>Do not link to pages which provide pornographic content and violate human and animal rights.</li>
                <li>Do not link pages to content which infringes the intellectual property of any third party, person or
                  entity.</li>
                <li>Do not link pages to content which violates any legal, regulatory, Governmental or network operator
                  conditions or codes of practice.</li>
              </ul>
            </div>

            <div>
              <h3 class="mb-3">Copyright and Intellectual Property</h3>
              <p>We value and respect intellectual property of other companies, organizations and individuals and also
                expect our users to do the same.</p>
              <p>The entire contents of the Site are protected by copyright and trademark laws. The owner of the
                copyrights and trademarks are ADMI (Advanced Digital Marketing Institute), its affiliates, or other
                third party licensors. The material on the site, including text, graphics, code and/or software is
                copyrighted and belongs to ADMI; therefore you may not duplicate, modify, publish, or reproduce the
                content in any manner.</p>
              <p>ADMI does not take any responsibility for the content on other sites (except our partners and
                affiliates), that you may find when searching or accessing ADMI products or services. The Privacy Policy
                and Terms of Use of the sites that you visit will administer that material.</p>
              <p>ADMI has all the rights to disable or prohibit access to the users who do not respect and involve in
                the infringement of ADMI’s intellectual property.</p>
              <p>You are not allowed to use any of the digital images or logos from the website. In case of copyright
                issues, there has to be a written consent from the trademark owner.</p>
            </div>

            <div>
              <h3 class="mb-3">Claims of Intellectual Property Violations</h3>
              <p>If you believe that your work has been used without your permission in any way which prompts for
                copyright infringement. Please provide us with the information given below and we will act on it.</p>
              <ul>
                <li>The authorized person, who will act on behalf of the owner of the copyright, should send a digital
                  or physical signature for representation.</li>
                <li>A description of the copyrighted work that you claim to be infringing your IP.</li>
                <li>A description of where and how the material that you claim is infringing is located on the ADMI
                  website, with enough detail that we may find it on the website.</li>
                <li>Contact Details - Address, telephone number, and email address.</li>
                <li>A statement by you, that the information which you provided is accurate and your claim of the
                  copyright or intellectual property is on your owner's behalf</li>
                <li>You can reach ADMI to notify your claims of copyright by writing to <a href="javascript:void(0)"
                    mailto="support@theadmi.com">support@theadmi.com</a> </li>
              </ul>
            </div>

            <div>
              <h3 class="mb-3">Transaction Terms</h3>
              <p>If you believe that your work has been used without your permission in a way which prompts for
                copyright infringement. Please provide us with the information given below and we will act on it.</p>
              <ul>
                <li>To make a transaction on ADMI website, you are bound to pay for that transaction.</li>
                <li>Please pay close attention to your payment details such as total bill, taxes, shipping costs, and
                  discounts.</li>
                <li>There are certain products which require additional Terms and Conditions which you have to agree to
                  before you make the purchase.</li>
                <li>Invoice of the order placed can be viewed in your respective login </li>
                <li>If an Applicant / Student has completed the course and is eligible for a certificate, then the same
                  shall be delivered to the address provided by the delegate within 30 working days.</li>
              </ul>
              <p>WE MAKE NO WARRANTIES OF ANY KIND, EXPRESSED OR IMPLIED, WITH RESPECT TO ANY PRODUCTS OR SERVICES SOLD
                ON OR THROUGH ADMI.</p>
              <p>No additional or different terms contained in any purchase order, document, transmission or other
                communication shall be binding upon ADMI unless agreed to by Kinal Media Private Limited in writing.</p>
              <p>ADMI reserves the right to modify, change without prior notice and in its sole discretion, to limit the
                order quantity on any item and to refuse service to anyone.</p>
            </div>

            <div>
              <h3 class="mb-3">Subscription Services</h3>
              <p>You will be allowed to subscribe to a particular course or collection of courses ("Subscription
                Services") and will be charged automatically, depending on payment frequency chosen by you, such as
                monthly/quarterly/yearly, as may be applicable.</p>
              <p>You will be permitted to freeze or schedule the freeze of the Subscription Services at anytime and ADMI
                shall not charge you for any such opted Subscription Services according to the payment cycle. Further,
                the Subscription Services, access will be revoked if the user opts to freeze the subscription. You will
                have the option to re-subscribe to the particular Subscription Services again and the payment cycle will
                start accordingly.</p>
              <p>Please note that Subscription Services discounts and any Subscription Services are only a limited time
                special subscription promotions in effect apply only to eligible items displaying the offer message on
                the Subscription Services item information pages, and then only if you select the Subscription Services.
                Subscription Services will last only till a particular period of time, as may be specified on the
                relevant page. Special limited time subscription promotions only apply during their effective dates.</p>
              <p>ADMI retains the right to modify the Subscription Service from time to time, including by adding or
                deleting features and functions, in an effort to improve your experience. ADMI will not make changes to
                the Subscription Service that materially reduces the functionality of the Subscription Service provided
                to you during the Subscription Term. We might provide some or all elements of the Subscription Service
                through third party service providers.</p>
              <p>If you register for a free trial of the Subscription Service, we will make the Subscription Service
                available to you on a trial basis, free of charge until the earlier of (a) the end of the free trial
                period or (b) the start date of your subscription. If we include additional terms and conditions on the
                trial registration web page, those will apply as well. During the free trial period, (i) the
                Subscription Service is provided “as is” and without warranty of any kind, (ii) we may suspend, limit,
                or terminate the Subscription Service for any reason at any time without notice, and (iii) we will not
                be liable to you for damages of any kind related to your use of the Subscription Service. Unless you
                subscribe to the Subscription Service before the end of the free trial, all of your data on the
                Subscription Service will be permanently deleted at the end of the trial, and we will not recover it.
                ADMI shall not refund or in any ways discount, set off any amount once the trial period has ended. In
                case you wish to discontinue to the paid Services, please login to your account. Go to Profile>Manage
                Subscription, click on Subscription and Cancel the subscription to stop any further payments being made.
              </p>
              <p>Valid credit cards / debit cards are the only payment method accepted for Subscription Services
                purchases. The customer shall at all time use a credit card for the use of Subscription Services and
                upon the completion of the free trial period, you will be charged automatically without the requirement
                of any further approval or consent, such a period of the trial period shall be mentioned on the at the
                time of registration to the Subscription Services, ADMI absolves any liability with regard to
                information of period of the free trial period. It is clarified that, once the Subscription Services are
                accepted the participants authorize ADMI and any of its affiliates to debit the accounts at regular
                intervals as may be notified to the participants</p>
              <p>Further, you will have the option of cancelling the Subscription Services during the free trial period,
                without incurring any charges for the same. To initiate the free trial, ADMI shall charge you a token
                amount to ensure the validity of Credit Card. The token amount will be refunded automatically within 5
                days. Further you may cancel your account with ADMI by reaching out to our Support Team</p>
              <p>Your Subscription Services will remain in effect until it is cancelled. If you cancel your Subscription
                Services and then reactivate it, the discount applied to any Subscription Services item may not be the
                same discount in effect at the time of cancellation. If you reactivate a Subscription Services, it will
                be created with the same products with which you previously subscribed. If the Subscription Services
                discount percentage for such Subscription Services item changes, the new discount will be applied to
                your products of that item.</p>
              <p>All fees are exclusive of taxes, which we will charge as applicable. You agree to pay any taxes
                applicable to your use of the Subscription Service. All fees are exclusive of any GST or any other
                applicable law and tax deductions. If you are a Company and that you are required to deduct or withhold
                any TDS (Tax Deduction at Source), you must pay the amount deducted or withheld as required by law and
                pay us an additional amount so that we receive payment in full as if there were no deduction or
                withholding.</p>
            </div>

            <div>
              <h3 class="mb-3">LIMIT OF LIABILITY</h3>
              <p>You expressly understand and agree that the Company shall not be liable for any direct, indirect,
                incidental, special, consequential or exemplary damages, including, damages for loss of profits,
                goodwill, use, data or other intangible losses (even if the Company has been advised of the possibility
                of such damages), resulting from: </p>
              <ol>
                <li>The use or the inability to use the service; </li>
                <li>The cost of procurement of substitute goods and services resulting from any goods, or services
                  purchases or obtained or messages received or transactions entered into through or from the Website or
                  otherwise; </li>
                <li>Unauthorized access to or alteration of your transmissions or data or confidential information;
                </li>
                <li>Statements or conduct of any third party on the Products; </li>
                <li>Any other matter relating to the Products</li>
              </ol>
            </div>

            <div>
              <h3 class="mb-3">INDEMNITY</h3>
              <p>You agree to indemnify and hold the Company and our subsidiaries, affiliates, officers, directors,
                agents, and employees, harmless from any claim or demand (including legal expenses and the expenses of
                other professionals) made by a third party due to or arising out of your breach of this Terms of Use or
                the documents it incorporates by reference, or your violation of any law or the rights of a third party.
              </p>
            </div>

            <div>
              <h3 class="mb-3">Pricing Disclaimer</h3>
              <p>All prices, products, and offers of ADMI website are subject to change without notice.</p>
              <p>While we make sure to provide most accurate and up-to-date information, in some cases one or more items
                on our website may be priced incorrectly. This might happen due to human errors, digital images,
                technical errors, or a mismatch in pricing information received from our suppliers.</p>
              <p>ADMI reserves the right to change prices for all our products, offers, or deals. These changes are done
                due to market conditions, course termination, providers, price changes, errors in advertisements, and
                other mitigating circumstances. However, the price you paid at the time of purchase still holds for you.
              </p>
            </div>

            <div>
              <h3 class="mb-3">Proprietary Use of ADMI Study Material</h3>
              <p>ADMI reserves the right to change prices for all our products, offers, or deals. These changes are done
                due to market conditions, course termination, providers, price changes, errors in advertisements and
                other extenuating circumstances. However, the price you paid at the time of purchase still holds for
                you.</p>
            </div>


          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Content Section -->

</main>
<!-- End #main -->
