<form [formGroup]="studentRegisterForm" class="row  g-3 needs-validation" novalidate>

    <div class="row">

        <div class="col-md-12">
            <div class="form-floating mb-3 mt-2">
                <input type="email" class="form-control lowercase" id="email" formControlName="email" placeholder="Email" [ngClass]="{ 'is-invalid': submitted && f.email.invalid }" required>
                <label for="floatingInput">Email<span style="color: red;">*</span></label>
                <div *ngIf="submitted && f.email.invalid" class="invalid-feedback mb-3">
                    <div *ngIf="f.email.errors?.required">{{ resourceData.email_required }}</div>
                    <div *ngIf="f.email.errors?.pattern">{{ resourceData.invalid_email_address }}</div>
                </div>
            </div>
        </div>

        <div class="col-md-12">
            <div class="form-floating mb-3">
                <select class="form-select" formControlName="title" id="title" aria-label="title" [ngClass]="{ 'is-invalid': submitted && f.title.invalid }">
          <option value="" disabled>Select</option>
          <option value="Mr">Mr</option>
          <option value="Ms">Ms</option>
          <option value="Miss">Miss</option>
          <option value="Mrs">Mrs</option>
          <option value="Dr">Dr</option>
        </select>
                <label for="title">Title<span style="color: red;">*</span></label>
                <div *ngIf="submitted && f.title.invalid" class="invalid-feedback mb-3">
                    <div *ngIf="f.title.errors.required">{{ resourceData.title_required }}</div>
                </div>
            </div>
        </div>

        <div class="col-md-12">
            <div class="form-floating mb-3">
                <input type="text" formControlName="firstName" class="form-control" id="first_name" placeholder="First Name" maxlength="40" [ngClass]="{ 'is-invalid': submitted && f.firstName.invalid }" required appAlphabetOnly>
                <label for="floatingInput">First Name<span style="color: red;">*</span></label>
                <div *ngIf="submitted && f.firstName.invalid" class="invalid-feedback mb-3">
                    <div *ngIf="f.firstName.errors.required">{{ resourceData.first_name_required }}</div>
                    <div *ngIf="f.firstName.errors.maxlength">{{ resourceData.first_name_max_length }}</div>
                    <div *ngIf="f.firstName.errors.pattern">{{ resourceData.invalid_first_name }}</div>
                </div>
            </div>
        </div>

        <div class="col-md-12">
            <div class="form-floating mb-3">
                <input type="text" class="form-control" formControlName="middle_name" id="middle_name" placeholder="Middle Name" maxlength="40" [ngClass]="{ 'is-invalid': submitted && f.middle_name.invalid }" appAlphabetOnly>
                <label for="floatingInput">Middle Name</label>
                <div *ngIf="submitted && f.middle_name.invalid" class="invalid-feedback mb-3">
                    <div *ngIf="f.middle_name.errors.maxlength">{{ resourceData.middle_name_max_length }}</div>
                    <div *ngIf="f.middle_name.errors.pattern">{{ resourceData.invalid_middle_name }}</div>
                </div>
            </div>
        </div>

        <div class="col-md-12">
            <div class="form-floating mb-3">
                <input type="text" formControlName="lastName" class="form-control" id="last_name" placeholder="Last Name" maxlength="40" [ngClass]="{ 'is-invalid': submitted && f.lastName.invalid }" required appAlphabetOnly>
                <label for="floatingInput">Last Name<span style="color: red;">*</span></label>
                <div *ngIf="submitted && f.lastName.invalid" class="invalid-feedback mb-3">
                    <div *ngIf="f.lastName.errors.required">{{ resourceData.last_name_required }}</div>
                    <div *ngIf="f.lastName.errors.maxlength">{{ resourceData.last_name_max_length }}</div>
                    <div *ngIf="f.lastName.errors.pattern">{{ resourceData.invalid_last_name }}</div>
                </div>
            </div>
        </div>


        <div class="col-md-12">
            <div class="form-floating mb-3">
                <select class="form-select" id="floatingSelectGrid" formControlName="nationality" aria-label="Nationality" [ngClass]="{ 'is-invalid': submitted && f.nationality.invalid }" required>
                    <option value="" disabled>Select</option>
                    <option [attr.value]="n.value" *ngFor="let n of nationality">{{n.value}}</option>
                </select>

                <label for="nationality">Nationality<span style="color: red;">*</span></label>
                <div *ngIf="submitted && f.nationality.invalid" class="invalid-feedback mb-3">
                    <div *ngIf="f.nationality.errors.required">{{ resourceData.nationality_required }}</div>
                </div>
            </div>
        </div>

        <div class="col-md-12">
            <div class="form-floating mb-3">
                <input type="tel" formControlName="mobile" class="form-control" id="mobile" placeholder="Mobile" [ngClass]="{ 'is-invalid': submitted && f.mobile.invalid }">
                <label for="mobile">Mobile Number<span style="color: red;">*</span></label>
                <div *ngIf="submitted && f.mobile.invalid" class="invalid-feedback mb-3">
                    <div *ngIf="f.mobile.errors.required">{{ resourceData.mobile_number_required }}</div>
                    <div *ngIf="f.mobile.errors?.pattern">{{resourceData.invalid_mobilenumber}}</div>
                    <div *ngIf="f.mobile.errors?.minlength">{{ resourceData.mobile_min_length }}</div>
                    <div *ngIf="f.mobile.errors?.maxlength">{{ resourceData.mobile_max_length }}</div>

                </div>
            </div>
        </div>

        <div class="col-md-12" *ngIf="studentRole['freelancer']">
          <div class="form-floating mb-3">
              <select class="form-select" id="userRole" formControlName="userRole" aria-label="userRole">
                  <option value="" selected disabled>Select User Role</option>
                  <option *ngIf="studentRole['student']" value="Student" >Student</option>
                  <option *ngIf="studentRole['freelancer']" value="Freelancer" >Freelancer</option>
              </select>
              <label for="userRole">User Role<span style="color: red;">*</span></label>
          </div>
        </div>


        <div class="row">
            <div class="col-md-6 col-12 mt-2">
                <ng-container *ngIf="isLoading">
                    <button class="btn btn-primary me-4" type="button">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      {{ resourceData.loading }}
                    </button>
                </ng-container>
                <ng-container *ngIf="!isLoading">
                    <button type="button" class="btn btn-primary" type="submit" (click)="registerStudent()">Add Student</button>
                </ng-container>
            </div>
        </div>


    </div>
</form>
