import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  Loader: Subject<any>;

  private _ForgotPop = new BehaviorSubject('');
  ForgotPop = this._ForgotPop.asObservable();

  private _popup = new BehaviorSubject(false);
  popup = this._popup.asObservable();

  private _clearModel = new BehaviorSubject({});
  clearModel = this._clearModel.asObservable();

  private _closeModal = new BehaviorSubject({});
  closeModal = this._closeModal.asObservable();

  private _downloadCenter = new BehaviorSubject({});
  downloadCenter = this._downloadCenter.asObservable();
  static showNotification: any;
  static showSuccess: any;

  constructor(public http: HttpClient) {
    this.Loader = new Subject<any>();
  }

  loaderSet(status: any) {
    this.Loader.next({ status });
  }

  LoaderGet() {
    return this.Loader.asObservable();
  }

  clearModelPopup(status) {
    this._clearModel.next({ clear: status });
  }

  subClearAction() {
    return this._clearModel.asObservable();
  }

  closeModalPopup() {
    this._closeModal.next('');
  }

  closeModalPopupService() {
    return this._closeModal.asObservable();
  }

  setPopup(value) {
    this._popup.next(value);
  }

  getPopup() {
    return this._popup.asObservable();
  }

  setForgotPop(value) {
    this._ForgotPop.next(value);
  }

  getForgotPop() {
    return this._ForgotPop.asObservable();
  }

  setDownloadCenter(value) {
    this._downloadCenter.next(value);
  }

  getDownloadCenter() {
    return this._downloadCenter.asObservable();
  }
}
