import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/userservice.service';
import { tenantConfig, employeeOfConfig } from 'src/app/Utils/general';

@Component({
  selector: 'app-crm-footer',
  templateUrl: './crm-footer.component.html',
  styleUrls: ['./crm-footer.component.css'],
})
export class CrmFooterComponent implements OnInit {
  tenantLogo = tenantConfig.logoUrl;
  userDetails: any = [];
  permissions: any = {};
  isEnable = false;
  loggedUserInfo$: Subscription;
  permissionDetails: any = [];
  permissionInfo$: Subscription;
  constructor(private userService: UserService) {
    this.loggedUserInfo$ = this.userService.loggedUserDetails.subscribe(
      (user: any) => {
        if (Object.keys(this.userDetails).length) {
          this.userDetails = user;
        } else {
          const getUserDetails: any = localStorage.getItem('userDetails');
          this.userDetails = JSON.parse(getUserDetails);
        }
      }
    );
    /**
     * Get permission data from local object
     */
    this.isEnable = false;
    this.permissionInfo$ = this.userService.userPermission.subscribe(
      (data: any) => {
        if (data) {
          if (Object.keys(data)?.length > 0) {
            this.permissionDetails = data;
            this.isEnable = true;
          }
        }
      }
    );
  }

  ngOnInit(): void {}

  validateMenuPrivilage(key1: any, key2: any) {
    let isPrivillageRequired = false;
    if (this.userDetails?.role?.value.toLowerCase() !== 'admin') {
      if (
        this.userDetails?.employee_of === employeeOfConfig.value &&
        this.userDetails?.type === 'Employee'
      ) {
        isPrivillageRequired = true;
      } else if (
        this.userDetails?.employee_of === 'associate' &&
        this.userDetails?.type === 'Employee'
      ) {
        isPrivillageRequired = true;
      }

      if (
        this.userDetails?.type === 'Agent' &&
        this.userDetails?.parents?.length &&
        this.userDetails?.parents[0]?.type === 'Agent'
      ) {
        isPrivillageRequired = true;
      } else if (this.userDetails?.role?.value === 'Agent') {
        isPrivillageRequired = true;
      } else if (
        this.userDetails?.employee_of === '' &&
        this.userDetails?.type === 'Employee'
      ) {
        isPrivillageRequired = true;
      }
    }

    if (this.userDetails?.role?.value.toLowerCase() === 'admin' || isPrivillageRequired) {
      if (
        this.permissionDetails[key1] &&
        this.permissionDetails[key1][key2] &&
        this.permissionDetails[key1][key2] === true
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  gotoTop() {
    window.scroll(0, 0);
  }
}
