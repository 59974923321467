<ng-container *ngIf="selectedType === 'notification'">
    <div class="row">
        <div class="col-md-12 justify-content-lg-start mb-3">
            <a *ngIf="isShowCloseAll" href="javascript:void(0)" class="anchorbutton" aria-controls="close-all-notify" (click)="closeAllNotify()">Close All</a>
        </div>
        <div class="col-md-12 col-12">
            <table class="table table-striped">
                <tbody>
                    <tr>
                        <td>
                            <input type="checkbox" #mainNotifyCheck (click)="getNotifyChecklist($event.target.checked)" />
                        </td>
                        <td class="divth text-center">
                            Content
                        </td>
                        <td class="divth">
                            Action
                        </td>
                    </tr>
                    <tr *ngFor="let data of selectedNotifyData?.final_noti | paginate: { itemsPerPage: 10, currentPage: page, totalItems: this.selectedNotifyData?.notifyCount };let i =index">
                        <td>
                            <input type="checkbox" (click)="getSingleNotifyChecklist($event.target.checked, i)" [checked]="check" />
                        </td>
                        <td>
                            <a href="javascript:void(0)" class="anchorbutton" aria-controls="close-all-notify" (click)="openNotification(data)">{{ data?.content }}</a>
                        </td>
                        <td>
                            <span class="badge bg-info text-dark pointer" (click)="closeNotification(data)">Close</span>
                        </td>
                    </tr>
                    <tr *ngIf="!selectedNotifyData?.final_noti?.length && !isNotifyLoading">
                        <td colspan="3" class="text-center">{{ resourceData.no_data_found }}</td>
                    </tr>
                </tbody>
            </table>
            <div class="row" *ngIf="selectedNotifyData?.final_noti?.length">
                <div class="col-lg-12 col-md-12 col-sm-12 ">
                    <pagination-controls (pageChange)="pageChange($event)"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="selectedType === 'inbox'">
    <div class="row">
        <div class="col-md-12 justify-content-lg-start mb-3">
            <a *ngIf="isShowCloseAll" href="javascript:void(0)" class="anchorbutton" aria-controls="close-all-inbox" (click)="closeAllInboxNotify()">Close All</a>
        </div>
        <div class="col-md-12 col-12">
            <table class="table table-striped">
                <tbody>
                    <tr>
                        <td>
                            <input type="checkbox" #mainNotifyCheck (click)="getInboxChecklist($event.target.checked)" />
                        </td>
                        <td class="divth">
                            Created By
                        </td>
                        <td class="divth">
                            Subject
                        </td>
                        <td class="divth">
                            Action
                        </td>
                    </tr>
                    <ng-container *ngIf="!inboxLoading">
                        <tr *ngFor="let data of selectedInboxData?.inboxes | paginate: { itemsPerPage: 10, currentPage: page };let i =index">
                            <td>
                                <input type="checkbox" (click)="getSingleInboxChecklist(data)" [checked]="check" />
                            </td>
                            <td>
                                <div>{{ data?.created_by?.employee?.name }}</div>
                                <div>{{ data?.created | date: 'dd/MM/yyyy' }}</div>
                            </td>
                            <td class="w-50 word-break">
                                <a href="javascript:void(0)" class="anchorbutton" aria-controls="close-all-notify" (click)="openNotification(data)">{{ data?.subject }}</a>
                                <div>{{ data?.open_closed_thread }}</div>
                            </td>
                            <td>
                                <span class="badge bg-info text-dark pointer" (click)="closeInbox(data)">Close</span>
                            </td>
                        </tr>
                    </ng-container>
                    <tr *ngIf="inboxLoading">
                        <td colspan="4" class="text-center">{{ resourceData.loading }}</td>
                    </tr>
                </tbody>
            </table>
            <div class="row" *ngIf="!inboxLoading">
                <div class="col-lg-12 col-md-12 col-sm-12 ">
                    <pagination-controls (pageChange)="pageChange($event, selectedInboxData?.inboxes)"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</ng-container>