import { TenantId } from './general';

const baseUrl = '/api/';

export const APP_VLE_URLS = {
  resetPassword: baseUrl + 'students/changeRecoverPassword/',
  notification: baseUrl + 'notifications/get',
  profileDetails: baseUrl + 'students',
  ProfileDropdown: baseUrl + 'groupedlist',
  DoSignature: baseUrl + 'DoSignature',
  events: baseUrl + 'events',
  fileUpload: baseUrl + 'attachments',
  studentFeeFinance: baseUrl + 'getstudentfeefinance/',
  addOrUpdatestudentFeeFinance:
    baseUrl + 'student_addOrUpdatestudentfeefinance/',
  studentChecklistDeclarations: baseUrl + 'students_getChecklistDeclarations/',
  student_addOrUpdateChecklistDeclarations:
    baseUrl + 'student_addOrUpdateChecklistDeclarations/',
  bookForInterviewCalenderData:
    baseUrl + 'vone/profile/bookforinterview/calender',
  bookForInterview: baseUrl + 'vone/profile/bookforinterview',
  bookForInterviewslotAllocation: baseUrl + 'slotAllocation',
  /** action center */
  UpdateEmployerAgreement: baseUrl + 'students_UpdateEmployerAgreement',
  getEmployerAgreement: baseUrl + 'students_getEmployerAgreement',
  getApprenticeshipAgreementChecklist:
    baseUrl + 'students_getApprenticeshipAgreementChecklist',
  getCommitmentStatement: baseUrl + 'students_getCommitmentStatement',
  getMilestone: baseUrl + 'students_getMilestone',
  getRolesAndResponcebilities: baseUrl + 'students_getRolesAndResponcebilities',
  getRecommendedDuration: baseUrl + 'students_getRecommendedDuration',
  getTotalNegotiatedPrice: baseUrl + 'students_getTotalNegotiatedPrice',
  getEvidenceSupportAPL: baseUrl + 'students_getEvidenceSupportAPL',
  getStandardWeeklyWorkHours: baseUrl + 'students_getStandardWeeklyWorkHours',
  getIndividualLearningAgreement:
    baseUrl + 'students_getIndividualLearningAgreement',
  getcourseidentification: baseUrl + 'students_getcourseidentification',
  getEmpOrganisation: baseUrl + 'students_getEmpOrganisation',
  getAssessorTrainingProvider: baseUrl + 'students_getAssessorTrainingProvider',
  getQualificationCheckedLARA: baseUrl + 'students_getQualificationCheckedLARA',
  getequalification: baseUrl + 'students_getequalification',
  getVerificationofIdentitIDDocuments:
    baseUrl + 'students_getVerificationofIdentitIDDocuments',
  getmanageEmpInfo: baseUrl + 'students_getmanageEmpInfo',
  getModeOFdeliveryLocation: baseUrl + 'students_getModeOFdeliveryLocation',
  getPlannedLearningHours: baseUrl + 'students_getPlannedLearningHours',
  getPreEntryInformation: baseUrl + 'students_getPreEntryInformation',
  getEqualityAndDiversity: baseUrl + 'students_getEqualityAndDiversity',
  getAllConsents: baseUrl + 'students_getAllConsents',
  getAdditionalSupportNeedsQuestions:
    baseUrl + 'students_getAdditionalSupportNeedsQuestions',
  getPlannedReviewDates: baseUrl + 'students_getPlannedReviewDates',
  getWorkPlacementHealthAndSafetyAssessment:
    baseUrl + 'students_getWorkPlacementHealthAndSafetyAssessment',
  getPersonalInformation: baseUrl + 'students_getPersonalInformation',
  getChangeLog: baseUrl + 'students_getChangeLog',
  getJournalEntry: baseUrl + 'students_getJournalEntry',
  getProgressReview: baseUrl + 'students_getProgressReview',
  getBasicAssessmentRpl: baseUrl + 'students_getBasicAssessmentRpl',
  getInductionLearningcentre: baseUrl + 'students_getInductionLearningcentre',
  getIndividualLearningPlanandSignatures:
    baseUrl + 'students_getIndividualLearningPlanandSignatures',
  getStudentDetails: baseUrl + 'students/',
  getconditionalofferletter: baseUrl + 'students_getconditionalofferletter',
  students_getplacementletter: baseUrl + 'students_getplacementletter',
  getenrollemntletter: baseUrl + 'students_getenrollemntletter',
  getapplicationprocess: baseUrl + 'students_getapplicationprocess',
  /***********************************************************/
  updateAttendance: baseUrl + 'addOrUpdateattendances/',
  Progression: baseUrl + 'assignmentsBystudent/',
  ProfileCertificates: baseUrl + 'vone/certificate/student',
  certificateTemplate: baseUrl + 'vone/certificate/template',
  attachments: baseUrl + 'attachments',
  supportTicket: baseUrl + 'inboxes',
  notificationClick: baseUrl + 'notifications/click',
  profileFeedback: baseUrl + 'vone/profile/feedback',
  courseList: baseUrl + 'vone/course/student',
  newchapters: baseUrl + 'vone/lmsmain?filter=',
  AppliedTraining: baseUrl + 'courseapplications?context=',
  mytestList: baseUrl + 'vone/coursetest/student',
  applyTest: baseUrl + 'coursetestsapply',
  getOffTheJobs: baseUrl + 'offthejob',
  lmsInnerSubmit: baseUrl + 'vone/course/studentsubmit',
  postOffTheJobs: baseUrl + 'studenttimesheets',
  submitFeedbackModel: baseUrl + 'studentsfeedback',
  assignments: baseUrl + 'assignments',
  portfolio_of_evidence: baseUrl + 'generalportfolio/getlist',
  chapters: baseUrl + 'chapters',
  aboutCourse: baseUrl + 'courses',
  getPlay: baseUrl + 'playcourseslist',
  lmsInner: baseUrl + 'vone/lmsinner',
  imagePath: `https://daipp-data-buckets.eu-central-1.linodeobjects.com/${TenantId}/`,
  lmsInnersubchapter: baseUrl + 'vone/lmsinner',
  resumeChapter: baseUrl + 'courseapplications',
  tenantSettings: baseUrl + 'tenantsettings',
  getCertificate: baseUrl + 'vone/getcertificate',
  createCertificate: baseUrl + 'vone/createCertificate',
  coursetest: baseUrl + 'vone/coursetest/getrecord?',
  studentsubmit: baseUrl + 'vone/chapterstest/studentsubmit',
  certificateRequest: baseUrl + 'certificaterequest/',
  killPlay: baseUrl + 'updatestudents',
  rePlay: baseUrl + 'removestudents',
  profilePictureUpdate: baseUrl + 'vone/profile_picture/student',
  evidence_fe: baseUrl + 'attachments?from=evidence_fe',
  AssignmentsApi: baseUrl + 'assignments',
  attachmentUrl : baseUrl + 'attachments',
  v1: {
    supportTicket: baseUrl + 'v1/user/ticket/support',
    settingsData : baseUrl + 'v1/settingsdata',
    profilePictureUpdate: baseUrl + 'v1/user/profile_picture',
    changePassword: baseUrl + 'v1/user/changePassword',
    getEmployeeList: baseUrl + 'v1/student/employeelist',
    bookForInterviewslotCreation: baseUrl + 'v1/slotCreation',
    getEncodedUrl: baseUrl + 'v1/encodeimgurl',
    attendanceNew: baseUrl + 'v1/otj/attendance/list',
    createSupportTicket: baseUrl + 'v1/user/ticket/support/create',
    replySupportTicket: baseUrl + 'v1/user/ticket/support/reply',
    getEmployeeUserList: baseUrl + 'v1/otj/employee/userlist',
    getMyApprentice: baseUrl + 'v1/report/user',
    otjReport: baseUrl + 'v1/otjincludeorexcludereport',
    getOTJReport: baseUrl + 'v1/otj/activity/report',
    getOTJRecord: baseUrl + 'v1/otj/activity/record',
    getAtlabRecordStatus: baseUrl + 'v1/atlab/activity/record/status',
    submitATLabRecords: baseUrl + 'v1/atlab/activity/report/submit',
    createCustomOTJ: baseUrl + 'v1/otj/activity/create',
    getAtlabReports: baseUrl + 'v1/atlab/activity/report',
    commentorreviewsave: baseUrl + 'v1/otj/commentorreviewsave',
    courseFinishDetail: baseUrl + 'v1/course/finishdetail',
    getAllCertificateTemplates: baseUrl + 'v1/certificate/template ',
    getAtlabList: baseUrl + 'v1/atlab/activity/list',
    monthlyReview: baseUrl + 'v1/student/apprenticemonthlyreview',
    formalReview: baseUrl + 'v1/student/apprenticeformalreview',
    skillScanReport: baseUrl + 'v1/otj/skillscan/report',
    skillScanCreate: baseUrl + 'v1/otj/skillscan/review/create',
    skillScanUpdate: baseUrl + 'v1/otj/skillscan/review/update',
    matrixReport: baseUrl + 'v1/otj/skillmatrix/report',
    matrixCreate: baseUrl + 'v1/otj/skillmatrix/review/create',
    matrixUpdate: baseUrl + 'v1/otj/skillmatrix/review/update',
    getCourseDetails: baseUrl + 'v1/courseapplication/bycourseandstudent',
    getEmployerAgreement: baseUrl + 'v1/user/agreement/list',
    downloadCenter: baseUrl + 'v1/eventlist',
    getMycourseList: baseUrl + 'v1/student/courses/list',
    otjincludeorexcludereport: baseUrl + 'v1/otjincludeorexcludereport',
    taskList: baseUrl + 'v1/mywork/task/list',
    channelList: baseUrl + 'v1/mywork/channel/list',
    wallet: baseUrl + 'v1/mywork/wallet',
    takeTask : baseUrl + 'v1/mywork/task/take/request',
    taskSubmit: baseUrl  + 'v1/mywork/task/submit',
    kyc: baseUrl + 'v1/mywork/kyc/data',
    kycSubmit: baseUrl + 'v1/mywork/kyc/submit',
    requestWithdraw: baseUrl + 'v1/mywork/withdraw/request',
    withdraw: baseUrl + 'v1/mywork/withdraw/list',
    channelById: baseUrl + 'v1/mywork/channelaccount/list',
    channelCreate: baseUrl + 'v1/mywork/channelaccount/create',
    mwchannel: baseUrl + 'v1/mwchannel'

  },
  oldApi: {
    CertificateRequest: baseUrl + 'certificaterequest/',
  },
};
