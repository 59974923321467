<!-- ======= Footer ======= -->
<footer id="footer">
    <div class="footer-top">
        <div class="container-fluid">
            <div class="row">

                <div class="col-lg-3 col-md-6">
                    <div class="footer-info text-xs-center">
                        <h3><img src="{{tenantInfo?.logoUrl}}" style="width: 120px !important;" /></h3>
                        <p>
                            {{tenantInfo?.tenantAddress}}
                            <br>
                            <strong>Mobile Number: </strong> {{tenantInfo?.phone}}
                            <br>
                            <strong>Email:</strong> {{ tenantInfo?.email}}
                            <br>
                        </p>
                        <div class="social-links mt-3">
                            <a href="javascript:void(0)" class="twitter"><i class="bi bi-twitter"></i></a>
                            <a href="javascript:void(0)" class="facebook"><i class="bi bi-facebook"></i></a>
                            <a href="javascript:void(0)" class="instagram"><i class="bi bi-instagram"></i></a>
                            <a href="javascript:void(0)" class="linkedin"><i class="bi bi-linkedin"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6 col-6 footer-links text-xs-center">
                    <h4>Useful Links</h4>
                    <ul>
                        <li><a routerLinkActive="active" [routerLink]="['/home']">Home</a></li>
                        <li><a routerLinkActive="active" [routerLink]="['/whyus']">Why us</a></li>
                        <li><a routerLinkActive="active" [routerLink]="['/ourApproach']">Our Approach</a></li>
                        <li><a routerLinkActive="active" [routerLink]="['/governance']">Governance</a></li>
                    </ul>
                </div>

                <div class="col-lg-3 col-md-6 col-6 footer-links  text-xs-center">
                    <h4>Our Services</h4>
                    <ul>
                        <li><a routerLinkActive="active" [routerLink]="['/contact']">Contact</a></li>
                        <li><a routerLinkActive="active" [routerLink]="['/privacypolicy']">Privacy Policy</a></li>
                        <li><a routerLinkActive="active" [routerLink]="['/aboutus']">About us</a></li>
                        <li><a routerLinkActive="active" [routerLink]="['/termsAndConditions']">Terms & Condition</a></li>

                    </ul>
                </div>

                <div class="col-lg-4 col-md-6 footer-newsletter text-xs-center">
                    <h4>Our Newsletter</h4>
                    <p>Bring your ideas to life, learn at the bottom by WeTransfer, along with links to their merchandise.</p>
                    <form [formGroup]="newsLetterFrom" class="text-xs-left">
                        <input type="email" id="email" formControlName="email" required>
                        <input type="submit" (click)="Newsletter()" value="SUBSCRIBE">
                    </form>
                    <div class="copyright">
                        © 2022 {{tenantInfo?.name}}
                    </div>
                </div>

            </div>
        </div>
    </div>
</footer>
<!-- End Footer -->

<a href="javascript:void(0)" (click)="scrollToTop()" class="back-to-top d-flex align-items-center justify-content-center" [ngClass]="{'active': scroll === true }"><i
  class="bi bi-arrow-up-short"></i></a>
