import { UserService } from './../../../services/userservice.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { APP_URLS } from 'src/app/Utils/general';
import { tenantConfig} from 'src/app/Utils/general';

@Component({
  selector: 'app-vle-footer',
  templateUrl: './vle-footer.component.html',
  styleUrls: ['./vle-footer.component.css'],
})
export class VleFooterComponent implements OnInit {
  tenantdata = tenantConfig;
  scroll = false;
  submitted = false;
  newsLetterFrom = new FormGroup({});
  isUserRole: string

  constructor(private Us: UserService) {
    this.isUserRole = localStorage.getItem('role');
  }

  ngOnInit(): void {
    this.newsLetterFrom = new FormGroup({
      email: new FormControl('', [
        Validators.required
      ])
    });
  }
  @HostListener('document:scroll')
  scrollfunction() {
    if (
      document.body.scrollTop > 100 ||
      document.documentElement.scrollTop > 100
    ) {
      this.scroll = true;
    } else {
      this.scroll = false;
    }
  }

   /**
   * get form controls
   */
    get f() {
      return this.newsLetterFrom.controls;
    }
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  Newsletter() {

    this.submitted = true;
    if (this.newsLetterFrom.invalid) {
      this.Us.showError('Please Enter Email');
      return;
    }
    const name = (this.newsLetterFrom.value.email).split('@');
    const url = APP_URLS.student;
    const payload = {
      email: this.newsLetterFrom.value.email,
      first_name: name[0],
      last_name: name[0],
      from_place: 'newsletter'
  };
    this.Us.postCallwithHeaders(url, payload).subscribe(
      (res: any) => {
        this.submitted = false;
        if (res.respCode === 201) {
          this.Us.showSuccess(res.respMessage);
          this.newsLetterFrom.reset();
        } else {
          this.Us.showError(res.respMessage);
        }
      },
      (error: any) => {
        this.Us.showError('newsletter Failed');
      }
    );
  }
}
