<!-- Start #main -->
<main id="main" class="container" *ngIf="role !== 'employer'">

    <!-- ======= Gallery Section ======= -->
    <section id="courses" class="courses">
        <div class="container-fluid g-lg-0">
            <div class="container mb-3">
                <div class="row">
                    <div class="col-lg-8">
                        <h3 class="text-xs-center">Professional courses</h3>
                        <p class="text-primary text-uppercase text-xs-center">by {{tenantInfo?.name}}</p>
                    </div>
                    <div class="col-lg-4 text-lg-right text-xs-center mt-3 mb-3">
                      <ng-container *ngIf="token == null">
                        <a class="btn-3" [routerLink]="['/Courses/View all']" routerLinkActive="router-link-active" href="javascript:void(0)">
                          view all courses
                        </a>
                      </ng-container>
                      <ng-container *ngIf="token != null">
                        <a class="btn-3" [routerLink]="['/vle/my-courses']" routerLinkActive="router-link-active" href="javascript:void(0)">
                          view all courses
                        </a>
                      </ng-container>
                    </div>
                </div>
            </div>

            <div class="row g-0">
                <div class="col-lg-3 col-md-4" *ngFor="let course of courses; let i = index">
                    <div class="courses-item" *ngIf="i <= 7">
                        <a href="javascript:void(0)" (click)="AddFav(course)" class="like-icon" [ngClass]="{'heart-icon' : course?.favorite === true }"></a>
                        <div class="courses-img">
                            <a href="javascript:void(0)" (click)="selectCourse(course)">
                                <img src="{{ FILE_PATH_LINODE +'course/'+ course?.document_name}}" alt="" class="img-auto-resize min-max-height">
                                <img src="../../../assets/img/courses/course-2.jpg" class="img-auto-resize min-max-height" *ngIf="course?.document_name ==''" alt="...">
                            </a>
                        </div>
                        <h4><a href="javascript:void(0)" (click)="selectCourse(course)" class="text-white">{{course?.name}}</a></h4>
                    </div>
                </div>
            </div>

        </div>
    </section>
    <!-- End Gallery Section -->
</main>
<!-- End #main -->
