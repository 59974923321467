<main class="inner-page">

    <!-- ======= Breadcrumbs Section ======= -->
    <section class="breadcrumb">
        <div class="container-fluid">
            <div class="d-flex justify-content-between align-items-center text-xs-center">
                <ul>
                    <li><a href="javascript:void(0)" [routerLink]="['/vle/home']">Home</a></li>
                    <li>My Course</li>
                </ul>
            </div>
        </div>
    </section>
    <!-- End Breadcrumbs Section -->

    <!-- ======= Content Section ======= -->
    <section id="main">
        <div class="container-fluid">
            <div class="main-content">
                <div class="d-flex justify-content-between align-items-center">
                    <h1 class="page-header-title-1">My Courses</h1>
                </div>
                <hr class="hr" />
                <div class="my-courses">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="alert alert-info d-flex align-items-start">
                              <ng-container *ngIf="showType === 'all'">
                                <i class="bi-info-circle-fill me-2"></i> All the available courses listed here. You can apply for the desired courses, get approval and start reading. Your allocated courses will be under the Approved course tab, you can read the courses from there.
                              </ng-container>
                              <ng-container *ngIf="showType === 'applied'">
                                <i class="bi-info-circle-fill me-2"></i> The courses list which you applied and are waiting for the approval from admin. Once you get approval, then you can start reading.
                              </ng-container>
                              <ng-container *ngIf="showType === 'assigned'">
                                <i class="bi-info-circle-fill me-2"></i> The courses which are allocated to you are listed here. You can read and finish your courses.
                              </ng-container>
                              <ng-container *ngIf="showType === 'favorites'">
                                <i class="bi-info-circle-fill me-2"></i> Your favourite courses are listed here.
                              </ng-container>
                            </div>
                            <ul class="tabs horizontal nav nav-tabs">
                                <li class="nav-item">
                                    <a class="nav-link active show" data-bs-toggle="tab" href="#show-all" (click)="ShowAllTrainings()">Show All</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-bs-toggle="tab" href="#applied-course" (click)="AppliedTrainings()">Applied Course</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-bs-toggle="tab" href="#approved-course" (click)="ApprovedTrainings()">Approved Course</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-bs-toggle="tab" href="#favourite-course" (click)="FavouriteTrainings()">Favourite Course</a>
                                </li>
                            </ul>
                            <div class="course-wrapper mt-3 mh-300">
                                <div class="row" *ngIf="TrainingList.length > 0">
                                    <div class="col-lg-3 mb-sm-3 mb-3" *ngFor="let Training of TrainingList; let i = index">
                                        <div class="card">
                                            <!-- <a href="javascript:void(0)" class="like-icon"></a> -->
                                            <a href="javascript:void(0)" class="like-icon" *ngIf="Training.fav_status==false" (click)="addFavorateFun(Training._id)"></a>

                                            <a href="javascript:void(0)" class="like-icon heart-icon" *ngIf="Training.fav_status==true" (click)="addFavorateFun(Training._id)"></a>
                                            <img src="{{ FILE_PATH_LINODE + 'course/' +Training.document_name }}" class="mb-3 img-auto-resize img-height" *ngIf="Training.document_name!=''" alt="...">
                                            <img src="../../../../../../assets/img/my-courses/abt.jpg " class="mb-3 img-auto-resize img-height" *ngIf="Training.document_name ==''" alt="...">
                                            <div class="card-body h-10 h-xs-auto">
                                              <h3 class="mb-2" style="cursor: pointer;" *ngIf="Training.apply_status=='applied'" (click)="waitingForApproval()">{{Training.name}}</h3>
                                              <h3 class="mb-2" style="cursor: pointer;" *ngIf="Training.apply_status!='applied'" (click)="CourseNameClick(Training)">{{Training.name}}</h3>
                                              <p class="mb-3 card_content_height overflow-hidden" innerHTML="{{Training.coursefrontview}}"></p>
                                            </div>
                                            <!-- <p class="mb-3 card_content_height" *ngIf="Training.name.length > 0 && Training.name.length < 70">{{(Training.coursefrontview).substring(0,130)}} {{Training.coursefrontview.length
                                                <170? '': "...."}}</p>
                                                    <p class="mb-3 card_content_height" *ngIf="Training.name.length >= 70 ">{{(Training.coursefrontview).substring(0,130)}} {{Training.coursefrontview.length
                                                        <130? '': "...."}}</p> -->
                                            <div>
                                                <a href="javascript:void(0)" class="btn btn-warning w-100" *ngIf="Training.apply_status=='nonapplied'" (click)="apply(Training._id)">Apply</a>

                                                <a href="javascript:void(0)" class="btn btn-success w-100" style="cursor:pointer;" *ngIf="Training.apply_status=='applied'" (click)="alreadyApplied()">Waiting For Approval</a>

                                                <a href="javascript:void(0)" class="btn btn-success w-100" *ngIf="Training.resume_chapter=='' && Training.apply_status=='assigned'" (click)="openCourse(Training)">Start Course</a>

                                                <a href="javascript:void(0)" class="btn btn-success w-100" *ngIf="Training.resume_chapter!='' && Training.apply_status=='assigned'" (click)="openCourse(Training)">Resume Course</a>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div class="col text-center mb-3">
                                  <ng-container class="row" *ngIf="isLoading && !TrainingList.length">
                                      <div class="col-md-12 text-center">
                                          <app-loader></app-loader>
                                      </div>
                                  </ng-container>
                              </div>

                                <div class="row" *ngIf="isnoDataFound">
                                    <div class="col-md-12 text-center">
                                        <div class="alert alert-info" role="alert">
                                            <strong>{{ noDataMsg }}</strong>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" *ngIf="isDataFound && disableButton">
                                    <div class="col text-center mb-3">
                                        <ng-container class="row" *ngIf="isLoading">
                                            <div class="col-md-12 text-center">
                                                <app-loader></app-loader>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="!isLoading && recordCount.totalRecords > 11">
                                            <button class="btn btn-1 btn-sm me-2" (click)="loadMore()">Load More</button>
                                            <button class="btn btn-1 btn-sm me-2" (click)="viewAll()">View All</button>
                                        </ng-container>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Content Section -->

</main>
<!-- End #main -->
