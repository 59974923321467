<main class="inner-page">
    <!-- courses area start -->
    <div id="courses" class="wrap-bg wrap-bg-dark bg-bottom-zero">
        <div class="container">
            <!-- .row -->
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 course-single mb20">
                    <!-- 1 -->
                    <div class="">
                        <div class="row">
                            <div class="col-md-9 col-sm-12">
                                <div class="">
                                    <div id="accordion">
                                        <div class="card">
                                            <div class="card-header">
                                                <a class="card-link" data-toggle="collapse" data-target="#collapseOne">
                          Course Summary
                        </a>
                                            </div>
                                            <div id="collapseOne" class="collapse show" data-parent="#accordion">
                                                <div class="card-body">
                                                    <div *ngFor="let item of CartDetails; let i = index " class="mb-2">
                                                        <div class="row">
                                                            <div class="col-md-2">
                                                                <!-- <img src="../../../assets/images/cartimg.jpg" /> -->
                                                                <img class="img-fluid" *ngIf="item.course.document_name == ''" src="../../../assets/images/cartimg.jpg" />
                                                                <img class="img-fluid" *ngIf="item.course.document_name != ''" src="{{'https://daipp-data-buckets.eu-central-1.linodeobjects.com/'+ tenantId +'/course/'+item.course.document_name}}" />
                                                            </div>
                                                            <div class="col-md-8">
                                                                <h4 style="color: #1086b1; ">
                                                                    <a href="javascript:void(0)" (click)="selectCourse(item)"><strong>{{item.course.name}}</strong></a>
                                                                </h4>
                                                                <span style="font-size: 18px; color: #000;">$<strong>{{item.course.course_fee_final}}</strong></span>&nbsp;
                                                                <span style="font-size: 18px; text-decoration: line-through; color: #000;">${{item.course.course_fee}}</span>
                                                            </div>
                                                            <div class="col-md-2 text-right">

                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="card-footer text-right rightpad" style="background: transparent; border: none">
                                                        <div class="row">
                                                            <div class="col-md-8 text-left mt-2">
                                                                <div style="margin-top: 15px;">Order confirmatiom mail sent to {{emailId}}</div>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <a class="btn_left2" data-toggle="collapse" data-target="#collapseTwo">Continue</a>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header">
                                                <a class="collapsed card-link" data-toggle="collapse" data-target="#collapseTwo">
                          Billing Address
                        </a>
                                            </div>
                                            <div id="collapseTwo" class="collapse" data-parent="#accordion">
                                                <div class="card-body">
                                                    <button class="btn btn-primary savestyles" data-toggle="modal" data-target="#address-modal" *ngIf="getAddressData?.length == 0" (click)="openAddress('')">+ Add</button>
                                                    <div class="m-2">
                                                        <table class="table table-striped display" cellspacing="0" width="100%">
                                                            <thead>
                                                                <tr>
                                                                    <th>S.NO</th>
                                                                    <th>Name</th>
                                                                    <th>Mobile No.</th>
                                                                    <th>Address</th>
                                                                    <th>Actions</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <ng-container *ngIf="!isLoading">
                                                                    <tr *ngFor="let item of getAddressData;let i=index">
                                                                        <td [ngClass]="{'selected': (item._id == default)}" (click)="selectedadd(item)">
                                                                            {{i+1}}</td>
                                                                        <td [ngClass]="{'selected': (item._id == default)}" (click)="selectedadd(item)">
                                                                            {{item.fname}}</td>
                                                                        <td [ngClass]="{'selected': (item._id == default)}" (click)="selectedadd(item)">
                                                                            {{item.mobile}}</td>
                                                                        <td [ngClass]="{'selected': (item._id == default)}" (click)="selectedadd(item)">
                                                                            {{item.city}}, {{item.locality}}, {{item.address}} - {{item.pincode}}.
                                                                        </td>
                                                                        <td [ngClass]="{'selected': (item._id == default)}">
                                                                            <span>
                                        <a href="javascript:void(0)" data-toggle="modal" data-target="#address-modal">
                                          <i class="fas fa-edit text-dark" (click)="openAddress(item)"></i>
                                        </a>
                                      </span>&nbsp;
                                                                            <span>
                                        <i class="fa fa-trash" (click)="deleteDetail(item)"></i>
                                      </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-container>
                                                                <tr *ngIf="isLoading">
                                                                    <td colspan="5" class="text-center">Loading...</td>
                                                                </tr>
                                                                <tr *ngIf="!isLoading && getAddressData?.length === 0">
                                                                    <td colspan="5" class="text-center">No Address Found</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-md-6 text-left mt-2">

                                                        </div>
                                                        <div class="col-md-6 text-right">
                                                            <button class="btn btn-danger" *ngIf="default=='' || studentId == ''" [disabled]="!getAddressData?.length" (click)="continue()">Continue</button>
                                                            <a class="btn_left2" *ngIf="default && studentId && getAddressData.length" data-toggle="collapse" data-target="#collapseThree">Continue</a>&nbsp;

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header">
                                                <a class="collapsed card-link" *ngIf="default=='' || studentId == ''">
                          Payment Option
                        </a>
                                                <a class="collapsed card-link" *ngIf="default && studentId" data-toggle="collapse" data-target="#collapseThree">
                          Payment Option
                        </a>
                                            </div>
                                            <div id="collapseThree" class="collapse" data-parent="#accordion">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="form-check">
                                                                <label class="form-check-label">
                                  <input type="radio" class="form-check-input" checked name="optradio"
                                    (click)="payNow()">Pay
                                  Now
                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <label class="form-check-label">
                                  <input type="radio" class="form-check-input" (click)="Subscription()"
                                    name="optradio">Subscription
                                </label>
                                                            </div>
                                                            <div class="form-check disabled">
                                                                <label class="form-check-label">
                                  <input type="radio" class="form-check-input" (click)="Emi()" name="optradio">EMI (Easy
                                  Installments)
                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row mt-3">
                                                        <div class="col-md-12">


                                                            <!-- 3rd tab  -->
                                                            <div class="tabs_border">
                                                                <!-- <div *ngIf="subscription || emioption"> -->
                                                                <div class="form-group" *ngIf="subscription">
                                                                    <label for="exampleFormControlSelect1">Select</label>
                                                                    <select class="form-control" id="exampleFormControlSelect1" #Selectsubscription (change)='selectSubscription(Selectsubscription.value)'>
                                    <option value ="Month">Month</option>
                                    <option value ="Year">Year</option>
                                  </select>
                                                                    <!-- <select class="form-control" id="exampleFormControlSelect1"
                                    [(ngModel)]="paymentMonth">
                                    <option value="Year">Year</option>
                                    <option value="Month">Month</option>

                                  </select> -->
                                                                </div>

                                                                <div class="form-group" *ngIf="emioption">
                                                                    <label for="exampleFormControlSelect2">Select</label>
                                                                    <select class="form-control" id="exampleFormControlSelect2" #mySelect (change)='selectEMI(mySelect.value)'>
                                    <option [value]="3">3 Months</option>
                                    <option [value]="6">6 Months</option>
                                    <option [value]="9">9 Months</option>
                                  </select>
                                                                </div>
                                                                <div class="form-group" *ngIf="subscription || emioption">
                                                                    <label for="exampleInputEmail1">Installment Amount</label>
                                                                    <input type="number" class="form-control" id="subscriptionamount" aria-describedby="" placeholder={{installamount}} disabled>

                                                                </div>
                                                                <!-- </div> -->
                                                                <div>
                                                                    <ul class="nav nav-pills" role="tablist">
                                                                        <li class="nav-item">
                                                                            <a class="nav-link" data-toggle="pill" href="#home"><i class="fab fa-paypal"></i>
                                        Paypal</a>
                                                                        </li>
                                                                        <li class="nav-item">
                                                                            <a class="nav-link active" data-toggle="pill" href="#menu1"><i
                                          class="fa fa-university"></i> Bank Transfer</a>
                                                                        </li>

                                                                    </ul>

                                                                    <!-- Tab panes -->
                                                                    <div class="tab-content">
                                                                        <div id="home" class=" tab-pane"><br>
                                                                            <ngx-paypal *ngIf="false" [config]="payPalConfig"></ngx-paypal>
                                                                            <p>Paypal is easiest way to pay online</p>
                                                                            <!-- <a class="btn_left2" href="javascript:void(0)"><i class="fa fa-paypal"></i> BUY NOW</a> -->
                                                                            <div style="font-size: 14px; margin-top: 10px"><strong>Note:</strong> Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
                                                                        </div>
                                                                        <div id="menu1" class=" tab-pane active"><br>
                                                                            <p>Bank Account Details</p>
                                                                            <div style="border: 1px solid lightgray; border-radius: 5px; padding: 20px">
                                                                                <h5><strong>Bank</strong></h5>
                                                                                <p>The World Bank</p>
                                                                                <h5><strong>Account Number</strong></h5>
                                                                                <p>12345678912345</p>
                                                                                <h5><strong>IBAN</strong></h5>
                                                                                <p>123456789</p>
                                                                                <h5><strong>Transaction Number</strong></h5>

                                                                                <input type="text" class="form-control" (keypress)="keyPressAlphaNumeric($event)" maxlength="50" placeholder="Transaction Number" id="tnumber">

                                                                            </div>
                                                                            <div style="font-size: 14px; margin-top: 10px"><strong>Note:</strong> After transer amount paste transaction number and click send button</div>
                                                                            <br />
                                                                            <button class="btn_left1" [disabled]="paymentID == ''" (click)="bankTrasfer()">SEND</button>

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-12 text-center">
                                <div class="box_checkout">
                                    <h4 class="text-left">
                                        <strong>Price Details</strong>
                                    </h4>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <table width="100%" cellspacing="0" cellpadding="0">
                                                <tr>
                                                    <td class="text-left">Price</td>
                                                    <td class="text-right">${{TotalCartFee}}</td>
                                                </tr>
                                                <!-- <tr>
                          <td class="text-left">Delivery Charges:</td>
                          <td class="text-right">FREE</td>
                        </tr> -->
                                                <tr>
                                                    <td class="text-left">
                                                        <h4><strong>Total:</strong></h4>
                                                    </td>
                                                    <td colspan="2" class="text-right">
                                                        <h4><strong>${{TotalCartFee}}</strong></h4>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- .row end -->
        </div>
    </div>
    <!-- courses area end -->
</main>

<div id="address-modal" class="modal" data-backdrop="false">
    <div class="modal-dialog" role="document">
        <form>
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Address</h5>
                    <button type="button" class="close" id="address-modal-close-btn" data-dismiss="modal" (click)="closeAddress()">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <form [formGroup]="addressForm">
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <div class="form-floating">
                                    <input type="text" name="fname" id="fname" class="form-control" formControlName="fname" maxlength="40" placeholder="Enter First Name" [ngClass]="{ 'is-invalid': submitted && af.fname.invalid }" required>
                                    <label for="fname">{{ resourceData.first_name }}<span style="color:red">*</span></label>
                                    <div *ngIf="submitted && af.fname.invalid" class="invalid-feedback">
                                        <span *ngIf="af.fname.errors.required">First Name is required</span>
                                        <span *ngIf="af.fname.errors?.pattern">{{resourceData.invalid_first_name}}</span>
                                        <span *ngIf="af.fname.errors?.maxlength">{{ resourceData.first_name_max_length }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3" *ngIf="getAddressData?.length == 0">
                                <div class="form-floating">
                                    <input type="text" name="lname" id="lname" class="form-control" formControlName="lname" maxlength="40" placeholder="Enter Last Name" [ngClass]="{ 'is-invalid': submitted && af.lname.invalid }" required>
                                    <label for="lname">{{ resourceData.last_name }}<span style="color:red">*</span></label>
                                    <div *ngIf="submitted && af.lname.invalid" class="invalid-feedback">
                                        <span *ngIf="af.lname.errors.required">Last Name is required</span>
                                        <span *ngIf="af.lname.errors?.maxlength">{{ resourceData.last_name_max_length }}</span>
                                        <span *ngIf="af.lname.errors?.pattern">{{resourceData.invalid_last_name}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3" *ngIf="getAddressData?.length == 0">
                                <div class="form-floating">
                                    <input type="email" name="email" id="email" class="form-control" formControlName="email" placeholder="Enter Email Address" [ngClass]="{ 'is-invalid': submitted && af.email.invalid }" required>
                                    <label for="email">Email Id<span style="color:red">*</span></label>
                                    <div *ngIf="submitted && af.email.invalid" class="invalid-feedback">
                                        <span *ngIf="af.email.errors.required">Email address is required</span>
                                        <span *ngIf="af.email.errors?.pattern">{{ resourceData.invalid_email_address }}</span>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <div class="form-floating">
                                    <input type="text" name="mobile" id="mobile" class="form-control" formControlName="mobile" minlength="5" maxlength="16" placeholder="Enter Mobile Number" [ngClass]="{ 'is-invalid': submitted && af.mobile.invalid }" required>
                                    <label for="mobile">Mobile Number<span style="color:red">*</span></label>
                                    <div *ngIf="submitted && af.mobile.invalid" class="invalid-feedback">
                                        <span *ngIf="af.mobile.errors.required">Mobile number is required</span>
                                        <span *ngIf="af.mobile.errors?.pattern">{{resourceData.invalid_mobilenumber}}</span>
                                        <span *ngIf="af.mobile.errors?.minlength">{{ resourceData.mobile_min_length }}</span>
                                        <span *ngIf="af.mobile.errors?.maxlength">{{ resourceData.mobile_max_length }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12  mb-3">
                                <div class="form-floating mb-3">
                                    <input type="text" placeholder="Enter Your Address" class="form-control" id="address" formControlName="address" maxlength="50" [ngClass]="{ 'is-invalid': submitted && af.address.invalid }">
                                    <label for="address">Enter Your Address</label>
                                    <div *ngIf="submitted && af.address.invalid" class="invalid-feedback mb-3">
                                        <span *ngIf="af.address.errors?.required">{{ resourceData.address_required }}</span>
                                        <span *ngIf="af.address.errors?.maxlength">{{ resourceData.address_max_length }}</span>
                                        <span *ngIf="af.address.errors?.pattern">{{resourceData.enter_valid_address}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <div class="form-floating mb-3">
                                    <input type="text" placeholder="Enter Your City/ District" class="form-control" id="city" formControlName="city" maxlength="20" [ngClass]="{ 'is-invalid': submitted && af.city.invalid }">
                                    <label for="city">City/Town</label>
                                    <div *ngIf="submitted && af.city.invalid" class="invalid-feedback mb-3">
                                        <sapn *ngIf="af.city.errors?.required">{{ resourceData.city_required }}</sapn>
                                        <sapn *ngIf="af.city.errors?.pattern">{{ resourceData.invalid_city_town }}</sapn>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <div class="form-floating mb-3">
                                    <input type="text" placeholder="State" class="form-control" id="locality" placeholder="Enter Your State" formControlName="locality" maxlength="20" [ngClass]="{ 'is-invalid': submitted && af.locality.invalid }">
                                    <label for="locality">State</label>
                                    <div *ngIf="submitted && af.locality.invalid" class="invalid-feedback mb-3">
                                        <span *ngIf="af.locality.errors?.required">{{ resourceData.state_required }}</span>
                                        <span *ngIf="af.locality.errors?.pattern">{{ resourceData.invalid_state }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6  mb-3">
                                <div class="form-floating">
                                    <input type="text" placeholder="Postal/Zip Code" class="form-control" id="pincode" maxlength="15" formControlName="pincode" [ngClass]="{ 'is-invalid': submitted && af.pincode.invalid }" postalCode required>
                                    <label for="pincode">Postal/Zip Code</label>
                                    <div *ngIf="submitted && af.pincode.invalid" class="invalid-feedback mb-3">
                                        <span *ngIf="af.pincode.errors?.minlength">{{ resourceData.postal_min_length }}</span>
                                        <span *ngIf="af.pincode.errors?.maxlength">{{ resourceData.postal_max_length }}</span>
                                        <span *ngIf="af.pincode.errors?.pattern">{{ resourceData.enter_valid_postal }}</span>
                                        <span *ngIf="af.pincode.errors?.required">{{ resourceData.postal_required }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6  mb-3">
                                <div class="form-floating">
                                    <select name="nationality" id="nationality" class="form-control" formControlName="nationality" [ngClass]="{ 'is-invalid': submitted && af.nationality.invalid }" required>
                    <option value="" selected disabled >Select Country</option>
                    <option *ngFor="let item of CountryList" value={{item.value}}>{{item.value}}</option>
                  </select>
                                    <label for="nationality">Select Country</label>
                                    <div *ngIf="submitted && af.nationality.invalid" class="invalid-feedback mb-3">
                                        <span *ngIf="af.nationality.errors?.required">{{ resourceData.country_required }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="closeAddress()">Close</button>
                    <button type="button" class="btn btn-primary savestyles" (click)="addAddress()">Save</button>
                </div>
            </div>
        </form>
    </div>
</div>
