<!-- ======= Specific Container ======= -->
<div class="row g-0 specific-container">
    <div class="col-md-4 for-individuals">
        <div class="box">
            <h4>
                For Individuals
            </h4>
            <p>
                Give your career a head start with Apprenticeship
            </p>
            <!-- <a href="javascript:void(0)" class="btn-4">GUIDE</a> -->
        </div>
    </div>
    <div class="col-md-4 for-employers">
        <div class="box">
            <h4>
                For Employers
            </h4>
            <p>
                Take Control of your Apprenticeship Levy
            </p>
            <!-- <a href="javascript:void(0)" class="btn-4">GUIDE</a> -->
        </div>
    </div>
    <div class="col-md-4"><img src="assets/img/RA.jpg" class="img-fluid" /></div>
</div>
<!-- End -->