import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/services/userservice.service';
import * as ResourceJson from '../Utils/crm-resource.json';

@Injectable({
  providedIn: 'root',
})
export class CrmAuthGuardGuard implements CanActivate {
  userDetails: any = {};
  resourceData: any = (ResourceJson as any).default; // used for read resource file

  constructor(private router: Router, private userService: UserService) {
    this.userService.loggedUserDetails.subscribe((user: any) => {
      this.userDetails = user;
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (Object.keys(this.userDetails).length === 0) {
      const getUserDetails: any = localStorage.getItem('userDetails');
      this.userDetails = JSON.parse(getUserDetails);
    }
    const token = localStorage.getItem('token');
    if (this.userDetails && token && token !== undefined) {
      const role = 'ROLE_' + this.userDetails?.role?.value;
      const roleName = role.split(' ').join('_');
      // check if route is restricted by role
      if (
        route.data.unAuthRoles &&
        route.data.unAuthRoles.indexOf(roleName.toUpperCase()) > -1
      ) {
        this.userService.showError(this.resourceData.role_permission_msg);
        // role not authorised so redirect to home page
        localStorage.clear();
        this.router.navigate(['/login']);
        return true;
      }
      // authorised so return true
      return true;
    } else {
      this.router.navigate(['/login']);
      localStorage.clear();
      return true;
    }
  }
}
