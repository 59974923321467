<!-- ======= About Section ======= -->
<section id="about" class="testimonials pt-0">
    <div class="row g-0">
        <div class="col-lg-6 comment-section">
            <div id="mycarousel" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="content">
                                    <p class="comment text-left">“ I liked the flexibility of this course. It worked well with busy scheduling, the class notes that followed each chapter were well detailed and the material supported the chapters.
                                      ”</p>
                                    <div class="name text-right">
                                        <p class="m-0">-- Arjun Venugopalan</p>
                                    </div>
                                    <p class="job text-right">Graphic Designer</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="content">
                                    <p class="comment text-left">“ The assignments that related the subject matter to pertinent issues were my favourites. Perhaps some additional reading on topics related to oceanography. ”</p>
                                    <div class="name text-right">
                                        <p class="m-0">-- Pavithra </p>
                                    </div>
                                    <p class="job text-right">Sociology</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="content">
                                    <p class="comment text-left">“ It was a very straight-forward class, which was good for me. I liked the tests because they were given with a substantial amount of time to do them”</p>
                                    <div class="name text-right">
                                        <p class="m-0">-- Velmurugan </p>
                                    </div>
                                    <p class="job text-right">Artitecture </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                      <div class="row">
                          <div class="col-lg-12">
                              <div class="content">
                                  <p class="comment text-left">“The course notes, in my opinion, were quite educational and a great complement to the readings. This course's simplicity and online delivery method, in my opinion, do a great job of assisting students in assimilation and retention of the material.”</p>
                                  <div class="name text-right">
                                      <p class="m-0">-- Siva Kumar</p>
                                  </div>
                                  <p class="job text-right">Economist </p>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 what-student-says">
            <h4> what our students says </h4>
            <div class="chevron-btns">
                <a class="text-decoration-none btn-4" href="#mycarousel" role="button" data-bs-slide="prev">
                    <span class="bi bi-chevron-left"></span>
                </a>
                <a class="text-decoration-none btn-4" href="#mycarousel" role="button" data-bs-slide="next">
                    <span class="bi bi-chevron-right"></span>
                </a>
            </div>
        </div>
    </div>
</section>
<!-- End About Section -->
