import { Component, OnInit } from '@angular/core';
import { tenantConfig } from 'src/app/Utils/general';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {
  token = '';
  tenantInfo = tenantConfig
  constructor() {
    this.token = localStorage.getItem('token');
  }

  ngOnInit(): void {

  }


}
