import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { CourseService } from 'src/app/services/course.service';
import { UserService } from 'src/app/services/userservice.service';
import { APP_URLS, tenantConfig, TenantId } from 'src/app/Utils/general';
declare let $: any;

export interface ClsObj {
  course_type: string;
  description: string;
  courses: any[];
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  ishome = false;

  @Input() menuItem: any;
  @Input() loadCarousel = false;
  showDialog = false;
  showDialogForgot = false;
  showDialogReg = false;
  tenantInfo: any = tenantConfig;
  isShown = false;
  isclose = false;
  sub: any;
  page: number | undefined;
  headerClass = '';
  selectCourseId: any;
  selectCourseIdData: any;
  token = false;
  categoryData: ClsObj[] = [];
  backgroundImage = '';
  userDetail: any = {};
  studentId = '';
  userRole: any = '';
  myAtLaBShow = '';
  myOTJShow = '';
  isOpenCourseType = false;
  CartCount: any;
  CartList: any[] = [];
  cartCount$: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public service: CourseService,
    private userSvc: UserService,
    private Cs: CommonService
  ) {
    this.backgroundImage =
      'https://appliedbusinessacademy.co.uk/assets/images/banner.jpg';
    if (localStorage.getItem('token')) {
      this.getProfilePermissions();
    }
    this.cartCount$ = this.userSvc.getCartData.subscribe((data) => {
      if (data) {
        this.ngOnInit();
      }
    });
  }

  ngOnInit(): void {
    this.userRole = localStorage.getItem('role');

    const profileData = localStorage.getItem('profilesummaryData');
    if (profileData !== undefined && typeof profileData === 'string') {
      this.userDetail = JSON.parse(profileData);
      if (this.userDetail != null) {
        this.studentId = this.userDetail._id;
      }
    }
    const store: any = localStorage.getItem('categoryList');
    this.categoryData = JSON.parse(store);

    this.Cs.getForgotPop().subscribe((data: any) => {
      if (data === 'forgot') {
        this.showDialog = false;
        this.showDialogForgot = true;
        this.showDialogReg = false;
      }
      if (data === 'register') {
        this.showDialog = false;
        this.showDialogForgot = false;
        this.showDialogReg = true;
      }
      if (data === 'login') {
        this.showDialog = true;
        this.showDialogForgot = false;
        this.showDialogReg = false;
      }
    });
    this.Cs.getPopup().subscribe((data: any) => {
      this.showDialogReg = false;
    });

    this.userSvc.islogin.subscribe((data) => {
      this.token = data;
    });

    if (localStorage.getItem('token') && this.studentId) {
      this.token = true;
    }
    this.selectCourseId = this.service
      .getDataToHeader()
      .subscribe((data: any) => {
        this.selectCourseIdData = data.text;
      });

    if (this.userRole !== 'employer') {
      this.getCategoryList();
    }
    this.getCart();

    /*---------- Mobile Menu Active ----------*/
    $.fn.vsmobilemenu = function(options) {
      const opt = $.extend(
        {
          menuToggleBtn: '.vs-menu-toggle',
          liToggleBtn: '.vs-mobile-menu ul li:not(.menu-item-has-children)',
          bodyToggleClass: 'vs-body-visible',
          subMenuClass: 'vs-submenu',
          subMenuParentToggle: 'vs-active',
          meanExpandClass: 'vs-mean-expand',
          subMenuToggleClass: 'vs-open',
          toggleSpeed: 400,
        },
        options
      );

      return this.each(function() {
        const menu = $(this); // Select menu

        // Menu Show & Hide
        function menuToggle() {
          menu.toggleClass(opt.bodyToggleClass);

          // collapse submenu on menu hide or show
          const subMenu = '.' + opt.subMenuClass;
          $(subMenu).each(function() {
            if ($(this).hasClass(opt.subMenuToggleClass)) {
              $(this).removeClass(opt.subMenuToggleClass);
              $(this).css('display', 'none');
              $(this).parent().removeClass(opt.subMenuParentToggle);
            }
          });
        }
        // Class Set Up for every submenu
        menu.find('li').each(function() {
          const submenu = $(this).find('ul');
          submenu.addClass(opt.subMenuClass);
          submenu.css('display', 'none');
          submenu.parent().addClass(opt.subMenuParent);
          submenu.prev('a').addClass(opt.meanExpandClass);
          submenu.next('a').addClass(opt.meanExpandClass);
        });

        // Toggle Submenu
        function toggleDropDown($element) {
          if ($($element).next('ul').length > 0) {
            $($element).parent().toggleClass(opt.subMenuParentToggle);
            $($element).next('ul').slideToggle(opt.toggleSpeed);
            $($element).next('ul').toggleClass(opt.subMenuToggleClass);
          } else if ($($element).prev('ul').length > 0) {
            $($element).parent().toggleClass(opt.subMenuParentToggle);
            $($element).prev('ul').slideToggle(opt.toggleSpeed);
            $($element).prev('ul').toggleClass(opt.subMenuToggleClass);
          }
        }

        // Submenu toggle Button
        const expandToggler = '.' + opt.meanExpandClass;
        $(expandToggler).each(function() {
          $(this).on('click', function(e) {
            e.preventDefault();
            toggleDropDown(this);
          });
        });

        // Menu Show & Hide On Toggle Btn click
        $(opt.menuToggleBtn).each(function() {
          $(this).on('click', () => {
            menuToggle();
          });
        });

        // Menu Show & Hide On Toggle Btn click
        $(opt.liToggleBtn).each(function() {
          $(this).on('click', () => {
            menuToggle();
          });
        });

        // Hide Menu On out side click
        menu.on('click', (e) => {
          e.stopPropagation();
          menuToggle();
        });

        // Stop Hide full menu on menu click
        menu.find('div').on('click', (e) => {
          e.stopPropagation();
        });

        // Hide Menu On click menu items
        $('.item-click').on('click', (e) => {
          e.stopPropagation();
          menuToggle();
        });
      });
    };
    $('.vs-menu-wrapper').vsmobilemenu();

    /*---------- Sticky fix ----------*/
    let lastScrollTop = '';
    const scrollToTopBtn = '.scrollToTop';

    function stickyMenu($targetMenu, $toggleClass, $parentClass) {
      const st = $(window).scrollTop();
      const height = $targetMenu.css('height');
      $targetMenu.parent().css('min-height', height);
      if ($(window).scrollTop() > 500) {
        $targetMenu.parent().addClass($parentClass);

        if (st > lastScrollTop) {
          $targetMenu.removeClass($toggleClass);
        } else {
          $targetMenu.addClass($toggleClass);
        }
      } else {
        $targetMenu.parent().css('min-height', '').removeClass($parentClass);
        $targetMenu.removeClass($toggleClass);
      }
      lastScrollTop = st;
    }
    $(window).on('scroll', function() {
      stickyMenu($('.sticky-active'), 'active', 'will-sticky');
      if ($(this).scrollTop() > 500) {
        $(scrollToTopBtn).addClass('show');
      } else {
        $(scrollToTopBtn).removeClass('show');
      }
    });
  }

  getCart() {
    if (localStorage.getItem('token') && this.studentId) {
      const url = APP_URLS.getcart;
      const payload = {};
      this.userSvc.postCallwithHeaders(url, payload).subscribe((res: any) => {
        this.CartCount = res.listCount;
      });
    } else {
      const dataofcart = localStorage.getItem('cartdata');
      this.CartCount = 0;
      if (dataofcart != null) {
        this.CartList = dataofcart.split(',');
        this.CartCount = this.CartList.length;
      }
    }
  }

  openmenu() {
    this.isclose = !this.isclose;
    this.isShown = !this.isShown;
  }

  logout() {
    localStorage.clear();
    this.userSvc.setIsLogin(false);
    this.router.navigate(['/home/'], { queryParams: { page: 0 } });
  }

  openCourses(coursetype: any, place: string) {
    localStorage.setItem('courseType', coursetype.course_type);
    if (place === 'mobile-nav'){
      $('#close-menu').click();
    }else{
      this.router.navigate(['/Courses/' + coursetype.course_type]);
    }

    this.isOpenCourseType = false;
    $('#open-coursetype').offcanvas('hide');
  }
  mobileLogoClick(){
    $('#close-menu').click();
  }

  openIndividulaCourse(course: any) {
    localStorage.setItem('Individualcourse', course.slug);
    this.router.navigate(['/' + course.slug]);
  }

  openCart() {
    this.router.navigate(['/Cart']);
  }

  getCategoryList() {
    const url =
      APP_URLS.Categories +
      '?filter={"criteria":[{"key":"tenantId","value":"' +
      TenantId +
      '","type":"eq"}]}';
    this.userSvc.getCall(url).subscribe((res: any) => {
      const name: any = [];
      const tempCategoryData: any = [];
      const id = '';
      const categories = Object.values(res.course_category_detail);
      categories.forEach((x: any) => {
        const obj: ClsObj = {
          course_type: '',
          description: '',
          courses: [],
        };
        const repetingdata = name.find(
          (z: any) => z === res.course_category_detail.course_type
        );
        if (repetingdata) {
        } else {
          if (x.title) {
            obj.course_type = x.title;
            obj.description = x.description;
            if (x?.course?.length) {
              x.course.forEach((y: any) => {
                if (x.title === y.course_type) {
                  obj.courses.push({
                    id: y._id,
                    name: y.name,
                    slug: y.slug,
                  });
                }
              });
            }
            tempCategoryData.push(obj);
          }
        }
      });
      if (this.categoryData === null) {
        this.categoryData = tempCategoryData;
      }

      localStorage.setItem('categoryList', JSON.stringify(tempCategoryData));
    });
  }

  async getProfilePermissions() {
    const url = APP_URLS.profilePermissions;

    await this.userSvc.getCallWithHeaders(url).subscribe(
      (res: any) => {
        this.myAtLaBShow = res?.my_at_lab;
        this.myOTJShow = res?.my_otj;
      },
      (err) => {}
    );
  }

  @HostListener('document:scroll')
  scrollfunction() {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      this.ishome = true;
    } else {
      this.ishome = false;
    }
  }

  openMoreCourseType() {
    this.isOpenCourseType = false;
    setTimeout(() => {
      this.isOpenCourseType = true;
      $('#open-coursetype').offcanvas('show');
    }, 100);
  }
}
