<main class="inner-page">

    <!-- ======= Breadcrumbs Section ======= -->
    <section class="breadcrumb">
        <div class="container-fluid">
            <div class="d-flex justify-content-between align-items-center text-xs-center">
                <ul>
                    <li><a href="javascript:void(0)" routerLink="/vle/home">Home</a></li>
                    <li>Help Desk</li>
                </ul>

            </div>
        </div>
    </section>
    <!-- End Breadcrumbs Section -->

    <!-- ======= Content Section ======= -->
    <section id="main">
        <div class="container-fluid">
            <div class="main-content" style="min-height: 35rem !important;">
                <div class="d-flex justify-content-between align-items-center">
                    <h1 class="page-header-title-1">Help desk</h1>
                </div>
                <hr class="hr" />
                <div class="help-desk">
                    <div class="row">
                        <div class="col-lg-8 ">
                            <div class="row">
                                <div>
                                    <h3 class="mb-3">Help Desk</h3>
                                </div>
                                <div class="ng-autocomplete col">
                                    <ng-autocomplete #auto [data]="allTopics" [searchKeyword]="keyword" placeholder="Search" (selected)='selectEvent($event)' name="searchTxt" [itemTemplate]="itemTemplate" notFoundText="No data found" [notFoundTemplate]="notFoundTemplate">

                                    </ng-autocomplete>

                                    <ng-template #itemTemplate let-item>
                                        <a [innerHTML]="item.help_title"></a>
                                    </ng-template>

                                    <ng-template #notFoundTemplate let-notFound>
                                        <div [innerHTML]="notFound"></div>
                                    </ng-template>
                                    <div class="row justify-content-center" *ngIf="!isLoading && helpDeskDetails.length === 0">
                                      No data found
                                    </div>
                                </div>

                            </div>

                            <h4 class="mb-3"><strong>{{ helpTitle }}</strong></h4>
                            <div class="border mb-3">
                                <div class="card-body fw-bold" *ngIf="helpRelatedTopics.length">
                                    <div [innerHTML]="innerhtmldata"></div>
                                </div>
                            </div>
                            <div class="border mb-3" *ngIf="helpRelatedTopics.length">
                                <div class="card-body">
                                    <h4 class="mb-3">Related Topics</h4>
                                    <div class="border mb-3">
                                        <div class="card-body">
                                            <ul class="list-unstyled leftLoadContent fw-bold">
                                                <li>
                                                    {{ relatedTopic?._id }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 ">
                            <div class="d-flex mb-3 ">
                                <i class="bi bi-book-fill me-2"></i>
                                <h3>Topics</h3>
                            </div>
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item" *ngFor="let helpDesk of helpDeskDetails; let i=index ">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse'+i" aria-expanded="true" [attr.aria-controls]="'collapse'+i">
                                            <i class="fa fa-chevron-down me-2"></i> {{ helpDesk._id}} ({{ helpDesk.count }})
                                        </button>
                                    </h2>
                                    <div [id]="'collapse'+i" class="accordion-collapse collapse" [ngClass]="{'show': i === 0}" [attr.aria-labelledby]="'#heading'+i" [attr.data-bs-parent]="'#accordionExample'">
                                        <div class="accordion-body">
                                            <p *ngFor="let helpsubData of helpDesk.help_related; let subIndex=index">
                                                <a href="javascript:void(0)" class="text-black" (click)="selectTopic(helpDesk.help_related, subIndex, i)">
                          {{ helpsubData.help_title }}</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <!-- Content Section -->

</main>
<!-- End #main -->
