import { Component, OnInit } from '@angular/core';
import { APP_URLS, tenantConfig, TenantId } from 'src/app/Utils/general';

@Component({
  selector: 'app-policies',
  templateUrl: './policies.component.html',
  styleUrls: ['./policies.component.css'],
})
export class PoliciesComponent implements OnInit {
  errorpage: any;
  isBeforeLogin = true;
  tenantInfo: any = tenantConfig;

  constructor() {
    const token = localStorage.getItem('token');
    this.isBeforeLogin = token ? false : true;
  }

  ngOnInit(): void {
    this.errorpage = window.location.pathname;
  }
}
