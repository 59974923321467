<main class="inner-page">

    <!-- ======= Breadcrumbs Section ======= -->
    <section class="breadcrumb">
        <div class="container-fluid">
            <div class="d-flex justify-content-between align-items-center text-xs-center">
                <ul>
                    <li><a routerLink="/" href="javascript:void(0)">Home</a></li>
                    <li *ngIf="courselist.course_type !== ''" ><a routerLink="{{'/Courses/'+ courselist.course_type}}" href="javascript:void(0)">{{courselist.course_type}}</a></li>
                    <li class="text-capitalize">{{courselist.name}}</li>
                </ul>
            </div>
        </div>
    </section>
    <!-- End Breadcrumbs Section -->

    <!-- ======= Content Section ======= -->
    <section id="main">
        <div class="container-fluid">
            <div class="main-content">
                <div class="d-lg-flex  justify-content-between align-items-center">
                    <h1 class="page-header-title-1 mb-3">{{courselist.name}}</h1>
                    <div class="flex-direction-sm">
                        <a (click)="AddFav(course)" *ngIf="studentID == null && (course?.infav == false)" href="javascript:void(0)" class="btn btn-warning mb-2">
                            Add As Favourite
                        </a>

                        <a (click)="removeFav(course)" *ngIf="studentID == null && (course?.infav == true)" href="javascript:void(0)" class="btn btn-danger mb-2">
                            Remove Favourite
                        </a>

                        <a (click)="AddFav(course)" *ngIf="studentID && (course?.fav_status == false)" href="javascript:void(0)" class="btn btn-warning mb-2" >
                            Add As Favourite
                        </a>

                        <a (click)="removeFav(course)" *ngIf="studentID && (course?.fav_status == true)" href="javascript:void(0)" class="btn btn-danger mb-2">
                            Remove Favourite
                        </a>
                        <a href="javascript:void(0)" class="btn btn-outline-primary mx-lg-2 mb-2 mx-md-2" (click)="bookAppointment()">BOOK APPOINTMENT</a>
                        <a (click)="AddApply(course)" *ngIf="studentID == null" href="javascript:void(0)" class="btn btn-success mb-2">
                            <span>Apply course</span></a>


                        <a (click)="AddApply(course)" *ngIf="studentID && course.text_btn == 'Apply course'" href="javascript:void(0)" class="btn btn-success mb-2">
                            <span> {{course.text_btn}} </span></a>

                        <a *ngIf="studentID && (course.text_btn == 'Waiting for approval')" href="javascript:void(0)" class="btn btn-warning mb-2">
                            <span> {{course.text_btn}} </span></a>

                        <a (click)="openCourse(course)" *ngIf="studentID && (course.text_btn == 'Start course' || course.text_btn == 'Resume course')" href="javascript:void(0)" class="btn btn-success mb-2">
                            <span> {{course.text_btn}} </span></a>
                    </div>
                </div>
                <hr class="hr" />
                <div class="courses-details">
                    <div class="row">
                        <div class="col-lg-7">
                            <div class="mycourses-section">

                                <div class="mt-2">
                                    <div [innerHTML]="innerhtmldata"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="mb-3">
                                <img *ngIf="course?.document_name != ''" src="{{FILE_PATH_LINODE + 'course/' + course?.document_name}}" class="img-fluid">
                            </div>

                            <div class="mb-3">
                             <div [innerHTML]='evVideo'></div>
                          </div>

                            <div *ngIf="!isUserRole" class="card p-3 shadow rounded-3">
                                <h2 class="mb-3 text-center">Want to study this course?</h2>
                                <p class=" p-3 text-white ">I can help you, just drop in your details below for the call back</p>
                                <div class="row ">
                                    <div class="col-lg-12 ">
                                        <div class=" profile-info">
                                            <div class="flex-direction-sm d-lg-flex d-md-flex justify-content-center">
                                                <div class="profile-pic p-3">
                                                    <img src="assets/img/profile/profile-pic.png" alt="" class="img-fluid">
                                                </div>
                                                <div class="align-self-center text-center text-lg-start text-md-start">
                                                    <div>SUNITHA VIJAY</div>
                                                    <div>Admission Expert</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <form class="p-3" [formGroup]="enquiryloginForm">
                                    <div class="mb-3">
                                        <input type="text" class="form-control" formControlName="fname" id="fname" placeholder="First Name" [ngClass]="{ 'is-invalid': submitted && f.fname.invalid }">
                                        <div *ngIf="submitted && f?.fname?.invalid" class="invalid-feedback mb-3">
                                            <div *ngIf="f?.fname?.errors?.required">{{resourceData?.first_name_required}}</div>
                                            <div *ngIf="f?.fname?.errors?.pattern">{{resourceData?.invalid_first_name}}</div>
                                            <div *ngIf="f?.fname?.errors?.maxlength">{{resourceData?.first_name_max_length}}</div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <input type="text" class="form-control" formControlName="lname" id="lname" placeholder="Last Name" [ngClass]="{ 'is-invalid': submitted && f.lname.invalid }">
                                        <div *ngIf="submitted && f?.lname?.invalid" class="invalid-feedback mb-3">
                                            <div *ngIf="f?.lname?.errors?.required">{{resourceData?.last_name_required}}</div>
                                            <div *ngIf="f?.lname?.errors?.pattern">{{resourceData?.invalid_last_name}}</div>
                                            <div *ngIf="f?.lname?.errors?.maxlength">{{resourceData?.last_name_max_length}}</div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <input type="email" class="form-control" formControlName="email" id="email" placeholder="Work Email Address" [ngClass]="{ 'is-invalid': submitted && f.email.invalid }">
                                        <div *ngIf="submitted && f?.email?.invalid" class="invalid-feedback mb-3">
                                            <div *ngIf="f?.email?.errors?.required">{{resourceData?.work_email_required}}</div>
                                            <div *ngIf="f?.email?.errors?.pattern">{{resourceData?.invalid_emp_email_address}}</div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <input type="text" class="form-control" maxlength="16" formControlName="phone" id="phone" placeholder="Mobile Number" [ngClass]="{ 'is-invalid': submitted && f.phone.invalid }">
                                        <div *ngIf="submitted && f?.phone?.invalid" class="invalid-feedback mb-3">
                                            <div *ngIf="f?.phone?.errors?.required">{{resourceData?.phone_number_required}}</div>
                                            <div *ngIf="f?.phone?.errors?.pattern">{{resourceData?.invalid_Mobile_number}}</div>
                                            <div *ngIf="f?.phone?.errors?.maxlength">{{resourceData?.phone_number_max_16}}</div>
                                            <div *ngIf="f?.phone?.errors?.minlength">{{resourceData?.phone_number_max_5}}</div>
                                        </div>
                                    </div>
                                    <div class="text-end">
                                        <button type="button" (click)="enquiry()" class="btn-1 btn-md ml-10 ">Talk to us for Funding Options</button>
                                    </div>


                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Content Section -->

</main>
<!-- End #main -->
