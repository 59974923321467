<!-- Login content starts here -->
<div class="registration">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="header-wrapper">
                    <h1 class="page-header-title">Register</h1>
                    <div class="header-subtitle">Create an account</div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row g-0">
            <div class="col-lg-6 col-left">
            </div>
            <div class="col-lg-6 bg-white">
                <div class="p-20">
                    <div class="section-header">
                        Learner’s Register
                    </div>
                    <div class="p-25">
                        <form [formGroup]="registerForm">
                            <div class="mb-3">
                                <input type="email" class="form-control" placeholder="EMAIL" id="email" name="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f?.email?.invalid }">
                                <div *ngIf="submitted && f?.email?.invalid" class="invalid-feedback mb-3">
                                    <div *ngIf="f?.email?.errors?.required">{{resourceData?.email_required}}</div>
                                    <div *ngIf="f?.email?.errors?.pattern">{{resourceData?.invalid_email_address}}</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3  position-relative">
                                    <select class="form-control mb-3 circleErr" id="title" name="title" formControlName="title" [ngClass]="{ 'is-invalid': submitted && f?.title?.invalid }">
                                      <option value="" selected disabled>Title</option>
                                      <option value="Mr">Mr</option>
                                      <option value="Ms">Ms</option>
                                      <option value="Miss">Miss</option>
                                      <option value="Mrs">Mrs</option>
                                      <option value="Dr">Dr</option>
                                    </select>
                                    <i class="bi bi-chevron-down form-control-icon"></i>
                                    <div *ngIf="submitted && f?.title?.invalid" class="invalid-feedback mb-3">
                                        <div *ngIf="f?.title?.errors?.required">{{resourceData?.title_required}}</div>
                                    </div>
                                </div>
                                <div class="col-md-9">
                                    <input type="text" class="form-control mb-3" placeholder="FIRST NAME" id="fname" name="fname" formControlName="fname" maxlength="40" [ngClass]="{ 'is-invalid': submitted && f?.fname?.invalid }" required>
                                    <div *ngIf="submitted && f?.fname?.invalid" class="invalid-feedback mb-3">
                                        <div *ngIf="f?.fname?.errors?.required">{{resourceData?.first_name_required}}</div>
                                        <div *ngIf="f?.fname?.errors?.pattern">{{resourceData?.invalid_first_name}}</div>
                                        <div *ngIf="f?.fname?.errors?.maxlength">{{resourceData?.first_name_max_length}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <input type="text" class="form-control mb-3" placeholder="MIDDLE NAME" id="mname" name="mname" formControlName="mname" maxlength="40" [ngClass]="{ 'is-invalid': submitted && f?.mname?.invalid }">
                                    <div *ngIf="submitted && f?.mname?.invalid" class="invalid-feedback mb-3">
                                      <!-- <div *ngIf="f?.mname?.errors?.required">{{resourceData?.last_name_required}}</div> -->
                                      <div *ngIf="f?.mname?.errors?.pattern">{{resourceData?.invalid_middle_name}}</div>
                                      <div *ngIf="f?.mname?.errors?.maxlength">{{resourceData?.last_name_max_length}}</div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" class="form-control mb-3" placeholder="LAST NAME" id="lname" name="lname" formControlName="lname" maxlength="40" [ngClass]="{ 'is-invalid': submitted && f?.lname?.invalid }">
                                    <div *ngIf="submitted && f?.lname?.invalid" class="invalid-feedback mb-3">
                                        <div *ngIf="f?.lname?.errors?.required">{{resourceData?.last_name_required}}</div>
                                        <div *ngIf="f?.lname?.errors?.pattern">{{resourceData?.invalid_last_name}}</div>
                                        <div *ngIf="f?.lname?.errors?.maxlength">{{resourceData?.last_name_max_length}}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-3">
                              <div class="col-md-6">
                                  <input type="text" class="form-control mb-3" placeholder="MOBILE NUMBER" id="phone" name="phone" formControlName="phone" [ngClass]="{ 'is-invalid': submitted && f?.phone?.invalid }">
                                  <div *ngIf="submitted && f?.phone?.invalid" class="invalid-feedback mb-3">
                                      <div *ngIf="f?.phone?.errors?.required">{{resourceData?.mobile_number_required}}</div>
                                      <div *ngIf="f?.phone?.errors?.pattern">{{resourceData?.invalid_Mobile_number}}</div>
                                      <div *ngIf="f?.phone?.errors?.maxlength">{{resourceData?.Mobile_number_max_16}}</div>
                                  </div>
                              </div>
                              <div class="col-md-6 position-relative">
                                  <select class="form-control " id="userRole" formControlName="userRole" aria-label="userRole" [ngClass]="{ 'is-invalid': submitted && f.userRole.invalid }" required>
                                    <option value="" selected disabled>Select User Role</option>
                                    <option *ngIf="studentRole && studentRole['student']" value="Student" >Student</option>
                                    <option *ngIf="studentRole && studentRole['freelancer']" value="Freelancer" >Freelancer</option>
                                  </select>
                                  <i class="bi bi-chevron-down form-control-icon"></i>
                                  <div *ngIf="submitted && f?.userRole?.invalid" class="invalid-feedback mb-3">
                                      <div *ngIf="f?.userRole?.errors?.required">{{resourceData?.user_role_required}}</div>
                                  </div>
                              </div>
                          </div>
                            <div class="mb-3">
                                <input type="button" class="btn-2" value="Register" (click)="Register()">
                            </div>
                        </form>
                        <hr />
                        <div class="d-flex justify-content-md-end">
                            <span class="a-link text-primary font-normal text-decoration-none"> Already have an account ? </span>
                            <a [routerLink]="['/login']" class="a-link text-primary ml-10" href="javascript:void(0)"> Login </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
