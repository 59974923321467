<main class="inner-page">
    <!-- ======= Breadcrumbs Section ======= -->
    <section class="breadcrumb">
        <div class="container-fluid">
            <div class="d-flex justify-content-between align-items-center text-xs-center">
                <ul>
                    <li><a routerLink="/" href="javascript:void(0)">Home</a></li>
                    <li>{{coursetype}}</li>
                </ul>
            </div>
        </div>
    </section>
    <!-- End Breadcrumbs Section -->
    <div class="course-wrapper mt-2 container">
        <div class="main-content mh500">
            <div class="row mt-5">
                <div class="col-lg-3 mb-3" *ngFor="let item of courselist">
                    <div class="card">
                        <img [src]="item.document_name !='' ? FILE_PATH_LINODE + 'course/'+item.document_name : '../../../assets/images/abt-ban.png'" alt="..." class="mb-3 img-auto-resize img-height">
                        <div class="card-body h-10 h-xs-auto">
                            <h6 class="mb-2 text-capitalize"><a href="javascript:void(0)" (click)="openIndividulaCourse(item)">{{item.name}}</a></h6>
                            <p class="mb-3 d-lg-block d-none" [innerHtml]="textTruncate(item.coursefrontview, 150)"></p>
                            <p class="mb-3 d-lg-none d-block" [innerHtml]="textTruncate(item.coursefrontview, 100)"></p>
                        </div>
                        <div>
                            <a href="javascript:void(0)" class="btn btn-success col-12 col-lg-6  py-2" [ngClass]="{'col-lg-12' : item?.course_fee === 0}" (click)="AddApply(item)">
                                <span> {{item.apply_status== 'nonapplied' ? 'Apply' : 'Applied'}} </span>
                            </a>
                            <a *ngIf="item.text_btn !== 'Waiting for payment' && item.text_btn !== 'Waiting for approval' && item?.course_fee != 0" href="javascript:void(0)" class="btn btn-danger col-12 col-lg-6 py-2" (click)="addToCart(item)">
                                <span>Add To Cart</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
