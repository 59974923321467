import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { UserService } from 'src/app/services/userservice.service';
import { APP_URLS, TenantId } from 'src/app/Utils/general';
import { APP_VLE_URLS } from 'src/app/Utils/vle';

@Component({
  selector: 'app-main-courses',
  templateUrl: './main-courses.component.html',
  styleUrls: ['./main-courses.component.css'],
})
export class MainCoursesComponent implements OnInit, OnDestroy {
  courselist: any = [];
  showDialogReg = false;
  coursetype: any;
  studentId: any;
  activeToken = false;
  errorpage: any;
  userDetails: any;
  CartList: any[] = [];
  FILE_PATH_LINODE = APP_URLS.FILE_PATH_LINODE;
  isLoading = false;
  filter = { limit: 0, page: 0 };
  trainingList = [];
  disableButton = false;
  isnoDataFound = false;
  isDataFound = false;

  constructor(
    private router: Router,
    private userSvc: UserService,
    private route: ActivatedRoute,
    private cms: CommonService
  ) {


    this.userDetails = localStorage.getItem('userDetails');
    const data = JSON.parse(this.userDetails);
    if (data) {
      this.studentId = JSON.parse(this.userDetails)._id;
    }

    if (localStorage.getItem('token')) {
      this.activeToken = true;
    } else {
      this.activeToken = false;
    }

    route.params.subscribe((val) => {
      console.log(val);
      this.coursetype = val?.id;
      this.ngOnInit();
    });
  }

  ngOnInit() {

    this.getData();
    this.errorpage = window.location.pathname;
    this.userDetails = localStorage.getItem('userDetails');
    const data = JSON.parse(this.userDetails);
    if (data) {
      this.studentId = JSON.parse(this.userDetails)._id;
    }
  }

  getData() {
    const dataofcart = localStorage.getItem('cartdata');
    if (dataofcart != null) {
      this.CartList = dataofcart.split(',');
    }
    this.getList();
  }


  getList() {
    this.cms.loaderSet(true);
    const url =
      APP_URLS.CourscesList +
      '{"criteria":[{"key":"tenantId","value":"' +
      TenantId +
      '","type":"eq"},{"key":"course_type","value":"' +
      this.coursetype +
      '","type":"eq"}]}';
    if (this.studentId) {
      this.userSvc.getCallWithHeaders(url).subscribe(
        (res: any) => {
          this.cms.loaderSet(false);
          this.courselist = res.all;
        },
        (err) => {
          this.cms.loaderSet(false);
        }
      );
    } else {
      this.userSvc.getCall(url, true).subscribe(
        (res: any) => {
          this.cms.loaderSet(false);
          this.courselist = res.all;
          this.courselist.forEach((x) => {
            if (this.CartList.includes(x._id)) {
              x.text_btn = 'Waiting for payment';
            }
          });
        },
        (err) => {
          this.cms.loaderSet(false);
        }
      );
    }
  }


  ngOnDestroy() {
    localStorage.removeItem('coursetype');
  }

  openIndividulaCourse(course: any) {
    localStorage.setItem('Individualcourse', course.slug);
    this.router.navigate(['/' + course.slug]);
  }

  AddFav(data: any) {
    const url = APP_URLS.Courses + '/' + data._id + '?favorite=true';
    if (this.studentId) {
      this.userSvc.putCallwithHeaders(url, {}).subscribe(
        (res: any) => {
          this.userSvc.showSuccess(res.respMessage);
          this.getList();
        },
        (err) => {}
      );
    } else {
      this.userSvc.showError('Please register or login to continue');
      // this.showDialogReg = true;
      this.router.navigate(['/register']);
    }
  }

  AddApply(data: any) {
    this.cms.loaderSet(true);
    const url = APP_URLS.Courses + '/' + data._id + '?applycourse=true';

    if (this.studentId) {
      this.userSvc.putCallwithHeaders(url, {}).subscribe(
        (res: any) => {
          this.cms.loaderSet(false);
          this.userSvc.showSuccess(res.respMessage);
          this.getList();
        },
        (err) => {
          this.cms.loaderSet(false);
        }
      );
    } else {
      this.userSvc.showError('Please register or login to continue');
      this.cms.loaderSet(false);
      // this.showDialogReg = true;
      this.router.navigate(['/' + 'register']);
    }
  }


  addToCart(course) {
    const url = APP_URLS.getcart + '/' + course._id + '?addtocart=true';
    if (localStorage.getItem('token') && this.studentId) {
      this.userSvc.putCall(url, {}).subscribe(
        (data: any) => {
          if (data.suc_arr.length > 0) {
            this.userSvc.showSuccess(data.suc_arr[0]);
            this.userSvc.setCart('data');
          }
          if (data.err_arr.length > 0) {
            this.userSvc.showError(data.err_arr[0]);
          }
          this.getList();
        },
        (err) => {
          this.userSvc.showError('failed');
        }
      );
    } else {
      this.CartList.push(course._id);
      localStorage.setItem('cartdata', this.CartList.toString());
      this.userSvc.setCart('data');
      this.userSvc.showSuccess('Course Sucessfully added to Your Cart');
      this.getData();
    }
  }

  waitingForApproval() {
    this.userSvc.showError(
      'Waiting for Admin approval. Contact your Admin for further information'
    );
  }

  textTruncate(data, range){
    let textWrap = data.substring(0, range);
    if (data?.length > range){
      textWrap = textWrap + '...';
    }

    return textWrap;
  }
}
