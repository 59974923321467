<!-- ======= Contact Section ======= -->
<section id="contact" class="contact d-none">
    <div class="container-fluid">
        <div class="section-title">
            <h3 class="text-center">Contact Us</h3>
        </div>
    </div>

    <div class="container-fluid">

        <div class="info-wrap">
            <div class="row">
                <div class="col-lg-3 col-md-6 info">
                    <i class="bi bi-geo-alt"></i>
                    <h4>Registered Address :
                    </h4>
                    <p>{{tenantData.tenantAddress}}</p>
                </div>

                <div class="col-lg-3 col-md-6 info">
                    <i class="bi bi-geo-alt"></i>
                    <h4>Trading Address :
                    </h4>
                    <p>Unit G4, Unit G2, Unit G10, <br />Waterfront Studios, <br />1 Dock Road, London E16 1AH


                    </p>
                </div>

                <div class="col-lg-3 col-md-6 info mt-4 mt-lg-0">
                    <i class="bi bi-envelope"></i>
                    <h4>Email:</h4>
                    <p>{{tenantData.email}}
                    </p>
                </div>

                <div class="col-lg-3 col-md-6 info mt-4 mt-lg-0">
                    <i class="bi bi-phone"></i>
                    <h4>Call:</h4>
                    <p>{{tenantData.phone}}
                    </p>
                </div>
            </div>
        </div>

        <form action="forms/contact.php" method="post" role="form" class="php-email-form">
            <div class="row">
                <div class="col-md-6 form-group">
                    <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" required>
                </div>
                <div class="col-md-6 form-group mt-3 mt-md-0">
                    <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" required>
                </div>
            </div>
            <div class="form-group mt-3">
                <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" required>
            </div>
            <div class="form-group mt-3">
                <textarea class="form-control" name="message" rows="5" placeholder="Message" required></textarea>
            </div>
            <div class="my-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">Your message has been sent. Thank you!</div>
            </div>
            <div class="text-center"><button type="submit" class="btn-1">GET IN TOUCH</button></div>
        </form>

    </div>
</section>
<!-- End Contact Section -->
