import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { UserService } from './services/userservice.service';
import { NoInternetComponent } from './components/no-internet/no-internet.component';
import { NgxFileDropModule } from 'ngx-file-drop';
// import { HomeProfessionalCoursesComponent } from './components/home-professional-courses/home-professional-courses.component';
// import { HomecarouselComponent } from './components/homecarousel/homecarousel.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { OwlModule } from 'ngx-owl-carousel';
// import { AcademicCourseSliderComponent } from './components/academic-course-slider/academic-course-slider.component';
// import { ProfCourseSliderComponent } from './components/prof-course-slider/prof-course-slider.component';
import { FooterComponent } from './components/footer/footer.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ContactComponent } from './components/contact/contact.component';
import { HelpDeskComponent } from './components/help-desk/help-desk.component';
import { WhyusInfoComponent } from './components/whyus-info/whyus-info.component';
import { OurApproachComponent } from './components/our-approach/our-approach.component';
import { GovernanceComponent } from './components/governance/governance.component';
import { PoliciesComponent } from './components/policies/policies.component';
import { NgxPaginationModule } from 'ngx-pagination';
// import { HomeContentComponent } from './components/home/home-content/home-content.component';
// import { MainCoursesComponent } from './components/main-courses/main-courses.component';
// import { VleMainComponent } from './vle/components/vle-main/vle-main.component';
import { MainCoursesComponent } from './components/main-courses/main-courses.component';
import { IndividualCourseComponent } from './components/individual-course/individual-course.component';
import { RegisterComponent } from './components/register/register.component';
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { CookieLawModule } from 'angular2-cookie-law';
// import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { MainComponent } from './components/main/main.component';
import { BannerComponent } from './components/banner/banner.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { ContactusComponent } from './components/contactus/contactus.component';
import { TestimonialsComponent } from './components/testimonials/testimonials.component';
import { WhyUsComponent } from './components/why-us/why-us.component';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { CartDetailsComponent } from './components/cart-details/cart-details.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { SetPasswordComponent } from './components/set-password/set-password.component';

// import { NgxSignaturePadModule } from '@eve-sama/ngx-signature-pad';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    CartDetailsComponent,
    CheckoutComponent,
    BannerComponent,
    LoginComponent,
    HomeComponent,
    CategoriesComponent,
    GalleryComponent,
    WhyUsComponent,
    TestimonialsComponent,
    ContactusComponent,
    PageNotFoundComponent,
    NoInternetComponent,
    FooterComponent,
    PrivacyPolicyComponent,
    ContactComponent,
    HelpDeskComponent,
    WhyusInfoComponent,
    OurApproachComponent,
    GovernanceComponent,
    PoliciesComponent,
    MainCoursesComponent,
    IndividualCourseComponent,
    RegisterComponent,
    ForgotpasswordComponent,
    MainComponent,
    SearchFilterPipe,
    AboutUsComponent,
    TermsAndConditionsComponent,
    SetPasswordComponent
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
    HttpClientModule,
    BrowserAnimationsModule,
    NgxFileDropModule,
    CarouselModule,
    OwlModule,
    NgxPaginationModule,
    CookieLawModule,
    // AutocompleteLibModule,
    // NgxSignaturePadModule
  ],
  exports: [PageNotFoundComponent],
  providers: [UserService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
