import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-approach',
  templateUrl: './our-approach.component.html',
  styleUrls: ['./our-approach.component.css']
})
export class OurApproachComponent implements OnInit {

  token = '';
  constructor() {
    this.token = localStorage.getItem('token');
  }

  ngOnInit(): void {
  }

}
