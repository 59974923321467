<!-- Login content starts here -->
<div class="login">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="header-wrapper">
                    <h1 class="page-header-title">LOGIN</h1>
                    <div class="header-subtitle">LOGIN TO YOUR ACCOUNT</div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row g-0">
            <div class="col-lg-6 col-left">
            </div>
            <div class="col-lg-6 bg-white">
                <div class="p-20">
                    <div class="section-header p-0">
                        <ul class="justify-content-around nav nav-tabs" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link link-light p-20" [ngClass]="{ 'active':activeTab==='student'}" id="home-tab" data-toggle="tab" href="javascript:void(0)" (click)="selectTab('student')" role="tab" aria-controls="home" aria-selected="true">Learner</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link link-light brd-l-r" [ngClass]="{ 'active':activeTab==='employer'}" id="employer-tab" data-toggle="tab" href="javascript:void(0)" (click)="selectTab('employer')" role="tab" aria-controls="employer" aria-selected="false">Apprentice <br>Employer</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link link-light" [ngClass]="{ 'active':activeTab==='staff'}" id="staff-tab" data-toggle="tab" href="javascript:void(0)" (click)="selectTab('staff')" role="tab" aria-controls="staff" aria-selected="false">{{tenantConfig?.shortName}} Staff and <br>Associates</a>
                            </li>
                        </ul>
                    </div>
                    <div class="tab-content" style="margin-top: 10px;">

                        <div class="tab-pane fade" id="home" role="tabpanel" *ngIf="activeTab === 'student'" [ngClass]="{ 'active show':activeTab==='student'}" aria-labelledby="home-tab">
                            <div class="p-20">
                                <form class="pt-20" [formGroup]="userLoginForm">
                                    <div class="mb-3">
                                        <input type="email" class="form-control" placeholder="EMAIL" id="email" name="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.invalid }">
                                        <div *ngIf="submitted && f.email.invalid" class="invalid-feedback mb-3">
                                            <div *ngIf="f.email.errors?.required">{{resourceData?.email_required}}</div>
                                            <div *ngIf="f.email.errors?.pattern">{{resourceData?.invalid_email_address}}</div>
                                        </div>
                                    </div>
                                    <div class="mb-3 position-relative">
                                        <input type="{{ isActive ? 'password' : 'text' }}" class="form-control" placeholder="PASSWORD" id="password" name="password" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.invalid }">
                                        <i class="bi bi-eye-fill form-control-icon me-2" (click)="showPassword()"></i>
                                        <div *ngIf="submitted && f.password.invalid" class="invalid-feedback mb-3">
                                            <div *ngIf="f.password.errors?.required">{{resourceData?.password_required}}</div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <input type="submit" class="btn-2" (click)="studentLogin()" [disabled]="submitted" value="LOGIN" />
                                    </div>
                                </form>
                                <hr />
                                <div class="d-flex justify-content-between">
                                    <a class="a-link text-four" [routerLink]="['/forgotPassword/','learner']" href="javascript:void(0)"> Forgot password </a>
                                    <a routerLinkActive="active" [routerLink]="['/register']" class="a-link text-primary" href="javascript:void(0)"> Create ACCOUNT </a>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="home" role="tabpanel" *ngIf="activeTab === 'employer'" [ngClass]="{ 'active show':activeTab==='employer'}" aria-labelledby="home-tab">
                            <div class="p-20">
                                <form class="pt-20" [formGroup]="userLoginForm">
                                    <div class="mb-3">
                                        <input type="email" class="form-control" placeholder="EMAIL" id="email" name="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.invalid }">
                                        <div *ngIf="submitted && f.email.invalid" class="invalid-feedback mb-3">
                                            <div *ngIf="f.email.errors?.required">{{resourceData?.email_required}}</div>
                                            <div *ngIf="f.email.errors?.pattern">{{resourceData?.invalid_email_address}}</div>
                                        </div>
                                    </div>
                                    <div class="mb-3 position-relative">
                                        <input type="{{ isActive ? 'password' : 'text' }}" class="form-control" placeholder="PASSWORD" id="password" name="password" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.invalid }">
                                        <i class="bi bi-eye-fill form-control-icon me-2" (click)="showPassword()"></i>
                                        <div *ngIf="submitted && f.password.invalid" class="invalid-feedback mb-3">
                                            <div *ngIf="f.password.errors?.required">{{resourceData?.password_required}}</div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <input type="submit" class="btn-2" (click)="studentLogin()" value="LOGIN">
                                    </div>
                                </form>
                                <hr />
                                <div class="d-flex justify-content-between">
                                    <a class="a-link text-four" [routerLink]="['/forgotPassword/','employer']" href="javascript:void(0)"> Forgot password </a>
                                </div>
                            </div>

                        </div>

                        <div class="tab-pane fade" id="staff" role="tabpanel" *ngIf="activeTab === 'staff'" [ngClass]="{ 'active show':activeTab==='staff'}" aria-labelledby="staff-tab">
                            <div class="p-20">
                                <form class="pt-20" [formGroup]="userLoginForm">
                                    <div class="mb-3">
                                        <input type="email" class="form-control" placeholder="EMAIL" id="email" name="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.invalid }">
                                        <div *ngIf="submitted && f.email.invalid" class="invalid-feedback mb-3">
                                            <div *ngIf="f.email.errors?.required">{{resourceData?.email_required}}</div>
                                            <div *ngIf="f.email.errors?.pattern">{{resourceData?.invalid_email_address}}</div>
                                        </div>
                                    </div>
                                    <div class="mb-3 position-relative">
                                        <input type="{{ isActive ? 'password' : 'text' }}" class="form-control" placeholder="PASSWORD" id="password" name="password" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.invalid }">
                                        <i class="bi bi-eye-fill form-control-icon me-2" (click)="showPassword()"></i>
                                        <div *ngIf="submitted && f.password.invalid" class="invalid-feedback mb-3">
                                            <div *ngIf="f.password.errors?.required">{{resourceData?.password_required}}</div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <input type="submit" class="btn-2" (click)="stafflogin()" [disabled]="submitted" value="LOGIN" />
                                    </div>
                                </form>
                                <hr />
                                <div class="d-flex justify-content-between">
                                    <a class="a-link text-four" [routerLink]="['/forgotPassword/','employee']" href="javascript:void(0)"> Forgot password </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
